var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_elImgAuxMain0",style:({
    backgroundImage:'url(' + require('../../assets/img-map-t1.png') + ')',
    backgroundSize: 'contain', //cover
    backgroundPosition: 'center',
    'width':_vm.widthDinam,
    'height':_vm.heightDinam
    })})
}
var staticRenderFns = []

export { render, staticRenderFns }