<style scoped>
    @import url("../../styles/loja/quemSomosLoja.scss");
</style>

<template>
    <div class="box_quemSomosLoja">

        <!-- SUPERIOR :style="{'height':'100px','width':'10px'}" -->
        <div class="supQuemSomos">
            
            <div class="box_img_sup">
                <v-img class=""
                :src="require('../../assets/laser (10).jpg')"
                >
                    <!-- Info LARGE -->
                    <QuemSomosInfo :liExib="liExib[0]" class="infoSup hidden-sm-and-down" />

                    <!-- Info SMALL -->
                    <QuemSomosInfo :liExib="liExib[0]" class="infoSup_small hidden-md-and-up" />
                    
                </v-img>
            </div>

        </div>

        <!-- INFERIOR -->
        <div class="infQuemSomos">

            <!-- COLUNA ESQUERDA -->
            <div class="colInf">
                <v-img class=""
                :src="require('../../assets/laser (2).jpg')"
                >
                    <!-- Info LARGE -->
                    <QuemSomosInfo :liExib="liExib[2]" class="infoInf hidden-sm-and-down" />
                    
                </v-img>
            </div>

            <!-- COLUNA CENTRAL -->
            <div class="colInf">
                <v-img class=""
                :src="require('../../assets/3dex1.jpeg')"
                >
                    <!-- Info LARGE -->
                    <QuemSomosInfo :liExib="liExib[3]" class="infoInf hidden-sm-and-down" />
                    
                </v-img>
            </div>

            <!-- COLUNA DIREITA -->
            <div class="colInf">
                <v-img class=""
                :src="require('../../assets/p (2).jpeg')"
                >
                    <!-- Info LARGE -->
                    <QuemSomosInfo :liExib="liExib[4]" class="infoInf hidden-sm-and-down" />
                    
                </v-img>
            </div>
        </div>
    
    </div>
</template>

<script>
import QuemSomosInfo from './QuemSomosInfo.vue'

export default {
    components: { 
        QuemSomosInfo,
    },

    data() {
        return {
            liExib: [
                {'tit':'Como a UPinC pode te ajudar?','txt':'Participamos ativamente da construção dos seus objetos em diversas finalidades. Produzimos objetos físicos a partir de modelos digitais.'},
                {'tit':'Fabricação Digital','txt':'Produção de objetos físicos a partir de modelos digitais.'},
                {'tit':'Corte e gravação a laser','txt':'É uma tecnologia que usa um laser para cortar ou desenhar em diferentes materiais de maneira personalizada.'},
                {'tit':'Impressão 3D','txt':'Técnica de manufatura aditiva que resulta em um objeto tridimensional feito por camadas.'},
                {'tit':'Confecção','txt':'Montamos, acabamos e manuseamos seu item com responsabilidade. Garantindo a integridade e confiabilidade da utilização.'}
            ],
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>