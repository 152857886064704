<style scoped>
    @import url("../../styles/loja/painelSendPdd.scss");
</style>

<template>
    <div class="box_painelLogin">

        <v-form class="session_form"
        ref="form"
        lazy-validation
        >
            <!--<v-textarea
            color="orange"
            v-model="nameClient"
            :rules="nameRules"
            label="Breve descrição"
            required
            ></v-textarea>-->

            <v-text-field
            color="orange"
            v-model="emailClient"
            label="E-mail"
            required
            ></v-text-field>
        </v-form>

        <div class="btn_login">
            <v-btn
            small
            block
            :disabled="emailClient.length < 10 || flagDuranteEnvio"
            color="orange"
            class="mr-4 mt-1"
            @click="chamarClientPage"
            >
                confirmar
            </v-btn>
        </div>

        <span v-if="flagErrorSend"
        :style="{'margin-top':'10px','color':'rgb(175, 28, 9)','font-size':'0.8rem'}">
            {{ msgErrorSend }}
        </span>

    </div>
</template>

<script>
export default {
    data() {
        return {
            emailClient:'',

            flagErrorSend:false,
            flagDuranteEnvio:false, // SE true indica que está ocorrendo o envio. Precisa aguardar concluir a tentativa de envio.
            msgErrorSend:'Informações inválidas',
        }
    },

    methods: {
        chamarClientPage() {
            if (this.emailClient.length >= 10) {

                this.flagDuranteEnvio = true // desabilitar btn de envio.

                // confirmar através da req post de envio..
                this.flagErrorSend = false
                this.flagDuranteEnvio = false // habilitar btn de envio novamente para a próxima tentativa de envio, se necessário.
                
                // emitir callback de confirmação.
                this.$emit('eventSend', [true,this.emailClient])

                // reset
                this.emailClient = ''

                //console.log('teste send info')
            } else {
                this.flagErrorSend = true
                return
            }

            this.flagErrorSend = false
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>