<style scoped>
    @import url("../../styles/makers/makersSmallCardsPainel.scss");
</style>

<template>
    <!-- PAINEL de small cards -->
    <div class="box_MakersSmallCardsPainel">

        <v-icon large :style="{'transform':rotCog}" class="iconMain">mdi-cog</v-icon>

        <!-- Small main menu (notícias principais) -->
        <MakersSmallCard v-for="(el,i) in liContentMainMenu" :key="el.tit[0]"
        :infoCard="liContentMainMenu[i]" />

        <span class="smallTits">O que os Makers estão a fazer</span>

        <MakersSmallCardB v-for="(el2,c) in liContentMain" :key="el2.tit[0]"
        :infoCard="liContentMain[c]" />

        <span class="smallTits">Mais notícias</span>

        <MakersSmallCardB v-for="(el3,x) in liContentNotice" :key="el3.tit[0]"
        :infoCard="liContentNotice[x]" />

    </div>
</template>

<script>
import MakersSmallCard from './MakersSmallCard'
import MakersSmallCardB from './MakersSmallCardB'

export default {
    props: ['liContentMainMenu','liContentMain','liContentNotice'],

    components: { MakersSmallCard, MakersSmallCardB },

    data() {
        return {
            // rotacionar engrenagem.
            interval: {},
            value:0,
            rotCog:'rotate(0deg)',
        }
    },

    methods: {
        rotCogFunc() {
            this.interval = setInterval(() => {

                if (this.value === 180) {

                    this.value = 0
                }

                this.value += 45
                this.rotCog = 'rotate(' + this.value + 'deg)'

            }, 2000)
        },
        callExp(infoCardB) {

            // Desabilitar main page makers.
            // Habilitar página de extensão de exibição de conteúdo.
            // Rcv info card indv.

            this.$store.state.infoCardContent = infoCardB

            this.$store.state.flagExpContent = true
        },
    },

    beforeDestroy () {
      clearInterval(this.interval)
    },

    created() {
        this.rotCogFunc()
    }
}
</script>