<style scoped>
    @import url("../../styles/lora/dashStation.scss");
</style>

<template>
    <div class="box_dashStation">

        <div class="primeira_linha1">
            <v-icon x-large :style="{'color':'rgb(31,122,177)'}">mdi-map-marker-check</v-icon>
            <span class="tit">Estação Meteorológica Quinta Experimental do Fundão</span>
            <span class="sub_tit">Projecto Montanha Viva</span>
            <span class="sub_tit">Última leitura</span>
            <span class="sub_tit" :style="{'font-weight':'bold'}">{{ lastReading.Data }}</span>
        </div>

        <div class="segunda_linha1">

            <div class="cardLeitura">
                <div class="titCardLeitura">Bateria</div>
                <div class="ContentCardLeitura">{{ lastReading.Bateria }}%</div>
            </div>

            <div class="cardLeitura">
                <div class="titCardLeitura">Humidade</div>
                <div class="ContentCardLeitura">{{ lastReading.Humidade }}%</div>
            </div>

            <div class="cardLeitura">
                <div class="titCardLeitura">Pressão</div>
                <div class="ContentCardLeitura">{{ lastReading.Pressão }}</div>
            </div>

            <div class="cardLeitura">
                <div class="titCardLeitura">Temperatura</div>
                <div class="ContentCardLeitura">{{ lastReading.Temperatura }}°C</div>
            </div>

            <div class="cardLeitura">
                <div class="titCardLeitura">Direção do vento</div>
                <div class="ContentCardLeitura">{{ lastReading.Direção_do_vento }}</div>
            </div>

            <div class="cardLeitura">
                <div class="titCardLeitura">Velocidade do vento</div>
                <div class="ContentCardLeitura">{{ lastReading.Velocidade_do_vento }}</div>
            </div>

        </div>

    </div>
</template>

<script>
export default {

    props:['listaMV'],

    data() {
        return {
            //
            lastReading:{},
        }
    },

    methods:{
        //
        /*
                    battery
: 
86
date
: 
"11/06/2024-17:53"
humidity
: 
46.5
pressure
: 
953.7
temperature
: 
25.5
wind_direction
: 
288.1
wind_speed
: 
2.9
        */
    },

    created() {
        //window.scrollTo(0,0)

        //console.log('on created')
        //console.log('this.listaMV: ',this.listaMV)

        this.lastReading = {
            'Bateria':this.listaMV[this.listaMV.length - 1]['battery'],
            'Data':this.listaMV[this.listaMV.length - 1]['date'],
            'Humidade':this.listaMV[this.listaMV.length - 1]['humidity'],
            'Pressão':this.listaMV[this.listaMV.length - 1]['pressure'],
            'Temperatura':this.listaMV[this.listaMV.length - 1]['temperature'],
            'Direção_do_vento':this.listaMV[this.listaMV.length - 1]['wind_direction'],
            'Velocidade_do_vento':this.listaMV[this.listaMV.length - 1]['wind_speed'],
        }
    }
}
</script>