<style scoped>
    @import url("../../styles/makers/makersNoticeCardB60.scss");
</style>

<template>
    <div class="box_makersNoticeCardB60">

        <!-- PAINEL À ESQUERDA -->
        <div class="painelTXT_NoticeCardB60">

            <div class="tit_NoticeCardB60">{{ infoCardB.tit }}</div>

            <div class="txt_NoticeCardB60">{{ infoCardB.txt }}</div>

            <div class="action_NoticeCardB60">
                <v-icon small :style="{color:'white'}">mdi-calendar</v-icon>
                <span :style="{'margin-left':'4px'}">{{ infoCardB.date }}</span>
            </div>

        </div>

        <!-- IMG À DIREITA -->
        <v-img 
        class="img_NoticeCardB60" 
        :src="infoCardB.src"
        @click="callExp(infoCardB)"
        />
    
    </div>
</template>

<script>
export default {
    props: ['infoCardB'],

    methods: {
        callExp(infoCardB) {

            // Desabilitar main page makers.
            // Habilitar página de extensão de exibição de conteúdo.
            // Rcv info card indv.

            this.$store.state.infoCardContent = infoCardB

            this.$store.state.flagExpContent = true
        },
    },

    created() {

    }
}
</script>