import { render, staticRenderFns } from "./PainelAjusteProd.vue?vue&type=template&id=1f9e4262&scoped=true&"
import script from "./PainelAjusteProd.vue?vue&type=script&lang=js&"
export * from "./PainelAjusteProd.vue?vue&type=script&lang=js&"
import style0 from "./PainelAjusteProd.vue?vue&type=style&index=0&id=1f9e4262&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f9e4262",
  null
  
)

export default component.exports