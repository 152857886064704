<style scoped>
    @import url("../../styles/lora/mapa.scss");
</style>

<template>
    <div class="box_mapa" id="mapaid"></div>
</template>

<script>
import L from "leaflet"; // npm install --save leaflet

export default {

    data() {
        return {
            //
        }
    },

    methods:{
        //
        mapa () {
            // remontar o mapa? /xgetxinfolorax 
        }
    },

    mounted() {
        // coordenada upinc covilha 40.2789636, -7.5040484,275
        // setView([latitude, longitude], zoom)
        //var map = L.map('mapaid').setView([40.2789636, -7.5040484,275], 9.5);
        var map = L.map('mapaid').setView([40.1622213, -7.4553607], 11);

        console.log('map devices start')

        /*L.circle([40.132305, -7.483535], {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.2,
            radius: 5 // metros
        }).addTo(map);*/

        L.marker([40.279184, -7.504404]).addTo(map)
        .bindPopup('Temperatura e Humidade<br><br>(Laboratório UPinC Covilhã)')
        .openPopup();

        L.marker([40.132305, -7.483535]).addTo(map)
        .bindPopup('Estação Meteorológica (Projecto Montanha Viva)<br><br><a href="https://montanhaviva.pt/" target="_blank">https://montanhaviva.pt/</a>')
        .openPopup();
        
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        // lista de listas de coordenadas.
        /*var listaCoords = [
            [40.2789836, -7.5040484,275],
            [40.28, -7.5040484,285],
            [40.29, -7.5040484,305],
        ]*/

        /*var c=0
        while (c < listaCoords.length) {

            L.circle([listaCoords[c][0],listaCoords[c][1]], {
                color: 'red',
                fillColor: '#f03',
                fillOpacity: 0.2,
                radius: 5 // metros
            }).addTo(map);

            //console.log('coord c')

            c++
        }*/

        /*var circle = L.circle([40.2789636, -7.5040484,275], {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.2,
            radius: 5 // metros
        }).addTo(map);*/

        //L.marker([40.2789636, -7.5040484,275], /*{icon: upincIcon}*/).addTo(map);

        // sede upinc
        /*L.marker([40.2789636, -7.5040484,275]).addTo(map)
            .bindPopup('Bem-vindos à UPinC Covilhã !!')
            .openPopup();*/

        // incubadora a praça fundao
        /*L.marker([40.1365755, -7.5006619,21]).addTo(map)
        .bindPopup('Bem-vindos à UPinC Fundão !!.')
        .openPopup();*/
    }
}
</script>