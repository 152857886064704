<style scoped>
    @import url("../../styles/loja/clOptions.scss");
</style>

<template>
    <div class="box_clOptions">

        <!-- VERSÃO LARGE -->

        <div class="col_info_user_large">
            <div class="painel_info">

                <div class="line_init">
                    <v-icon large
                    @click="callCl('main')"
                    @mouseover="changeColor('on')"
                    @mouseleave="changeColor('off')"
                    :style="{'color':colorIcon1,'cursor':'pointer'}"
                    >mdi-account-arrow-left</v-icon>
                </div>

                <span class="tit_options">EDITAR MEUS DADOS<v-icon small class="ml-2">mdi-account-edit</v-icon></span>

                <div class="line_menu_edit mt-4">

                    <v-text-field
                        v-model="nomeClient"
                        :rules="nameRules"
                        color="orange"
                        label="userupinc"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="passClient"
                        :rules="nameRules"
                        color="orange"
                        label="*****"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="emailClient"
                        :rules="nameRules"
                        color="orange"
                        label="teste@gmail.com"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="enderecoClient"
                        :rules="nameRules"
                        color="orange"
                        label="Rua teste número 8, 6200-001"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="foneClient"
                        :rules="nameRules"
                        color="orange"
                        label="951 519 155"
                        required
                    ></v-text-field>

                    <v-btn
                        small
                        block
                        :disabled="!valid"
                        color="orange"
                        class=""
                    >Atualizar dados</v-btn>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {
            colorIcon1:null,

            nomeClient:'',
            emailClient:'',
            passClient:'', // não rcb pass, apenas enviar!
            enderecoClient:'',
            foneClient:'',

            valid: false,
            nameRules: [
                v => !!v || 'Mínimo 5 e máximo 15 letras',
            ],
        }
    },

    methods: {
        callCl(page) {
            this.$store.state.flagCl = page
        },
        changeColor(flag) {
            if(flag == 'on') {
                this.colorIcon1 = 'rgb(247,215,46)'
            } else {
                this.colorIcon1 = null
            }
        },
        updateDadosCl() {

            if (this.$store.state.flagPayPage) {
                console.log('Tá ocorrendo uma op de pagamento. Então redirecionar para para a paypage novamento após edit cl.')
            }
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>