var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$store.state.flagMenuSup)?_c('div',{staticClass:"box_monitorComercial"},[_c('div',{staticClass:"titGwComercial"},[_c('v-icon',{staticClass:"iconTitGw mt-8"},[_vm._v("mdi-monitor-dashboard")]),_c('span',{staticClass:"tit mt-2"},_vm._l((_vm.titMain),function(pT,i){return _c('span',{key:i,staticClass:"mr-2"},[_vm._v(_vm._s(pT))])}),0),_vm._m(0)],1),_c('div',{staticClass:"painelLinhas mt-8 mb-16"},[_c('div',{staticClass:"boxLinha mt-4"},[_c('div',{staticClass:"colImg",style:({
            backgroundImage:_vm.liLinhas[0].img,
            backgroundSize: 'cover', // contain cover
            backgroundPosition:'top-right' // center, bottom, top
            })}),_c('div',{staticClass:"colTxt"},[_c('span',{},[_vm._v(_vm._s(_vm.liLinhas[0].txt))])])]),_c('div',{staticClass:"boxLinha mt-4 mb-4",style:({'flex-wrap':'wrap-reverse'})},[_c('div',{staticClass:"colTxt",style:({'justify-content':'flex-end'})},_vm._l((_vm.liLinhas[1].txt),function(p,n){return _c('span',{key:n,staticClass:"elTxt"},[_vm._v(_vm._s(p))])}),0),_c('div',{staticClass:"colImg",style:({
            backgroundImage:_vm.liLinhas[1].img,
            backgroundSize: 'cover', // contain cover
            backgroundPosition:'right' // center, bottom, top
            })})])]),_c('ContactoUp')],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subTit mt-4"},[_c('span',{staticClass:"elSubTit"},[_vm._v("As suas máquinas sempre operacionais")])])
}]

export { render, staticRenderFns }