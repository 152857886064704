<style scoped>
    @import url("../../styles/loja/painelSuporte.scss");
</style>

<template>
    <div class="box_painelSuporteLarge">

        <div class="faq">
            <div class="tit_faq">FAQ</div>
        
            <v-expansion-panels accordion class="box_faq">
                <v-expansion-panel v-for="(item,i) in liFaq" :key="i" class="el_box_faq">

                    <v-expansion-panel-header class="el_faq">
                        {{ item.pergunta }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="el_faq_content">
                        {{ item.resposta }}
                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </div>

        <div class="outros_sup">
            
            <span class="tit_outros">Fale conosco</span>
            
            <!--<div class="box_outros">teste</div>-->
            
            <v-expansion-panels accordion class="box_outros">
                <v-expansion-panel class="el_box_outros">

                    <v-expansion-panel-header class="el_outros">
                        Como podemos ajudar?
                        <v-icon large :style="{'max-width':'50px','color':'orange'}">mdi-email-fast-outline</v-icon>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="el_outros_content">
                        <PainelSend @eventSend="afterSend($event)" />
                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
import PainelSend from './PainelSend'

export default {
    components: { 
        PainelSend,
    },

    data() {
        return {

            liFaq: [
                {pergunta:'Posso personalizar qualquer coisa?', resposta:'Você pode escolher um dos itens disponíveis na área de produtos e adicionar a uma nova ideia. Nos envie uma descrição mais detalhada sobre sua necessidade que iremos entrar em contato com uma sugestão, proposta ou orçamento.'},
                {pergunta:'A UPinC faz envios?', resposta:'Sim. Enviamos para todo o território nacional portugues, europeu e outras partes do mundo. Entre em contato conosco. Estamos à sua disposição'},
            ],
        }
    },
    methods: {
        afterSend() { // afterSend(e) bool
            //console.log(e)
            window.scrollTo(0,0)
        },
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>