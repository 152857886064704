<style scoped>
    @import url("../../styles/makers/makersLineE.scss");
</style>

<template>
    <div class="box_makersLineE">

        <div class="painel_content_lineE">
            
            <MakersNoticeCardA v-for="(cardA,i) in liContent_E" :key="i"
            :infoCardA="cardA" />
        </div>
    
    </div>
</template>

<script>
import MakersNoticeCardA from './MakersNoticeCardA'

export default {
    components: { MakersNoticeCardA },

    props: ['liContent_E'],
}
</script>