<style scoped>
    @import url("../../styles/loja/checkOutProd.scss");
</style>

<template>
    <div class="">

        <!-- VERSÃO LARGE -->
        <div class="box_checkOutProd">

            <div class="tit">
                <v-icon :style="{'color':'#4B94C0'}">mdi-wrench</v-icon>
                <span :style="{'color':'black'}" class="ml-2">Resumo do ajuste</span>
            </div>

            <div class="body mt-1">
                
                <div class="el_body" v-if="checkbox1">

                    <div class="el_body_el">
                        <span>Nome</span>
                        <span>{{ nomeProduto }}</span>
                    </div>
                    <v-divider class="mb-1"></v-divider>

                    <div class="el_body_el">
                        <span>Material</span>
                        <span>{{ select_a }}</span>
                    </div>
                    <v-divider class="mb-1"></v-divider>

                    <div class="el_body_el">
                        <span>Tamanho</span>
                        <span>{{ select_b }}</span>
                    </div>
                    <v-divider class="mb-1"></v-divider>

                    <div class="el_body_el">
                        <span>Quantidade</span>
                        <span>{{ qnt_un }}</span>
                    </div>
                    <v-divider class="mb-1"></v-divider>

                    <div class="el_body_el">
                        <span>Descrição</span>
                        <span :style="{'align-items':'flex-end','width':'70%','color':'#4B94C0'}">{{ desc_pdd }}</span>
                    </div>
                    <v-divider class="mb-4"></v-divider>

                    <div class="el_body_el" v-if="desconto > 0">
                        <span>Desconto</span>
                        <span>{{ desconto }} €</span>
                    </div>
                    <div class="el_body_el">
                        <span>Subtotal</span>
                        <span>{{ pr_final_sem_imposto }} €</span>
                    </div>
                    <div class="el_body_el">
                        <span>IVA (23%)</span>
                        <span>{{ imposto }} €</span>
                    </div>
                    <div class="el_body_el">
                        <span>Total</span>
                        <span>{{ pr_final }} €</span>
                    </div>
                    <v-divider class="mb-4"></v-divider>
                </div>

                <div class="el_body" v-if="checkbox2">
                    <div class="el_body_el">
                        <span>Descrição</span>
                        <!--<span v-if="desc_pdd.length < 2">Você precisa descrever como deve ser produzido o seu artigo.</span>-->
                        <span :style="{'align-items':'flex-end','width':'70%','color':'#4B94C0'}">{{ desc_pdd }}</span>
                    </div>
                    <v-divider class="mb-4"></v-divider>

                    <div class="el_body_el" v-if="desc_pdd.length > 9">
                        <span>Status</span>
                        <span :style="{'color':'red'}">Irá para análise</span>
                    </div>
                    <v-divider class="mb-4"></v-divider>
                </div>

                <span v-if="checkbox1 && pr_final < 10" :style="{'font-size':'0.7rem','color':'red'}">Preço final mínimo 10.00 €</span>
                <!--<span v-if="checkbox2">Iremos retornar por e-mail e através da sua área de cliente a resposta ao seu pedido em até 24h.</span>-->
                <span v-if="checkbox2">{{ msg1 }}</span>
                <span v-if="checkbox2">{{ msg2 }}</span>
                <v-divider class="mb-4"></v-divider>

                <v-btn small
                v-if="checkbox1"
                class="mb-1"
                :disabled="pr_ref_material[0] <= 0 || pr_ref_material[0] == '' || desc_pdd.length < 10"
                @click="update_pr()"
                >
                    Atualizar preço
                    <v-icon small :style="{'color':'#4B94C0'}">mdi-update</v-icon>
                </v-btn>

                <!--  || pr_final < 10 -->
                <v-btn small
                v-if="checkbox1"
                :disabled="desc_pdd.length < 10 || qnt_un <= 0 || !flagOpenAdd"
                color="green"
                @click="up_prod_to_cart()"
                >
                    Adicionar
                    <v-icon small>mdi-arrow-right</v-icon>
                    <!--:style="{'color':'#4B94C0'}"-->
                    <v-icon small>mdi-cart</v-icon>
                </v-btn>

                <v-btn small
                v-if="checkbox2"
                :disabled="desc_pdd.length < 10"
                color="green"
                @click="up_prod_to_cart()"
                >
                    Adicionar
                    <v-icon small>mdi-arrow-right</v-icon>
                    <!--:style="{'color':'#4B94C0'}"-->
                    <v-icon small>mdi-cart</v-icon>
                </v-btn>

                <span v-if="desc_pdd.length < 10" :style="{'font-size':'0.7rem','color':'red'}">* O produto precisa ter descrição válida</span>
                <span v-if="qnt_un < 1 && checkbox1" :style="{'font-size':'0.7rem','color':'red'}">* Quantidade mínima 1 unidade.</span>
            </div>
        </div>
        

    </div>
</template>

<script>
export default {
    props:[
        'nomeProduto','select_a','select_b','qnt_un','desc_pdd',

        'material','pr_ref_material','tamanho','tam_ref_material',

        'checkbox1','checkbox2'
    ],

    data() {
        return {
            flagOpenAdd:false,

            pr_init:0,
            desconto:0,
            imposto:0,
            pr_final_sem_imposto:0,
            pr_final:0,

            msg1:'A descrição do seu pedido será analisada em até 24h após submeter o pedido. Retornaremos a resposta na sua área de cliente.',
            msg2:'Antes de adicionar ao carrinho, certifique-se de que descreveu por completo: formato, tamanho, cor, material, quantidade, arte.',
        }
    },

    methods: {
        up_prod_to_cart() {
            
            // Condicionar: produto sugestão ou descrito por completo?
            // op cart.
            if (this.checkbox1) { // Produto escolhido através das sugestões.

                // variáveis globais de operação financeira.
                this.$store.state.pr_inicialCartGlobal = parseFloat(this.$store.state.pr_inicialCartGlobal) + parseFloat(this.pr_init)
                this.$store.state.descontoCartGlobal = parseFloat(this.$store.state.descontoCartGlobal) + parseFloat(this.desconto)
                this.$store.state.subtotalCartGlobal = parseFloat(this.$store.state.subtotalCartGlobal) + parseFloat(this.pr_final_sem_imposto)
                this.$store.state.impostoCartGlobal = parseFloat(this.$store.state.impostoCartGlobal) + parseFloat(this.imposto)
                this.$store.state.pr_finalCartGlobal = parseFloat(this.$store.state.pr_finalCartGlobal) + parseFloat(this.pr_final)

                this.$store.state.liCartGlobal.push({
                    'name':this.nomeProduto,
                    'material':this.select_a,
                    'tam':this.select_b,
                    'desc':this.desc_pdd,
                    'qnt':this.qnt_un,
                    'pr':this.pr_init,
                    'hoverEdit':'rgb(31,122,177)',
                    'hoverEditBorder':'1px solid rgb(31,122,177)',
                    'desconto':this.desconto,
                    'subtotal':this.pr_final_sem_imposto,
                    'imposto':this.imposto,
                    'prFinal':this.pr_final
                })
                this.$store.state.flagOpSubmitPdd = true // => indicação de que está em operação de fechamento.
                this.$store.state.flagCartParaAnalise = false // => permitir pay page.

            } else { // Produto personalizado por completo.

                this.$store.state.liCartGlobal.push({
                    'name':this.nomeProduto,
                    'material':'',
                    'tam':'',
                    'desc':this.desc_pdd, // (!)
                    'qnt':'',
                    'pr':'',
                    'hoverEdit':'rgb(31,122,177)',
                    'hoverEditBorder':'1px solid rgb(31,122,177)',
                    'desconto':'',
                    'subtotal':'',
                    'imposto':'',
                    'prFinal':''
                })
                this.$store.state.flagOpSubmitPdd = true // => indicação de que está em operação de fechamento.

                this.$store.state.flagCartParaAnalise = true // => NÃO permitir pay page.
            }

            // Subir página até o componente RESUMO DO PEDIDO.
            window.scrollTo(0,0)

            // Verif. SE cl logado. => inputar novo pdd na lista de pdd do cl.
            /*if (!this.$store.state.flagPageLoginCl) { // (el login off) => (el cl on).

                // rcv info atual local do cl logado.
                var info_cl = this.$store.state.info_cl

                // Operação de soma condicionada através do tipo de pedido.
                // Se ~orçamento~ Então STATUS_PDD rcb "Orçamento em análise". Senao rcb "Pagamento em análise".
                var liCart = this.$store.state.liCartGlobal
                var somaDesconto = 0
                var somaSubtotal = 0
                var somaImposto = 0
                var somaPrFinal = 0
                var msgStatus = ''
                var i = 0
                while (i < liCart.length) {
                    if (liCart[i]['pr'] != '') {
                        somaDesconto = somaDesconto + liCart[i]['desconto']
                        somaSubtotal = somaSubtotal + liCart[i]['subtotal']
                        somaImposto = somaImposto + liCart[i]['imposto']
                        somaPrFinal = somaPrFinal + liCart[i]['prFinal']
                        msgStatus = 'Pagamento em análise'
                    } else {
                        somaDesconto = 0
                        somaSubtotal = 0
                        somaImposto = 0
                        somaPrFinal = 0
                        msgStatus = 'Orçamento em análise'
                        break // Se encontrar pelo menos 1 preço vazio Então indica que é um pdd orçamento.
                    }
                    i++
                }
                
                // NOVO pedido sendo submetido.
                // simulação backend: rcv cliente e enviar para o front.
                var len = info_cl['pedidos'].length
                var novo_id_pdd = len + 101
                var novo_pdd = {
                    'id_pdd':novo_id_pdd,
                    'date_pdd':'02/01/2001',
                    'pdd':this.$store.state.liCartGlobal,
                    'status_pdd':msgStatus,
                    'desconto':somaDesconto, // soma de todos os descontos, se houverem.
                    'subtotal':somaSubtotal, // soma.
                    'imposto':somaImposto, // soma.
                    'prFinal':somaPrFinal, // soma.
                }
                info_cl['status'] = 'Aberto' // input db. ~pdd sendo aberto~
                info_cl['pedidos'].push(novo_pdd) // input db.

                // update lista de pedidos do cl logado. (sobrepor a info local)
                //this.$store.state.info_cl['pedidos'] = info_cl['pedidos']
                //this.$store.state.info_cl['status'] = info_cl['status']
                this.$store.state.info_cl = info_cl
            }*/

            //console.log('Fim de rotina: envio do ajuste para o cart')

            // RESET: local
            /*this.desconto = 0
            this.pr_final_sem_imposto = 0
            this.imposto = 0
            this.pr_final = 0*/

            // RESET: PROP mutation (necessário? pois o reset local já limpa o emissor)
            /*this.desc_pdd = ''
            this.qnt_un = 0
            this.pr_init = 0

            this.select_a = ''
            this.select_b = ''*/
        },
        update_pr() { // btn atualizar preço.

            let c=0
            let d=0
            while (c < this.material.length) {

                // preço m2 no índice de referência, a partir do material selecionado.
                if (this.select_a == this.material[c]) {
                    var pr_mat = this.pr_ref_material[c]['pr_ref_m2'] // FLOAT.
                    var ref_m2 = this.pr_ref_material[c]['ref_m2'] // FLOAT.
                    break
                }
                c++
            }
            while (d < this.tamanho.length) {
                
                if (this.select_b == this.tamanho[d]) {
                    var altura = this.tam_ref_material[d]['altura'] // FLOAT.
                    var largura = this.tam_ref_material[d]['largura'] // FLOAT.

                    var dim = (altura * largura)/1000 // converter [mm] --> [m].
                    break
                }
                d++
            }
            
            // prévea do preço final.
            this.pr_init = (dim * pr_mat) / ref_m2
            // add tempo_de_maquina e custo_do_operador e a qnt pedida pelo cliente.
            this.pr_init = this.pr_init * 3 * 6.5 * this.qnt_un
            //console.log('pr sem desconto: ',this.pr_init)

            // preço final.
            if (this.pr_init < 3) { // a partir de 3 euros há desconto direto.

                this.desconto = 0.0
                //console.log('este valor não inclui desconto')

            } else {

                // equação de desconto.
                this.desconto = ( 0.26325 * (1.001**this.qnt_un) ) * this.qnt_un
                //console.log('desconto: ',this.desconto)
                if (this.desconto >= (this.pr_init * 0.15)) {

                    this.desconto = (this.pr_init * 0.15)
                }
            }
            this.pr_final_sem_imposto = this.pr_init - this.desconto
            this.pr_final_sem_imposto = parseFloat(this.pr_final_sem_imposto).toFixed(2)
            this.desconto = parseFloat(this.desconto).toFixed(2) // APÓS CÁLCULO DO PREÇO É PERMITIDO ARREDONDAR.
            this.pr_init = parseFloat(this.pr_init).toFixed(2) // APÓS CÁLCULO DO PREÇO É PERMITIDO ARREDONDAR.
            //console.log('pr com desconto: ',this.pr_final_sem_imposto)

            // IMPOSTO
            this.imposto = this.pr_final_sem_imposto * 0.23 // IVA 23%
            var aux_imposto = this.imposto
            this.imposto = parseFloat(this.imposto).toFixed(2)
            var dif_aux = Math.abs(aux_imposto - this.imposto) // módulo da diferença.
            //console.log('diferença entre o imposto e o arredondamento: ',dif_aux)
            var aux50 = (this.imposto/2) * 0.5 // 50% do arredondamento.
            //console.log('50% do arredondamento: ',aux50)
            // PUXAR O PREÇO SEMPRE PARA CIMA.
            if (dif_aux <= aux50) {

                // inc imposto para puxar o arredondamento para acima de 50%.
                this.imposto = aux_imposto + dif_aux
                this.imposto = parseFloat(this.imposto).toFixed(2)

            }
            //console.log('imposto arredondado: ',this.imposto)

            this.pr_final = parseFloat(this.pr_final_sem_imposto) + parseFloat(this.imposto)
            this.pr_final = parseFloat(this.pr_final).toFixed(2)
            //console.log('preço final: ',this.pr_final)

            this.flagOpenAdd = true // habilitar o adicionamento do produto no carrinho global.
        },
    },
}
</script>