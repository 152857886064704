<style scoped>
    @import url("../../styles/lora/mapLora.scss");
</style>

<template>
    <div class="box_mapLora">

        <div class="tit_linha">
            <v-icon class="mb-2" x-large :style="{'color':'rgb(31,122,177)'}">mdi-map</v-icon>
            <div class="box_tit">
                <span class="tit mr-2" v-for="(elFrase,k) in liFraseTit" :key="k">{{ elFrase }}</span>
            </div>
        </div>

        <!-- criar componente de linha de controle posteriormente -->
        <div class="linha_controle">
            <!--<div class="card_btn"></div>-->
            <v-btn class="mr-2 mt-1" @click="changeMap('device')" :style="{'background-color':colorBtnDevices}">
                <span class="mr-1">Dispositivos</span>
                <v-icon :style="{'color':'green'}">mdi-check-circle</v-icon>
            </v-btn>
            <v-btn class="mt-1" @click="changeMap('sinal')" :style="{'background-color':colorBtnSinalRede}">
                <span class="mr-1">Sinal da rede</span>
                <v-icon v-if="flagLoadingSinal" :style="{'color':'red','transform':rotLoading}">mdi-loading</v-icon>
                <v-icon v-if="!flagLoadingSinal" :style="{'color':'green'}">mdi-check-circle</v-icon>
            </v-btn>
            <!-- internamente ao painel devices deve haver botoes para selecionar o view dos dados do device -->
            <!-- criar um subpainel de controle para selecionar os devices e exibir um dashboard simples -->
        </div>

        <!-- INFO SINAL LORA -->
        <div class="primeira_linha" v-if="flagSinalLoraView">
            <!--<v-icon x-large :style="{'color':'rgb(31,122,177)'}">mdi-map-marker-path</v-icon>-->
            <span class="tit">Mapeamento de captação do sinal LoRa</span>
            <span class="sub_tit">Desbrave as possibilidades de integrar a sua solução LoRaWAN à rede Pública do Fundão e Outras</span>
        </div>

        <!-- INFO DEVICES -->
        <div class="primeira_linha" v-if="flagDevicesLoraView">
            <!--<v-icon x-large :style="{'color':'rgb(31,122,177)'}">mdi-map-marker-path</v-icon>-->
            <span class="tit">Dispositivos LoRaWAN</span>
            <span class="sub_tit">Soluções LoRaWAN inseridas na rede Pública do Fundão</span>
        </div>

        <!-- OPÇÕES LORA -->
        <div class="linha_controle_devices" v-if="flagSinalLoraView">
            <!--<div class="card_btn"></div>-->
            <v-btn class="mr-2 mt-1" @click="changeMap('pontosMap')" :style="{'background-color':colorBtnPontosMap}">
                <span class="mr-1">Pontos Mapeados</span>
                <v-icon :style="{'color':'green'}">mdi-check-circle</v-icon>
            </v-btn>

            <v-btn class="mr-2 mt-1" @click="changeMap('gwMap')" :style="{'background-color':colorBtnGwMap}">
                <span class="mr-1">Gateways Localizadas</span>
                <v-icon :style="{'color':'green'}">mdi-check-circle</v-icon>
            </v-btn>

            <v-btn class="mr-2 mt-1" @click="changeMap('gwCirc')" :style="{'background-color':colorBtnGwCirc}">
                <span class="mr-1">Estimativa de cobertura</span>
                <v-icon :style="{'color':'green'}">mdi-check-circle</v-icon>
            </v-btn>
        </div>

        <!-- DEVICES -->
        <div class="linha_controle_devices" v-if="flagDevicesLoraView">
            <!--<div class="card_btn"></div>-->
            <v-btn class="mr-2" @click="changeMap('station1')" :style="{'background-color':colorBtnStation1}">
                <span class="mr-1">Estação Meteorológica</span>
                <v-icon v-if="flagLoadingStation1" :style="{'color':'red','transform':rotLoading1}">mdi-loading</v-icon>
                <v-icon v-if="!flagLoadingStation1" :style="{'color':'green'}">mdi-check-circle</v-icon>
            </v-btn>

            <v-btn class="mr-2" @click="changeMap('sensor1')" :style="{'background-color':colorBtnSensor1}">
                <span class="mr-1">Sensor UPinC</span>
                <v-icon v-if="flagLoadingSensor1" :style="{'color':'red','transform':rotLoading2}">mdi-loading</v-icon>
                <v-icon v-if="!flagLoadingSensor1" :style="{'color':'green'}">mdi-check-circle</v-icon>
            </v-btn>
        </div>

        <!-- SUB INFO OPÇÕES LORA -->
        <div class="primeira_linha" v-if="flagSinalLoraView">
            <span class="tit">{{ strTitOptionGw }}</span>
            <span class="sub_tit">{{ strSubTitOptionGw }}</span>

            <div class="box_legendaMapPontos mt-2" v-if="strTitOptionGw == 'Pontos Mapeados'">

                <QuadLegendPontosInit 
                :tit="tit_initPt"
                :liValueSignal="liValueSignal_initPts"
                :liCorSignal="liCorSignal_initPts" />

                <QuadLegendRSSI />

                <QuadLegendSNR />
                
            </div>

            <div class="box_legendaMapPontos mt-2" v-if="strTitOptionGw == 'Gateways Localizadas'">

                <QuadLegendGwFound v-if="flagWaitForCallBackMapGw" :liContadores="liContadores" />
                
            </div>

            <div class="box_legendaMapPontos mt-2" v-if="strTitOptionGw == 'Estimativa de cobertura'">

                <QuadLegendRSSI />

                <QuadLegendPontosInit 
                :tit="tit_circ"
                :liValueSignal="liValueSignal_circ"
                :liCorSignal="liCorSignal_circ" />
                
            </div>
        </div>

        <!--<MapaLeafLetPontosMap v-if="flagSinalLoraMap && flagPontosMap" :listaCoords="this.listaCoords" />-->
        <MapaLeafLetPontosMapV2 v-if="flagSinalLoraMap && flagPontosMap" :listaCoords="this.listaCoords" />
        
        <MapaLeafLetGwMap v-if="flagSinalLoraMap && flagGwMap" 
        :listaGw="this.listaGw" 
        @contadores="contadores($event)"
        />

        <MapaLeafLetGwCirc v-if="flagSinalLoraMap && flagGwCirc" :listaCirc="this.listaCirc" />

        <MapaLeafLetDevices v-if="flagDevicesLoraMap" />

        <DashStation :listaMV="this.listaMV" v-if="flagStation1View" />

        <DashSensor :listaTH="this.listaTH" v-if="flagSensor1View" />

        <!-- "gambiarra"  de sobreposição de elementos na página LARGE -->
        <div class="segunda_linha hidden-sm-and-down">
            <div class="sobreposta"></div>
        </div>

        <!-- "gambiarra"  de sobreposição de elementos na página SMALL -->
        <div class="segunda_linha_small hidden-md-and-up">
            <div class="sobreposta_small"></div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

import MapaLeafLetDevices from './MapaLeafLetDevices'
import DashStation from './DashStation'
import DashSensor from './DashSensor'

//import MapaLeafLetPontosMap from './MapaLeafLetPontosMap'
import MapaLeafLetPontosMapV2 from './MapaLeafLetPontosMapV2'
import MapaLeafLetGwMap from './MapaLeafLetGwMap'
import MapaLeafLetGwCirc from './MapaLeafLetGwCirc'

import QuadLegendRSSI from './QuadLegendRSSI'
import QuadLegendSNR from './QuadLegendSNR'
import QuadLegendPontosInit from './QuadLegendPontosInit'

import QuadLegendGwFound from './QuadLegendGwFound'

export default {

    components: { 
        MapaLeafLetPontosMapV2, MapaLeafLetGwMap, MapaLeafLetGwCirc,
        MapaLeafLetDevices, DashStation, DashSensor,
        QuadLegendRSSI, QuadLegendSNR, QuadLegendPontosInit,
        QuadLegendGwFound,
    },

    data() {
        return {
            //
            // --------------------------------------------------------------------------- LORA MAP
            //
            liFraseTit:['Rede','Pública','do','Município','do','Fundão','e','Outras'],
            //
            flagDevicesLoraView:true, // habilitar componentes na página.
            flagSinalLoraView:false, 
            flagDevicesLoraMap:true, // habilitar mapas na página.
            flagSinalLoraMap:false,
            listaCoords:[], // enviado via props para o componente filho MapaLeafLet.vue
            colorBtnSinalRede:'',
            colorBtnDevices:'#1F7AB1',

            // Lista de gateways identificadas pelos pontos.
            listaGw:[],
            liContadores:[0,0,0],
            flagWaitForCallBackMapGw:false,

            tit_initPt:'Pontos iniciais',
            liCorSignal_initPts:['#D91966'],
            liValueSignal_initPts:['Informação da conexão sem indicação do sinal no ponto.'],

            // Lista de círculos contendo os pontos que o compõe.
            listaCirc:[],

            tit_circ:'Cobertura',
            liCorSignal_circ:['rgb(161, 31, 236)'],
            liValueSignal_circ:['Agrupamentos de pontos mapeados.'],

            // rotacionar loading.
            interval:{},
            valueCog:0,
            rotLoading:'rotate(0deg)',
            flagLoadingSinal:true, // init loading...

            // station 1 (quinta experimental)
            interval1:{},
            valueCog1:0,
            rotLoading1:0,
            flagLoadingStation1:true, // init loading...
            flagStation1View:false,
            listaMV:[],
            colorBtnStation1:'', // '#1F7AB1'

            // sensor upinc laboratório 02/09/2024
            interval2:{},
            valueCog2:0,
            rotLoading2:0,
            flagLoadingSensor1:true, // init loading...
            flagSensor1View:false,
            listaTH:[],
            colorBtnSensor1:'',

            // menu opções lora v3 08/08/2024
            colorBtnPontosMap:'',
            colorBtnGwMap:'#1F7AB1', // v3 08/08/2024
            colorBtnGwCirc:'',
            flagPontosMap:false,
            flagGwMap:false,
            flagGwCirc:false,
            flagStrOptionMap:'',

            // menu opções lora v4 21/08/2024
            strTitOptionGw:'Gateways Localizadas',
            strSubTitOptionGw:'Esta componente de análise exibe as gateways encontradas resultante da captura de pontos no terreno. Algumas gateways podem não ter geolocalização.',
        }
    },

    methods: {
        //
        contadores(liContadoresInit) { // recebido via callback MapaLeafLetGwMap.vue e inputado via props em QuadLegendGwFound.vue
            this.liContadores[0] = liContadoresInit[0]
            this.liContadores[1] = liContadoresInit[1]
            this.liContadores[2] = liContadoresInit[2]
            this.flagWaitForCallBackMapGw = true
        },
        rotLoadingFunc() {
            this.interval = setInterval(() => {

                if (this.valueCog === 360) {

                    this.valueCog = 0
                }

                this.valueCog += 45
                this.rotLoading = 'rotate(' + this.valueCog + 'deg)'

            }, 500)
        },
        rotLoadingFunc1() {
            this.interval1 = setInterval(() => {

                if (this.valueCog1 === 360) {

                    this.valueCog1 = 0
                }

                this.valueCog1 += 45
                this.rotLoading1 = 'rotate(' + this.valueCog1 + 'deg)'

            }, 500)
        },
        rotLoadingFunc2() {
            this.interval2 = setInterval(() => {

                if (this.valueCog2 === 360) {

                    this.valueCog2 = 0
                }

                this.valueCog2 += 45
                this.rotLoading2 = 'rotate(' + this.valueCog2 + 'deg)'

            }, 500)
        },
        changeMap(subRoute) {
            //console.log('subRoute: ',subRoute)
            //console.log('this.flagLoadingStation1: ',this.flagLoadingStation1)
            //console.log('-----------------------------')
            
            this.flagStrOptionMap = subRoute // flag string usada para selecionar o mapa no componente MapaLeafLet.vue

            //this.flagSinalLoraMap = false // component reset for reload flags

            if (subRoute == 'device') { 
                this.flagSinalLoraView = false // main
                this.flagDevicesLoraView = true // main

                this.flagSinalLoraMap = false // main
                this.flagDevicesLoraMap = true // main

                this.flagStation1View = false // sub
                this.flagSensor1View = false // sub 02/09/2024
                this.flagPontosMap = false
                this.flagGwMap = false
                this.flagGwCirc = false

                this.colorBtnDevices = '#1F7AB1'
                this.colorBtnSinalRede = '' // btn sinal da rede desativado

                this.colorBtnStation1 = ''
                this.colorBtnSensor1 = '' // 02/09/2024
                this.colorBtnPontosMap = ''
                this.colorBtnGwMap = ''
                this.colorBtnGwCirc = ''
            }
            if (subRoute == 'sinal') { 
                if (this.flagLoadingSinal == false) { 
                    this.flagSinalLoraView = true // main
                    this.flagDevicesLoraView = false // main

                    this.flagSinalLoraMap = true // main
                    this.flagDevicesLoraMap = false // main

                    this.flagStation1View = false // sub
                    this.flagSensor1View = false // sub 02/09/2024
                    this.flagPontosMap = false
                    this.flagGwMap = true // init able v3 08/08/2024
                    this.flagGwCirc = false

                    this.colorBtnDevices = ''
                    this.colorBtnSinalRede = '#1F7AB1' // btn sinal da rede ativado

                    this.colorBtnStation1 = ''
                    this.colorBtnSensor1 = '' // 02/09/2024
                    this.colorBtnPontosMap = ''
                    this.colorBtnGwMap = '#1F7AB1' // init able v3 08/08/2024
                    this.colorBtnGwCirc = ''

                    // forced init | v3 08/08/2024 -> init with gateway signal map
                    this.flagStrOptionMap = 'gwMap'
                }
            }
            if (subRoute == 'station1') {
                if (this.flagLoadingStation1 == false) { 
                    //this.flagSinalLoraView = false // main
                    //this.flagDevicesLoraView = false // main

                    this.flagSinalLoraMap = false // main
                    this.flagDevicesLoraMap = false // main

                    this.flagStation1View = true // sub
                    this.flagSensor1View = false // sub 02/09/2024
                    this.flagPontosMap = false
                    this.flagGwMap = false
                    this.flagGwCirc = false

                    this.colorBtnDevices = ''
                    this.colorBtnSinalRede = ''

                    this.colorBtnStation1 = '#1F7AB1'
                    this.colorBtnSensor1 = '' // 02/09/2024
                    this.colorBtnPontosMap = ''
                    this.colorBtnGwMap = ''
                    this.colorBtnGwCirc = ''
                }
            }

            if (subRoute == 'sensor1') {
                if (this.flagLoadingSensor1 == false) { 
                    //this.flagSinalLoraView = false // main
                    //this.flagDevicesLoraView = false // main

                    this.flagSinalLoraMap = false // main
                    this.flagDevicesLoraMap = false // main

                    this.flagStation1View = false // sub
                    this.flagSensor1View = true // sub 02/09/2024
                    this.flagPontosMap = false
                    this.flagGwMap = false
                    this.flagGwCirc = false

                    this.colorBtnDevices = ''
                    this.colorBtnSinalRede = ''

                    this.colorBtnStation1 = ''
                    this.colorBtnSensor1 = '#1F7AB1'
                    this.colorBtnPontosMap = ''
                    this.colorBtnGwMap = ''
                    this.colorBtnGwCirc = ''
                }
            }

            if (subRoute == 'pontosMap') {
                //console.log('this.flagLoadingStation1: ',this.flagLoadingStation1)
                if (this.flagLoadingSinal == false) { 
                    //console.log('dentro [pontosMap]')
                    
                    //this.flagSinalLoraView = false // main
                    //this.flagDevicesLoraView = false // main

                    this.flagSinalLoraMap = true // main // manter sinal main habilitado. 08/08/2024 control via prop
                    this.flagDevicesLoraMap = false // main

                    this.flagStation1View = false // sub
                    this.flagSensor1View = false // sub 02/09/2024
                    this.flagPontosMap = true
                    this.flagGwMap = false
                    this.flagGwCirc = false

                    this.colorBtnDevices = ''
                    this.colorBtnSinalRede = '#1F7AB1' // manter btn sinal main habilitado. 08/08/2024

                    this.colorBtnStation1 = ''
                    this.colorBtnSensor1 = '' // 02/09/2024
                    this.colorBtnPontosMap = '#1F7AB1'
                    this.colorBtnGwMap = ''
                    this.colorBtnGwCirc = ''

                    this.strTitOptionGw = 'Pontos Mapeados'
                    this.strSubTitOptionGw = 'A intensidade e a qualidade do sinal estão distribuídas no mapa. A legenda abaixo e a caixa de seleção SNR/RSSI ajudam a filtrar a análise da região desejada. Ao clicar em cada ponto é possível visualizar em quais gateways aquela localidade se conecta.'
                }
            }
            if (subRoute == 'gwMap') {
                //console.log('this.flagLoadingStation1: ',this.flagLoadingStation1)
                if (this.flagLoadingSinal == false) { 
                    //console.log('dentro [gwMap]')
                    //this.flagSinalLoraView = false // main
                    //this.flagDevicesLoraView = false // main

                    this.flagSinalLoraMap = true // main  // manter sinal main habilitado. 08/08/2024 control via prop
                    this.flagDevicesLoraMap = false // main

                    this.flagStation1View = false // sub
                    this.flagSensor1View = false // sub 02/09/2024
                    this.flagPontosMap = false
                    this.flagGwMap = true
                    this.flagGwCirc = false

                    this.colorBtnDevices = ''
                    this.colorBtnSinalRede = '#1F7AB1' // manter btn sinal main habilitado. 08/08/2024

                    this.colorBtnStation1 = ''
                    this.colorBtnSensor1 = '' // 02/09/2024
                    this.colorBtnPontosMap = ''
                    this.colorBtnGwMap = '#1F7AB1'
                    this.colorBtnGwCirc = ''

                    this.strTitOptionGw = 'Gateways Localizadas'
                    this.strSubTitOptionGw = 'Esta componente de análise exibe as gateways encontradas resultante da captura de pontos no terreno. Algumas gateways podem não ter geolocalização.'
                }
            }
            if (subRoute == 'gwCirc') {
                //console.log('this.flagLoadingStation1: ',this.flagLoadingStation1)
                if (this.flagLoadingSinal == false) { 
                    //console.log('dentro [gwCirc]')
                    //this.flagSinalLoraView = false // main
                    //this.flagDevicesLoraView = false // main

                    this.flagSinalLoraMap = true // main  // manter sinal main habilitado. 08/08/2024 control via prop
                    this.flagDevicesLoraMap = false // main

                    this.flagStation1View = false // sub
                    this.flagSensor1View = false // sub 02/09/2024
                    this.flagPontosMap = false
                    this.flagGwMap = false
                    this.flagGwCirc = true

                    this.colorBtnDevices = '' // main
                    this.colorBtnSinalRede = '#1F7AB1' // manter btn sinal main habilitado. 08/08/2024

                    this.colorBtnStation1 = '' // sub
                    this.colorBtnSensor1 = '' // 02/09/2024
                    this.colorBtnPontosMap = ''
                    this.colorBtnGwMap = ''
                    this.colorBtnGwCirc = '#1F7AB1'

                    this.strTitOptionGw = 'Estimativa de cobertura'
                    this.strSubTitOptionGw = 'Abaixo são apresentados grupos de pontos por gateway. Ou seja, cada círculo representa um agrupamento de pontos que se conectaram a uma gateway. Ao clicar em um dos círculo, um único conjunto é selecionado para ser analisado individualmente.'
                }
            }
        },
    },

    beforeDestroy () {
      clearInterval(this.interval)
      clearInterval(this.interval1)
      clearInterval(this.interval2)
    },

    created() {
        //window.scrollTo(0,0)

        this.rotLoadingFunc() // sinal da rede.
        this.rotLoadingFunc1() // station 1.
        this.rotLoadingFunc2() // sensor upinc laboratorio.

        var self = this

        axios.get(self.$store.state.pathAxiosGetMV).then(function (res) {
            console.log(res.data.result)
            self.listaMV = res.data.result.stationQuintaExp
            if (self.listaMV.length > 0) { self.flagLoadingStation1 = false }
        })

        axios.get(self.$store.state.pathAxiosGetTH).then(function (res) {
            console.log(res.data.result)
            self.listaTH = res.data.result
            if (self.listaTH.length > 0) { self.flagLoadingSensor1 = false }
        })

        // Se estiver logado no usuário selecionado.
        // -- STORAGE
        var creden = JSON.parse(localStorage.getItem('creden'))
        var contentUser = JSON.parse(localStorage.getItem('contentUser'))
        if (creden != null) {
            this.$store.state.flagLogadoDash = creden.flagLogadoDash
            this.$store.state.contentUser = contentUser

            //console.log('contentUser (state): ',this.$store.state.contentUser)
        }

        if (this.$store.state.flagLogadoDash == true && this.$store.state.contentUser.nome == 'upinc') {
            
            //
            console.log('req lorawan')
            axios.get(self.$store.state.pathAxiosGetLoraPriv).then(function (res) {
                
                console.log(res.data.result)

                self.listaCoords = res.data.result.liP
                self.listaGw = res.data.result.liGw
                self.listaCirc = res.data.result.liCirc
                
                if (self.listaCoords.length > 0) { self.flagLoadingSinal = false }
            })

        } else { // senão, apenas exibir a versão pública da rede.

            //var listaCoords = []
            axios.get(self.$store.state.pathAxiosGetLora).then(function (res) {
                console.log('RCV FROM testerConV4 (pub)')
                console.log(res.data.result)
                self.listaCoords = res.data.result.liP
                self.listaGw = res.data.result.liGw
                self.listaCirc = res.data.result.liCirc
                if (self.listaCoords.length > 0) { /*console.log('lista map sinal ok');*/ self.flagLoadingSinal = false }
            })
        }
    }
}
</script>