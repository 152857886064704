<style scoped>
    @import url("../../styles/makers/makersMain.scss");
</style>

<template>
    <div class="box_makersMain">

        <!-- LARGE -->
        <MakersMenu 
        class="hidden-sm-and-down" 
        :style="{'justify-content':'center'}" 
        />

        <!-- SMALL -->
        <MakersMenu 
        class="hidden-md-and-up" 
        :style="{'justify-content':'start'}" 
        />
        
        <!-- ELEMENTOS: VOLTAR AO MAIN UPINC OU REDIRECT AO INSTAGRAM -->
        <MakersLineA />

        <!-- SMALL CARDs -->
        <MakersSmallCardsPainel
        v-if="!$store.state.flagExpContent"
        class="hidden-md-and-up" 
        :liContentMainMenu="liContentMainMenu"
        :liContentMain="liContentMain"
        :liContentNotice="liContentNotice"
        />

        <!-- EXPANSÃO: EXIBIÇÃO DO CONTEÚDO SELECIONADO -->
        <MakersPageExpContent class="hidden-sm-and-down"
        v-if="$store.state.flagExpContent"
        />
        <!-- SMALL -->
        <MakersPageExpContentSmall class="hidden-md-and-up"
        v-if="$store.state.flagExpContent"
        />

        <!-- PAINEL MENU MAIN (LARGE) -->
        <MakersLineB class="hidden-sm-and-down"
        v-if="!$store.state.flagExpContent"
        :liContentMain="liContentMainMenu"
        />

        <!-- LINHA DE TÍTULO E SEPARAÇÃO ENTRE OS CARDS -->
        <!-- CARDs mais recentes -->
        <MakersLineC class="hidden-sm-and-down"
        v-if="!$store.state.flagExpContent"
        :fraseTIT="fraseTIT_A" 
        />

        <MakersLineD class="hidden-sm-and-down"
        v-if="!$store.state.flagExpContent"
        :liContent_D="liContentMain" 
        />

        <!-- LINHA DE TÍTULO E SEPARAÇÃO ENTRE OS CARDS -->
        <!-- CARDs mais antigos -->
        <MakersLineC class="hidden-sm-and-down"
        v-if="!$store.state.flagExpContent"
        :fraseTIT="fraseTIT_B" 
        />

        <!-- 5 cards horizontais -->
        <MakersLineE class="hidden-sm-and-down"
        v-if="!$store.state.flagExpContent"
        :liContent_E="liContentNotice" 
        />

        <!-- LINHA DE TÍTULO E SEPARAÇÃO ENTRE OS CARDS -->
        <!-- CARD de informações em tempo real -->
        <MakersLineC 
        v-if="!$store.state.flagExpContent"
        :fraseTIT="fraseTIT_C" 
        :posicionamentoTIT="stylePositionTIT" 
        />

        <MakersLineTR 
        v-if="!$store.state.flagExpContent"
        />
    </div>
</template>

<script>
import MakersMenu from './MakersMenu.vue'
import MakersLineA from './MakersLineA.vue'
import MakersSmallCardsPainel from './MakersSmallCardsPainel.vue'
import MakersLineB from './MakersLineB.vue'
import MakersLineC from './MakersLineC.vue'
import MakersLineD from './MakersLineD.vue'
import MakersLineE from './MakersLineE.vue'
import MakersLineTR from './MakersLineTR.vue'
import MakersPageExpContent from './MakersPageExpContent.vue'
import MakersPageExpContentSmall from './MakersPageExpContentSmall.vue'

export default {
    components: { 
        MakersMenu, 
        MakersLineA, 
        MakersSmallCardsPainel,
        MakersLineB, 
        MakersLineC, 
        MakersLineD, 
        MakersLineE, 
        MakersLineTR,
        MakersPageExpContent,
        MakersPageExpContentSmall
    },

    data() {
        return {
            fraseTIT_A:'O que os MAKERS estão a fazer',
            fraseTIT_B:'Mais notícias',
            fraseTIT_C:'Resultados em tempo real',
            stylePositionTIT:'center',
            liContentMainMenu:[
                {
                    'tit':'Porta - chaves Spotify',
                    'desc':'Através da gravação personalizada você pode ter sua música preferida sempre consigo.',
                    'src':require('../../assets/chaveiroSpotify.png'),
                    'date':'03/07/2022'
                },
                {
                    'tit':'MakerSpace UPinC',
                    'desc':'A evolução que o makers precisavam. Está a trazer mais oportunidades de desenvolvimento a cada dia.',
                    'src':require('../../assets/nlx.jpg'),
                    'date':'15/06/2022'
                },
                {
                    'tit':'Gerador de função [DIY]',
                    'desc':'O XR2206 function generator DIY (do-it-yourself) pode gerar as funções que você precisa para os seus projetos e você mesmo que o monta.',
                    'src':require('../../assets/gf1.png'),
                    'date':'01/06/2022'
                }
            ],
            liContentMain:
            [
                {
                    'src':require('../../assets/lo1.png'),
                    'tit':'Compartilhamento de conhecimento',
                    'txt':'Em parceria com a incubadora À PRAÇA a UPinC teve a oportunidade de trocar conhecimento com os makers da cidade do Fundão, Portugal. Abordamos a temática de comunicação LoraWan, um Workshop de iniciação a configuração de um gateway.',
                    'date':'25/05/2022',

                    'srcExp':require('../../assets/lo1.png'),
                    'txtExp':'',
                    //'mainTitCont':'Materiais',
                    //'titCont1':'teste titulo',
                    //'srcCont1':require('../../assets/lo1.png'),
                    //'titCont2':'Teste de título 2',
                    //'txtCont2':'Teste de txt conteúdo 2',
                    'txtFinal':'Quer participar do próximo workshop com os makers? Entre em contacto conosco.'
                },
                {
                    'src':require('../../assets/3dex.jpeg'),
                    'tit':'Já utilizou uma impressora 3d?',
                    'txt':'...',
                    'date':'10/05/2022',

                    'srcExp':require('../../assets/3dex.jpeg'),
                    'txtFinal':'Quando você vai fazer a sua primeira peça?'
                },
            ],
            liContentNotice: // lista que pode ser rcv via props ou store
            [ // 5 un por cada linha do tipo A.
                {
                    'src':require('../../assets/agrotech1.jpg'),
                    'tit':'Smart Garden',
                    'txt':'É possível acompanhar o seu jardim através do seu telemóvel a partir de qualquer lugar.',
                    'date':'05/03/2022',

                    'srcExp':require('../../assets/ica3_t1.jpg'),
                    'txtExp':['Já imaginou pode ver se suas plantas estão recebendo a quantidade certa de água enquanto você precisa estar fora por alguns dias?', 'Os makers da UPinC desenvolveram um sistema de rega automatizada para não precisar se preocupar com suas plantas mesmo offline.','O sistema utiliza a rede loRaWan da cidade do Fundão em Portugal para comunicar seus dados com um receptor final na WEB. O sistema possui um tanque logo abaixo da superfíce onde fica posicionado o vaso com a planta.','O sensor de humidade do ar logo acima da planta e o sensor capacitivo de humidade do solo alertam o microcontrolador que o ambiente precisa de hidratação. Neste momento a bomba inserida no tanque rega a planta para que ela se mantenha saudável.','Este foi um projeto desenvolvido em parceria com o FabLab Aldeia do Xisto.'],
                    'mainTitCont':'Materiais',
                    'titCont1':'Aceda a SMART GARDEN em tempo real',
                    'srcCont1':require('../../assets/qrcode_sg.jpeg'),
                    //'titCont2':'Teste de título 2',
                    //'txtCont2':'Teste de txt conteúdo 2',
                    'txtFinal':'Acompanhe a atual necessidade da planta.'
                },
                {
                    'src':require('../../assets/cl1.png'),
                    'tit':'Real time clock RTC',
                    'txt':'O RTC é um relógio de tempo real que fornece segundos, minutos, horas, dia, data, mês e ano.',
                    'date':'28/02/2022',

                    'srcExp':require('../../assets/rtc_1.png'),
                    'txtExp':['Os seus projetos com controle mais preciso utilizando o RTC.','Aproveite o esquema de montagem desenvolvido pelos makers e o código disponível para download e faça os seus testes.','Aproveite o tempo.'],
                    'mainTitCont':'Materiais',
                    'titCont1':'Circuito RTC',
                    'srcCont1':require('../../assets/rtc_2.png'),
                    'txtFinal':'Conte para a gente no instagram como foram os seus testes.'
                },
                {
                    'src':require('../../assets/nd1.png'),
                    'tit':'End device LoRaWan',
                    'txt':'Estabelecimento de comunicação através da API tylora Circuitpython.',
                    'date':'06/01/2022'
                },
                {
                    'src':require('../../assets/1n.png'),
                    'tit':'O primeiro',
                    'txt':'A comunidade completa o seu primeiro aniversário.',
                    'date':'01/12/2021'
                },
                {
                    'src':require('../../assets/esp01.png'),
                    'tit':'Você conhece o ESP01?',
                    'txt':'O módulo Wi-Fi que contém um microcontrolador esp8266 dispensando a necessidade de um microcontrolador externo.',
                    'date':'16/09/2021'
                },
            ],
        }
    },

    created() {

        this.$store.commit('srcIPmaker') // without param. Initial main GET Req.

        this.$session.set("flagExpContent", false) // RESET

        // obter el da session --> store.
        if (this.$session.exists("flagExpContent")) {

            let flagExpContent = this.$session.get("flagExpContent")
            this.$store.state.flagExpContent = flagExpContent
            console.log('this.$store.state.flagExpContent: ',this.$store.state.flagExpContent)
        }
    }
}
</script>
