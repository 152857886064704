<style scoped>
    @import url("../../styles/loja/clMain.scss");
</style>

<template>
    <div class="box_clMain">

        <!-- LARGE -->

        <div class="col_info_user_large hidden-sm-and-down">
            <div class="painel_info">

                <div class="line_init">
                    <span class="name_user">Olá, {{ $store.state.info_cl['nome_cl'] }}</span>
                    <v-icon large
                    :style="{'color':colorIcon1,'cursor':'pointer'}"
                    >mdi-account</v-icon>
                </div>

                <v-divider></v-divider>

                <span :style="{'font-size':'0.7rem'}" class="mt-2 mb-2 ml-2 mr-2"
                >Bem-vindo à sua área de cliente. Nesta sessão você pode editar suas informações abaixo ou visualizar o seu histórico de pedidos à direita.</span>
                
                <v-divider></v-divider>

                <span v-if="$store.state.info_cl['status'] == 'aberto'"
                :style="{'font-size':'0.8rem'}" class="mt-2 mb-2 ml-2 mr-2">Você ainda não tem nenhum pedido em aberto.</span>

                <!--<div class="line_info mt-4">
                    <span class="ml-2 mr-2">Você ainda não tem nenhum pedido em aberto.</span>
                </div>-->

                <v-divider></v-divider>

                <div class="line_menu_edit mt-4">

                    <v-btn v-if="$store.state.info_cl['pedidos'].length > 0"
                    class="btnMenuEdit"
                    size="small" 
                    color="rgb(247,215,46)" 
                    block
                    @click="$store.state.flagPageLoja = 'lojaProdutos'"
                    >
                        Ver outros produtos
                        <v-icon>mdi-printer-3d</v-icon></v-btn>

                    <v-btn block class="btnMenuEdit" @click="callCl('options')">
                        Editar informações
                        <v-icon>mdi-account-cog</v-icon>
                    </v-btn>

                    <!-- suporte ao cliente -->
                    <v-btn v-if="!flagOpenChatSuporte && !flagConfirm_local"
                    block 
                    class="btnMenuEdit" 
                    color="#4B94C0"
                    @click="flagOpenChatSuporte = true"
                    >
                        Falar com suporte
                        <v-icon>mdi-headphones</v-icon>
                    </v-btn>
                    <PainelSendTxtCl @eventSendQ="callConfim($event)" v-if="flagOpenChatSuporte && !flagConfirm_local" />
                    <div v-if="flagConfirm_local" class="box_confirm_msg mb-1">
                        <span>Solicitação enviada. Em até 24h você receberá uma resposta.</span>
                        <v-icon small class="ml-2" color="rgb(9, 243, 9)">mdi-check</v-icon>
                    </div>
                    
                    <v-btn @click="callOutCl()"
                    block 
                    color="black" 
                    class="btnMenuEdit white--text"
                    >
                        Sair
                        <v-icon>mdi-account-lock-outline</v-icon>
                    </v-btn>

                    <v-divider></v-divider>

                    <div class="img_clMain mt-1">
                        <v-img :style="{'border-radius':'4px'}" :src="require('../../assets/chaveiroSpotify.png')" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col_hist_large hidden-sm-and-down">
            <div class="painel_info_hist">

                <div class="line_init_hist mt-4">
                    <v-icon>mdi-history</v-icon>
                    <span class="ml-2">Histórico</span>
                </div>

                <v-divider class="mt-3"></v-divider>

                <div class="painel_msg" v-if="$store.state.info_cl['pedidos'].length == 0">
                    <span>Você ainda não fez nenhum pedido...</span>
                    <!--<v-icon color="#4B94C0">mdi-emoticon-sad-outline</v-icon>-->
                </div>
                <div class="painel_msg2" v-if="$store.state.info_cl['pedidos'].length == 0">
                    <span>Comece agora mesmo!</span>
                    <v-btn size="small" color="rgb(247,215,46)"  small @click="$store.state.flagPageLoja = 'lojaProdutos'">Ver produtos</v-btn>
                    <v-icon class="mt-2" color="#4B94C0">mdi-emoticon-cool-outline</v-icon>
                </div>

                <v-expansion-panels accordion class="painel_expan mt-6">
                    <v-expansion-panel class="el_expan" :key="i" v-for="(el,i) in $store.state.info_cl['pedidos']">

                        <v-expansion-panel-header class="el_expan_header">
                            <div class="el_header mr-2">
                                <span>Id</span>
                                <span>{{ el.id_pdd }}</span>
                            </div>
                            <div class="el_header mr-4">
                                <span>Data</span>
                                <span>{{ el.date_pdd }}</span>
                            </div>
                            <div class="el_header mr-6">
                                <span>Status</span>
                                <span :style="{'color':'red'}">{{ el.status_pdd }}</span>
                            </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="el_expan_content">

                            <div class="el_content" :key="j" v-for="(prod,j) in el.pdd">
                                
                                <div class="el_content_name">
                                    
                                    <span>{{ prod.name }}</span>
                                    <span class="mini_name">{{ prod.desc }}</span>
                                    
                                    <span class="mini_name" :style="{'color':'#1F7AB1'}" v-if="prod.pr > 0">{{ prod.material }}</span>
                                    <span class="mini_name" :style="{'color':'#1F7AB1'}" v-if="prod.pr > 0">{{ prod.tam }}</span>
                                    <span class="mini_name" :style="{'color':'#1F7AB1'}" v-if="prod.pr > 0">x {{ prod.qnt }}</span>
                                </div>

                                <span class="el_content_pr" v-if="prod.pr != ''">{{ prod.pr | dinheiro }}</span>
                                <span class="el_content_pr" v-else :style="{'color':'red'}">Em análise</span>

                                <!-- VERIF CONDICIONAL PARA A EXIBIÇÃO DA INFO FINANCEIRA (apenas exibe se todos NÃO forem pdd orçamento) -->
                                <!-- SE houver preço definido ENTÃO exibe resultado financeiro -->
                                <!-- <span v-if="prod.pr != '' ? flagFinOn = true : flagFinOn = false"></span> CAUSA LOOP INFINITO NO DOM ! -->
                            </div>
                            <!--<div class="el_content">
                                <span class="el_content_name">Nome de outro produto</span>
                                <span :style="{'color':'red'}">Em análise</span>
                            </div>-->

                            <v-divider class="mt-2 mb-2"></v-divider>

                            <div class="content_desc mt-2 mb-2" v-if="el.status_pdd == 'Pagamento em análise'"><!-- condicionar flag através da func calculo pré exibição -->
                                 <div class="el_content_desc">
                                    <span>Desconto</span>
                                    <span>{{ el.desconto | dinheiro }}</span>
                                </div>
                                <div class="el_content_desc">
                                    <span>Subtotal</span>
                                    <span>{{ el.subtotal | dinheiro }}</span>
                                </div>
                                <div class="el_content_desc">
                                    <span>IVA (23%)</span>
                                    <span>{{ el.imposto | dinheiro }}</span>
                                </div>
                                <div class="el_content_desc">
                                    <span>Total</span>
                                    <span>{{ el.prFinal | dinheiro }}</span>
                                </div>
                            </div>

                            <div class="painel_btn_content">

                                <v-btn small>cancelar</v-btn>
                                
                                <v-btn small class="ml-1"
                                :disabled="el.status_pdd != 'Orçamento aprovado. Aguardando confirmação do cliente.'"
                                >confirmar</v-btn>

                            </div>

                        </v-expansion-panel-content>

                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>

        <!-- SMALL -->

        <div class="col_info_user hidden-md-and-up">teste</div>

        <div class="col_hist hidden-md-and-up">teste2</div>

    </div>
</template>

<script>
import PainelSendTxtCl from './PainelSendTxtCl'

export default {
    components: { PainelSendTxtCl },

    data() {
        return {
            //imgLogoECommerce:require('../../assets/logoLojaA.png'),

            colorIcon1:null,

            flagFinOn:false,

            flagOpenChatSuporte:false,

            flagConfirm_local:false, // true após enviar msg de suporte.
        }
    },

    methods: {
        callCl(page) {
            this.$store.state.flagCl = page
        },
        callConfim(flagConfirm) {

            if(flagConfirm) {
                this.flagConfirm_local = flagConfirm
                this.flagOpenChatSuporte = false
            } else {
                this.flagOpenChatSuporte = false
                this.flagConfirm_local = false
            }
        },
        callOutCl(){

            // reset var local cl.
            this.$store.state.info_cl = {}
            //console.log('this.$store.state.info_cl: ',this.$store.state.info_cl)

            this.$store.state.flagPageLoginCl = true // habilitar login page.
        }
        /*
                    @mouseover="changeColor('on')"
                    @mouseleave="changeColor('off')"

        changeColor(flag) {
            if(flag == 'on') {
                this.colorIcon1 = 'rgb(247,215,46)'
            } else {
                this.colorIcon1 = null
            }
        },*/
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
        //console.log('teste de entrada')
    }
}
</script>