var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_elCardMain",style:({
'height':_vm.heightDinam,
'width':_vm.widthDinam,
backgroundImage:_vm.imgFromChangeByMouse,
backgroundSize: 'cover',
backgroundPosition: 'center'
}),on:{"mouseover":function($event){return _vm.changeImg('on')},"mouseleave":function($event){return _vm.changeImg('off')}}},[_c('div',{staticClass:"bodyText"},[_c('div',{staticClass:"tit mb-4"},[_vm._v(_vm._s(_vm.infoCard.tit))]),_c('div',{staticClass:"contentText ml-2 mr-2"},[(_vm.infoCard.tit != 'Quem Somos')?_c('span',[_vm._v(_vm._s(_vm.infoCard.txt))]):_vm._e()]),(_vm.flagSaibaMais)?_c('div',{staticClass:"saibaMais mb-2",on:{"click":function($event){return _vm.callRotaFromMenuMain(_vm.infoCard.rota)}}},[_vm._v("Saiba mais")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }