<style scoped>
    @import url("../../../styles/loja/catalogo/painelCatalogo.scss");
</style>

<template>
    <div class="box_painelCatalogo">

        <div @click="callSuporte()"
        class="cardProd" v-for="(el,i) in li" :key="i"
        @mouseover="el.shadowCard='0px 1px 2px black'"
        @mouseleave="el.shadowCard='0px 1px 2px rgb(247,215,46)'"
        :style="{'box-shadow':el.shadowCard}">

            <div class="painel_img">
                <v-img 
                :style="{'max-height':'100%','border-top-right-radius':'8px','border-top-left-radius':'8px'}" 
                :src=el.img
                ></v-img>
            </div>

            <div class="titCard">{{el.nome}}</div>

            <div v-if="el.status == 'off'"
            :style="{'display':'flex','justify-content':'center'}">
                <span :style="{'font-size':'0.7rem','color':'red'}">Indisponível</span>
            </div>

            <div class="actionCard">
                <span>Saber mais</span>
                <v-icon small class="ml-2" :style="{'color':'rgb(247,215,46)'}">mdi-arrow-expand</v-icon>
            </div>
        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {
            shadowCard:'0px 1px 2px rgb(247,215,46)',

            li:[
                {'nome':'Porta-Chaves','img':require('../../../assets/produtos/p_chaves1.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Letreiro neon','img':require('../../../assets/produtos/letr0.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Acrílico dobrável','img':require('../../../assets/produtos/acr_dobr0.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Neon','img':require('../../../assets/produtos/led0.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Artigos em acrílico','img':require('../../../assets/produtos/acr_trof.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Iluminações interiores','img':require('../../../assets/produtos/ilumi.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Madeira dobrável','img':require('../../../assets/produtos/mad_dobr.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Porta-Telemóvel/Tablet','img':require('../../../assets/produtos/p_tel.jpeg'),'desc':'','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Artigos para restauração','img':require('../../../assets/mad1.jpeg'),'desc':'Componha sua loja ou restaurante com artigo personalizados.','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Fotografia em madeira','img':require('../../../assets/ftpersoni.jpeg'),'desc':'A sua fotografia pode ser esculpida em madeira.','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Porta chaves Spotify','img':require('../../../assets/chaveiroSpotify.png'),'desc':'A sua música consigo onde estiver.','status':'on','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Letreiro em 3D','img':require('../../../assets/letreiro.jpeg'),'desc':'Letreiro personalizado no relevo desejado.','status':'off','shadowCard':'0px 1px 2px rgb(247,215,46)'},
                {'nome':'Artigos em 3D','img':require('../../../assets/3dex1.jpeg'),'desc':'Técnica de manufatura aditiva que resulta em um objeto tridimensional feito por camadas.','status':'off','shadowCard':'0px 1px 2px rgb(247,215,46)'},
            ],
        }
    },

    methods:{ callSuporte() {this.$store.state.flagPageLoja = 'lojaSuporte';} },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
        window.scrollTo(0,0)
    }
}
</script>