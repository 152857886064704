<style scoped>
@import url("../../styles/makers/makersPageExpContent.scss");
</style>

<template>
  <div class="box_makersPageExpContent">

    <MakersLineAExp />

    <div class="col123_pageExp">

        <!-- COLUNA 1 -->
        <div class="col1_pageExp">

            <!-- TITULO -->
            <div class="linha1_col1_pageExp">

                <span v-for="(wordTit,i) in this.infoCardContent_tit" :key="i">
                    <span :style="{'margin':'5px'}">{{ wordTit }}</span>
                </span>
            </div>

            <!-- LINHA: TXT + IMG -->
            <div class="linha2_col1_pageExp">

                <span class="txt_linha2_col1_pageExp" v-if="this.$store.state.infoCardContent.txtExp">
                    <span class="frase_txt_l2_c1_pExp" v-if="this.$store.state.infoCardContent.txtExp[0]">
                        {{ this.$store.state.infoCardContent.txtExp[0] }}
                    </span>
                    <span class="frase_txt_l2_c1_pExp" v-if="this.$store.state.infoCardContent.txtExp[1]">
                        {{ this.$store.state.infoCardContent.txtExp[1] }}
                    </span>
                    <span class="frase_txt_l2_c1_pExp" v-if="this.$store.state.infoCardContent.txtExp[2]">
                        {{ this.$store.state.infoCardContent.txtExp[2] }}
                    </span>
                    <span class="frase_txt_l2_c1_pExp" v-if="this.$store.state.infoCardContent.txtExp[3]">
                        {{ this.$store.state.infoCardContent.txtExp[3] }}
                    </span>
                </span>

                <v-img v-if="this.$store.state.infoCardContent.srcExp"
                class="img_linha2_col1_pageExp" 
                :src="this.$store.state.infoCardContent.srcExp"
                />

            </div>

            <div class="linha3_col1_pageExp" v-if="this.$store.state.infoCardContent.txtExp">
                <span v-if="this.$store.state.infoCardContent.txtExp[4]">
                    {{ this.$store.state.infoCardContent.txtExp[4] }}
                </span>
            </div>

        </div>

        <!-- COLUNA 2 -->
        <div class="col2_pageExp" v-if="this.$store.state.infoCardContent.mainTitCont">
            
            <!-- TÍTULO PRINCIPAL DOS CONTEÚDOS -->
            <span class="linha1_col2_pageExp">
                {{ this.$store.state.infoCardContent.mainTitCont }}
            </span>

            <!-- CONTEÚDO 1 -->
            <div class="linha2_col2_pageExp">

                <!-- TÍTULO CONTEÚDO 1 -->
                <span class="tit_linha2_col2_pageExp"
                v-if="this.$store.state.infoCardContent.titCont1"
                >
                    {{ this.$store.state.infoCardContent.titCont1 }}
                </span>

                <!-- SE houver img no contéudo 1 -->
                <v-img v-if="this.$store.state.infoCardContent.srcCont1"
                class="img_linha2_col2_pageExp" 
                :src="this.$store.state.infoCardContent.srcCont1"
                @click="downloadImg($store.state.infoCardContent.srcCont1,$store.state.infoCardContent.titCont1)"
                />

                <!-- SE houver txt no conteúdo 1 -->
                <span v-if="this.$store.state.infoCardContent.txtCont1"
                class="txt_linha2_col2_pageExp"
                >
                    {{ this.$store.state.infoCardContent.txtCont2 }}
                </span>

                <!-- SE houver conteúdo para ser baixado -->

            </div>

            <!-- CONTEÚDO 2 -->
            <div class="linha3_col2_pageExp">

                <!-- TÍTULO CONTEÚDO 2 -->
                <span class="tit_linha3_col2_pageExp"
                v-if="this.$store.state.infoCardContent.titCont2"
                >
                    {{ this.$store.state.infoCardContent.titCont2 }}
                </span>

                <!-- SE houver img no contéudo 2 -->
                <v-img v-if="this.$store.state.infoCardContent.srcCont2"
                class="img_linha3_col2_pageExp" 
                :src="this.$store.state.infoCardContent.srcCont2"
                @click="downloadImg($store.state.infoCardContent.srcCont2,$store.state.infoCardContent.titCont2)"
                />

                <!-- SE houver txt no conteúdo 2 -->
                <span v-if="this.$store.state.infoCardContent.txtCont2"
                class="txt_linha3_col2_pageExp"
                >
                    {{ this.$store.state.infoCardContent.txtCont2 }}
                </span>

            </div>

            <!-- TEXTO DE FINALIZAÇÃO DE EXIBIÇÃO DE CONTEÚDO -->
            <span class="linha4_col2_pageExp"
            v-if="this.$store.state.infoCardContent.txtFinal"
            >
                {{ this.$store.state.infoCardContent.txtFinal }}
            </span>
        </div>

    </div>
  
  </div>
</template>

<script>
import MakersLineAExp from "./MakersLineAExp"

export default {
  components: { MakersLineAExp },

  data() {
    return {
        infoCardContent_tit:'',
    }
  },

  methods: {
    downloadImg(img,tit) {

        const linkSource = img;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = tit; // label da imagem no download.
        downloadLink.click();
    },
  },

  created() {

    window.scrollTo(0,0)

    //console.log(Array.isArray(this.$store.state.infoCardContent.tit)) // true se é array.

    //console.log('entrada no component de exibição externa:')
    if (Array.isArray(this.$store.state.infoCardContent.tit) == false) { // SE não for já um array.

        this.infoCardContent_tit = this.$store.state.infoCardContent.tit.split(' ')
    } else {
        this.infoCardContent_tit = this.$store.state.infoCardContent.tit
    }

    /*if (this.$session.exists("infoCardContent ")) {

        let infoCardContent = this.$session.get("infoCardContent")
        this.$store.state.infoCardContent = infoCardContent
        console.log(infoCardContent)
    } else {
        console.log('infoCard not E | sessão desabilitada')
    }*/
  }
}
</script>