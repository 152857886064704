<style scoped>
    @import url("../../styles/agro/msgEmBreve.scss");
</style>

<template>
    <div class="box_msgEmBreve">
        
        <span class="msg_el">Disponível em breve...</span>
    </div>
</template>

<script>
export default {

}
</script>