<style scoped>
    @import url("../../styles/loja/produto.scss");
</style>

<template>
    <div class="box_produto">

        <!-- VERSÃO LARGE -->

        <!-- LINHA INIT: marcador de etapas entre páginas -->
        <div class="etapas">
            <v-icon :style="{'color':'#4B94C0'}">mdi-wrench</v-icon>

            <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>

            <v-icon>mdi-account-credit-card</v-icon>

            <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>

            <v-icon>mdi-check-circle-outline</v-icon>
        </div>
        
        <!-- LINHA MAIN -->
        <div class="cols">

            <!-- COLUNA 1 trocada pela 2 -->
            <div class="col2">
                <span class="tit">{{ $store.state.artigoCurrent.tit }}</span>

                <!--<div class="line"><v-divider></v-divider></div>-->

                <div class="img">
                    <v-img class="el_img" :src="require('../../assets/chaveiroSpotify.png')" />
                </div>

                <div class="desc">
                    <span class="tit_desc">Descrição</span>
                    <span class="el_desc">{{ $store.state.artigoCurrent.desc }}</span>
                </div>
            </div>

            <!-- COLUNA 2 trocada pela 1 -->
            <div class="col1">

                <!--<div class="line"><v-divider></v-divider></div>-->

                <div class="options">
                    <span class="tit">Opções de ajuste</span>
                    <PainelAjusteProd class="painel_ajuste"
                    :nomeProduto="$store.state.artigoCurrent.tit"/>
                </div>
            </div>

            <!-- COLUNA 3 -->
            <div class="col3">
                <span class="tit_col3"><v-icon class="mr-1" :style="{'color':'#4B94C0'}">mdi-cart</v-icon>Meu pedido</span>
                <PainelResumoProd class="painel_resumo" />
            </div>

        </div>

    </div>
</template>

<script>
import PainelAjusteProd from './PainelAjusteProd'
import PainelResumoProd from './PainelResumoProd'

export default {
    components: { PainelAjusteProd, PainelResumoProd },
}
</script>