<style scoped>
    @import url("../../styles/loja/clLogin.scss");
</style>

<template>
    <div class="box_cllogin">

        <v-form class="session_form"
        ref="form"
        v-model="valid"
        lazy-validation
        >
            <v-text-field
            color="orange"
            v-model="nameClient"
            :rules="nameRules"
            label="Nome de usuário"
            required
            ></v-text-field>

            <v-text-field
            type="password"
            color="orange"
            v-model="passClient"
            label="Palavra chave"
            required
            ></v-text-field>
        </v-form>

        <div class="btn_login">
            <v-btn
            small
            block
            :disabled="!valid"
            color="success"
            class="mr-4 mt-1"
            @click="chamarClientPage"
            >
                entrar
            </v-btn>
        </div>

        <span 
        @mouseover="hoverCadastro = 'rgb(247,215,46)'"
        @mouseleave="hoverCadastro = 'rgb(31,122,177)'"
        class="cadastroNameClient" 
        @click="chamarCadastro()"
        :style="{'color':hoverCadastro}"
        >
            Novo Cadastro</span>

        <span v-if="flagErrorLogin"
        :style="{'margin-top':'10px','color':'red','font-size':'0.8rem'}">
            {{ msgErrorLogin }}
        </span>

    </div>
</template>

<script>
export default {
    data() {
        return {
            nameClient:'',
            passClient:'',
            hoverCadastro:'rgb(31,122,177)',
            flagErrorLogin:false,
            msgErrorLogin:'Usúario ou senha incorretos',

            valid: true,
            nameRules: [
                v => !!v || 'Preenchimento obrigatório',
            ],
        }
    },

    methods: {
        chamarClientPage() {
            if (this.$refs.form.validate()) {

                if (this.nameClient.length >= 5 && this.passClient.length >= 5) {

                    // request get info cl.

                    if (this.nameClient == 'upinc' && this.passClient == 'testeç') { // resultado request

                        // -- op backend (teste)
                        let c = 0
                        while (c < this.$store.state.li_simu_cl.length) {

                            //console.log('nome_cl: ',this.$store.state.li_simu_cl[c]['nome_cl'])
                            //console.log('this.nameClient: ',this.nameClient)

                            if (this.$store.state.li_simu_cl[c]['nome_cl'] == this.nameClient) {

                                // update info cl local.
                                this.$store.state.info_cl = {
                                    'id_cl':this.$store.state.li_simu_cl[c]['id_cl'],
                                    'email_cl':this.$store.state.li_simu_cl[c]['email_cl'],
                                    'nome_cl':this.$store.state.li_simu_cl[c]['nome_cl'],
                                    'endereco_cl':this.$store.state.li_simu_cl[c]['endereco_cl'],
                                    'fone_cl':this.$store.state.li_simu_cl[c]['fone_cl'],
                                    'pedidos':this.$store.state.li_simu_cl[c]['pedidos'],
                                    'status':this.$store.state.li_simu_cl[c]['status']
                                }

                                var info_cl = this.$store.state.info_cl
                                console.log('info_cl: ',info_cl)

                                // update info cl local. SE for um novo pdd.
                                if(this.$store.state.flagOpSubmitPdd){

                                    // obter info do pdd current.

                                    // Operação de soma condicionada através do tipo de pedido.
                                    // Se ~orçamento~ Então STATUS_PDD rcb "Orçamento em análise". Senao rcb "Pagamento em análise".
                                    var liCart = this.$store.state.liCartGlobal
                                    var somaDesconto = 0
                                    var somaSubtotal = 0
                                    var somaImposto = 0
                                    var somaPrFinal = 0
                                    var msgStatus = ''
                                    var i = 0
                                    while (i < liCart.length) {
                                        if (liCart[i]['pr'] != '') {
                                            somaDesconto = somaDesconto + liCart[i]['desconto']
                                            somaSubtotal = somaSubtotal + liCart[i]['subtotal']
                                            somaImposto = somaImposto + liCart[i]['imposto']
                                            somaPrFinal = somaPrFinal + liCart[i]['prFinal']
                                            msgStatus = 'Pagamento em análise'
                                        } else {
                                            somaDesconto = 0
                                            somaSubtotal = 0
                                            somaImposto = 0
                                            somaPrFinal = 0
                                            msgStatus = 'Orçamento em análise'
                                            break // Se encontrar pelo menos 1 preço vazio Então indica que é um pdd orçamento.
                                        }
                                        i++
                                    }

                                    // new pdd tmp save local.

                                    //var info_cl // = this.$store.state.info_cl

                                    // NOVO pedido.
                                    var len = info_cl['pedidos'].length
                                    var novo_id_pdd = len + 101
                                    var novo_pdd = {
                                        'id_pdd':novo_id_pdd,
                                        'date_pdd':'02/01/2001',
                                        'pdd':this.$store.state.liCartGlobal,
                                        'status_pdd':msgStatus,
                                        'desconto':somaDesconto, // soma de todos os descontos, se houverem.
                                        'subtotal':somaSubtotal, // soma.
                                        'imposto':somaImposto, // soma.
                                        'prFinal':somaPrFinal, // soma.
                                    }
                                    info_cl['status'] = 'Aberto' // input db. ~pdd sendo aberto~
                                    info_cl['pedidos'].push(novo_pdd) // input db.

                                    // update lista de pedidos do cl logado. (sobrepor a info local)
                                    this.$store.state.info_cl = info_cl

                                    if(this.$store.state.flagCartParaAnalise){ // SE pdd orçamento.
                                        
                                        // req post new info cl to db.

                                        // abrir cl page.
                                        this.$store.state.flagPageLoginCl = false // desabilitar login.

                                        // limpar cart após input do pdd.
                                        this.$store.state.liCartGlobal = []

                                        this.$store.state.flagOpSubmitPdd = false // fim de operação de movimento com o pdd.
                                        return

                                    } else { // SE pdd pagamamento direto.

                                        // abrir pay page.
                                        this.$store.state.flagPageLoginCl = false // desabilitar login.
                                        this.$store.state.flagPageLoja = 'pag'

                                        this.$store.state.flagOpSubmitPdd = false // fim de operação de movimento com o pdd.
                                        return
                                    }
                                }
                            }
                            c++
                        }

                        this.flagErrorLogin = false // desabilitar msg error login.
                        this.$store.state.flagPageLoginCl = false // desabilitar login.
                        
                    } else {
                        this.flagErrorLogin = true
                        return
                    }
                } else {
                    this.flagErrorLogin = true
                    return
                }

                this.flagErrorLogin = false
            }
        },
        chamarCadastro() {
            this.$store.state.flagCadastroUser = true
        },
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>