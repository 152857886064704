<style scoped>
    @import url("../../styles/makers/makersLineBCarsMain.scss");
</style>

<template>
    <div class="box_makersLineBCarsMain">

        <div class="box_img_CardMainLineB">
            <v-img :style="{'border-radius':'8px'}"
                :src="imgSelected"
            />
        </div>

        <div class="box_txt_CardMainLineB">
            <span class="tit_txtCardMain">
                <span v-for="(wordTit,i) in titSelected" :key="i">
                    <span :style="{'margin':'4px'}">{{ wordTit }}</span>
                </span>
            </span>
            <span class="desc_txtCardMain">
                <span v-for="(wordDesc,j) in descSelected" :key="j">
                    <span :style="{'margin':'4px'}">{{ wordDesc }}</span>
                </span>
            </span>

            <div class="last_txtCardMain">
                <v-icon small :style="{color:'white'}">mdi-calendar</v-icon>
                <span :style="{'margin-left':'4px'}">{{ dateSelected }}</span>
            </div>
        </div>

        <div class="box_waitExibCardMain">

            <span class="onWaitExibCardMain"
            @mouseover="changeColorOnHoverOVER('a')"
            @mouseleave="changeColorOnHoverLEAVE('a')"
            @click="callExp(liContentMain[0])"
            :style="{'box-shadow': boxShadowOnWaitExib_a,'color':colorWordOnWaitExib_a}"
            >
                {{ $store.state.titBtn0 }}
            </span>

            <span class="onWaitExibCardMain"
            @mouseover="changeColorOnHoverOVER('b')"
            @mouseleave="changeColorOnHoverLEAVE('b')"
            @click="callExp(liContentMain[1])"
            :style="{'box-shadow': boxShadowOnWaitExib_b,'color':colorWordOnWaitExib_b}"
            >
                {{ $store.state.titBtn1 }}
            </span>

            <span class="onWaitExibCardMain"
            @mouseover="changeColorOnHoverOVER('c')"
            @mouseleave="changeColorOnHoverLEAVE('c')"
            @click="callExp(liContentMain[2])"
            :style="{'box-shadow': boxShadowOnWaitExib_c,'color':colorWordOnWaitExib_c}"
            >
                {{ $store.state.titBtn2 }}
            </span> 
        </div>

    </div>
</template>

<script>
export default {

    props:['liContentMain'],

    data() {
        return {
            titSelected:'',
            descSelected:'',
            imgSelected:'',
            dateSelected:'',

            //titBtn0:'', // alocados no state store.
            //titBtn1:'',
            //titBtn2:'',

            boxShadowOnWaitExib_a:'0px 1px 2px rgb(255, 255, 255)',
            colorWordOnWaitExib_a:'rgb(255, 255, 255)',
            boxShadowOnWaitExib_b:'0px 1px 2px rgb(255, 255, 255)',
            colorWordOnWaitExib_b:'rgb(255, 255, 255)',
            boxShadowOnWaitExib_c:'0px 1px 2px rgb(255, 255, 255)',
            colorWordOnWaitExib_c:'rgb(255, 255, 255)',
        }
    },

    methods: {
        callExp(infoCard) {

            // Desabilitar main page makers.
            // Habilitar página de extensão de exibição de conteúdo.
            // Rcv info card indv.

            //console.log('Menu superior chamando exib')
            //console.log('infoCard: ',infoCard)

            this.$store.state.infoCardContent = infoCard

            this.$store.state.flagExpContent = true
        },
        changeColorOnHoverOVER(letra) {
            
            if (letra == 'a') {
                this.boxShadowOnWaitExib_a = '1px 2px 4px rgb(247,215,46)'
                this.colorWordOnWaitExib_a = 'rgb(247,215,46)'

                this.titSelected = this.liContentMain[0].tit
                this.descSelected = this.liContentMain[0].desc
                this.imgSelected = this.liContentMain[0].src
                this.dateSelected = this.liContentMain[0].date
            }

            if (letra == 'b') {
                this.boxShadowOnWaitExib_b = '1px 2px 4px rgb(247,215,46)'
                this.colorWordOnWaitExib_b = 'rgb(247,215,46)'

                this.titSelected = this.liContentMain[1].tit
                this.descSelected = this.liContentMain[1].desc
                this.imgSelected = this.liContentMain[1].src
                this.dateSelected = this.liContentMain[1].date
            }

            if (letra == 'c') {
                this.boxShadowOnWaitExib_c = '1px 2px 4px rgb(247,215,46)'
                this.colorWordOnWaitExib_c = 'rgb(247,215,46)'

                this.titSelected = this.liContentMain[2].tit
                this.descSelected = this.liContentMain[2].desc
                this.imgSelected = this.liContentMain[2].src
                this.dateSelected = this.liContentMain[2].date
            }
        },
        changeColorOnHoverLEAVE(letra) {

            if (letra == 'a') {
                this.boxShadowOnWaitExib_a = '0px 1px 2px rgb(255, 255, 255)'
                this.colorWordOnWaitExib_a = 'rgb(255, 255, 255)'
            }

            if (letra == 'b') {
                this.boxShadowOnWaitExib_b = '0px 1px 2px rgb(255, 255, 255)'
                this.colorWordOnWaitExib_b = 'rgb(255, 255, 255)'
            }

            if (letra == 'c') {
                this.boxShadowOnWaitExib_c = '0px 1px 2px rgb(255, 255, 255)'
                this.colorWordOnWaitExib_c = 'rgb(255, 255, 255)'
            }
        },
    },

    created () {

        console.log(this.liContentMain)

        // Títulos dos botões carousel. Não mutá-los.
        if (this.$store.state.titBtn0 == '') {
            this.$store.state.titBtn0 = this.liContentMain[0].tit
            this.$store.state.titBtn1 = this.liContentMain[1].tit
            this.$store.state.titBtn2 = this.liContentMain[2].tit
        }

        if (Array.isArray(this.liContentMain[0].tit) == false) { // SE não for já um array.

            let c = 0
            while (c < this.liContentMain.length) {

                this.liContentMain[c].tit = this.liContentMain[c].tit.split(" ")
                this.liContentMain[c].desc = this.liContentMain[c].desc.split(" ")

                c = c + 1
            }
        }

        // inicialização do carousel principal.
        this.titSelected = this.liContentMain[0].tit
        this.descSelected = this.liContentMain[0].desc
        this.imgSelected = this.liContentMain[0].src
        this.dateSelected = this.liContentMain[0].date
    },
}
</script>