<style scoped>
    @import url("../../styles/makers/makersLineC.scss");
</style>

<template>
    <div class="box_makersLineC">

        <span class="makersLineC_tit" :style="{'justify-content':posicionamentoTIT}">
            
            {{ fraseTIT }}
            
        </span>

    </div>
</template>

<script>
export default {
    props: ['fraseTIT','posicionamentoTIT'],
}
</script>