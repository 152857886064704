<style scoped>
    @import url("../../styles/loja/loja.scss");
</style>

<template>
    <div class="box_loja">

        <!-- class="hidden-sm-and-down" -->
        <LojaMenuSup />

        <!--LOGO abaixo do menu | posicionado em px 
        <div class="logo_movel hidden-sm-and-down">
            <div class="logo_movel_el">
                <v-img class="img_ecommerce"
                :src="require('../../assets/logoLojaA.png')"
                />
            </div>
        </div>-->

        <!--<LojaLineLogo />-->

        <LojaImgMain v-if="$store.state.flagPageLoja == 'lojaMain'" />

        <!--<LojaInfoNegoc />-->

        <!-- PRODUTOS EM DESTAQUE -->
        <LojaCarouselGrade v-if="$store.state.flagPageLoja == 'lojaMain'" />
        
        <!--<LojaMenu1 />-->
        
        <!--<LojaCarousel />

        <LojaMenu2 class="hidden-sm-and-down" teste />
        <LojaMenu2Small class="hidden-md-and-up" />

        <LojaPainel />-->

        <LojaPerson v-if="$store.state.flagPageLoja == 'lojaMain'" />

        <!-- CATEGORIA DE PRODUTOS 
        <ProdutosLoja v-if="$store.state.flagPageLoja == 'lojaProdutos'" />-->
        <Catalogo v-if="$store.state.flagPageLoja == 'lojaProdutos'" />

        <!-- PRODUTO SELECIONADO -->
        <Produto v-if="$store.state.flagPageLoja == 'produto'" />

        <!-- PÁGINA INTERMEDIÁRIA (APÓS PRODUTO/PEDIDO SER SUBMETIDO OU FINALIZADO) -->
        <InterPagProd v-if="$store.state.flagPageLoja == 'interPagProduto'" />
        <InterPagProdEmail v-if="$store.state.flagPageLoja == 'interPagProdutoEmail'" />

        <!-- PAPAGAMENTO -->
        <LojaPayPage v-if="$store.state.flagPageLoja == 'pag'" />

        <!-- QUEM SOMOS -->
        <QuemSomosLoja v-if="$store.state.flagPageLoja == 'lojaQuemSomos'" />

        <!-- SUPORTE -->
        <SuporteLoja v-if="$store.state.flagPageLoja == 'lojaSuporte'" />

        <!-- Area Cliente -->
        <AreaCliente v-if="$store.state.flagPageLoja == 'areaCliente'" />

    </div>
</template>

<script>
import LojaMenuSup from './LojaMenuSup'
import LojaImgMain from './LojaImgMain'
import LojaCarouselGrade from './LojaCarouselGrade'
import LojaPerson from './LojaPerson'

//import LojaLineLogo from './LojaLineLogo'
//import LojaInfoNegoc from './LojaInfoNegoc'
//import LojaMenu1 from './LojaMenu1'
/*import LojaCarousel from './LojaCarousel'
import LojaMenu2 from './LojaMenu2'
import LojaMenu2Small from './LojaMenu2Small'
import LojaPainel from './LojaPainel'
*/

//import ProdutosLoja from './ProdutosLoja'
import Catalogo from './catalogo/Catalogo'
import Produto from './Produto'

import InterPagProd from './InterPagProd'
import InterPagProdEmail from './InterPagProdEmail'

import LojaPayPage from './LojaPayPage'

import QuemSomosLoja from './QuemSomosLoja'
import SuporteLoja from './SuporteLoja'
import AreaCliente from './AreaCliente'

export default {
    components: { 
        LojaMenuSup, 
        LojaImgMain,
        LojaCarouselGrade, 
        LojaPerson,

        //ProdutosLoja,
        Catalogo,
        Produto,
        
        InterPagProd,
        InterPagProdEmail,

        LojaPayPage,

        QuemSomosLoja,
        SuporteLoja,
        AreaCliente,
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
        window.scrollTo(0,0)
    }
}
</script>