<style scoped>
    @import url("../../styles/loja/painelSendLoja.scss");
</style>

<template>
    <div class="box_painelLogin">

        <v-form class="session_form" v-if="!flagSending"
        ref="form"
        v-model="valid"
        lazy-validation
        >
            <v-textarea
            color="orange"
            v-model="descSuporte"
            :rules="nameRules"
            label="Breve descrição"
            required
            ></v-textarea>

            <v-text-field
            color="orange"
            v-model="emailClient"
            label="E-mail"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="nameClient"
            label="Nome"
            required
            ></v-text-field>
        </v-form>

        <div class="btn_login" v-if="!flagSending">
            <v-btn
            small
            block
            :disabled="!valid"
            color="orange"
            class="mr-4 mt-1"
            @click="chamarSender()"
            >
                enviar
            </v-btn>
        </div>

        <span v-if="flagErrorSend"
        :style="{'margin-top':'10px','color':'rgb(175, 28, 9)','font-size':'0.8rem'}">
            {{ msgErrorSend }}
        </span>

        <v-icon x-large v-if="flagSending && !flagSent"
        :style="{'margin-top':'10px'}">
        mdi-email-fast-outline
        </v-icon>

        <span v-if="!flagSending && flagSent"
        :style="{'margin-top':'10px','color':'rgreen','font-size':'0.8rem'}">
            Enviado. Retornaremos a sua questão o mais breve possível.
        </span>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            nameClient:'',
            emailClient:'',
            descSuporte:'',
            hoverCadastro:'rgb(31,122,177)',
            flagErrorSend:false,
            msgErrorSend:'Informações inválidas',

            valid: true,
            nameRules: [
                v => !!v || 'Preenchimento obrigatório',
            ],

            //envio
            flagSending:false,
            flagSent:false,
        }
    },

    methods: {
        chamarSender() {
            
            if (this.$refs.form.validate()) {

                var self = this

                if (self.descSuporte.length >= 10 && self.emailClient.length >= 10) {

                    var params = {'ref':'b','cliente':self.nameClient,'email':self.emailClient,'desc':self.descSuporte}
                    
                    self.flagSending = true

                    // confirmação de envio.
                    axios.post(self.$store.state.pathAxiosLojaSuporteA, params).then(function (res) {
                        //console.log('res: ',res.data.result)

                        if (res.data.result == 'email-enviado') {

                            self.msgErrorSend = 'Informações inválidas' // reset
                            self.flagErrorSend = false // reset.
                            
                            // emitir callback de confirmação.
                            self.$emit('eventSend', true)

                            self.flagSent = true
                            self.flagSending = false

                            // reset
                            self.nameClient = ''
                            self.emailClient = ''
                            self.descSuporte = ''

                            //console.log('teste send info')
                        } else {
                            self.msgErrorSend = 'Não foi possível enviar a solicitação. Tente novamente mais tarde.'
                            self.flagErrorSend = true
                            return
                        }
                    })

                } else {

                    self.msgErrorSend = 'Informações inválidas' // reset
                    self.flagErrorSend = true
                    return
                }
                self.msgErrorSend = 'Informações inválidas' // reset
                self.flagErrorSend = false // reset
            }
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>