var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_elTxtShowAuxMain0",style:({
    'width':_vm.widthDinam,
    'height':_vm.heightDinam
    })},[_c('div',{staticClass:"paineltxtShow"},[_c('v-icon',{staticClass:"hidden-sm-and-down",style:({'color':'rgb(31,122,177)'}),attrs:{"large":""}},[_vm._v("mdi-earth")]),_c('div',{staticClass:"boxTxtShow mt-4"},_vm._l((_vm.liTxtAuxMain0),function(txt,i){return _c('span',{key:i,staticClass:"elTxtShow"},[_vm._v(_vm._s(txt))])}),0),_c('span',{staticClass:"mt-4",style:({
            'font-size':'2.4rem',
            'font-weigth':'bold'
            })},[_vm._v(" 700km2 + ")]),_c('div',{staticClass:"btnCallAction",style:({'color':_vm.corBtnMapaMainV2}),on:{"mouseover":function($event){_vm.corBtnMapaMainV2 = 'rgb(31,122,177)'},"mouseleave":function($event){_vm.corBtnMapaMainV2 = ''},"click":function($event){return _vm.callPage('lora')}}},[_c('span',{staticClass:"mr-1"},[_vm._v("Mapa")]),_c('v-icon',{style:({'color':_vm.corBtnMapaMainV2})},[_vm._v("mdi-map")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }