<style scoped>
    @import url("../../styles/loja/clPage.scss");
</style>

<template>
    <div class="box_clPage">

        <!-- VERSÃO LARGE <v-icon>mdi-account</v-icon> -->

        <div class="painel_cl">
            
            <ClMain v-if="$store.state.flagCl == 'main'" />

            <ClOptions v-if="$store.state.flagCl == 'options'" />

            <!--<ClEdit v-if="$store.state.flagCl == 'edit'" />-->
        </div>

    </div>
</template>

<script>
import ClMain from './ClMain'
import ClOptions from './ClOptions'

export default {
    components: { ClMain, ClOptions },

    data() {
        return {
            //imgLogoECommerce:require('../../assets/logoLojaA.png'),

            //hoverBackAgro:'rgb(31,122,177)',
        }
    },

    methods: {
        chamarRota() {
            //this.$store.state.flagPageLoja = 'lojaMain'
        },
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>