<style scoped>
    @import url("../../styles/lora/quadLegendRSSI.scss");
</style>

<template>
    <div class="box_quadLegendRSSI">

        <!-- COLUNA 1 -->
        <div class="colA">
            <div class="tit">Cor</div>
            <div class="boxQuad">

                <div class="quad" 
                v-for="(cor,i) in liCorSignal" :key="i" 
                :style="{'background':cor}">

                    <span v-if="i == 0">Forte</span>
                    <span v-if="i == 2">Fraco</span>

                </div>

            </div>
        </div>

        <!-- COLUNA 2 -->
        <div class="colA">
            
            <div class="tit">SNR</div>

            <div class="boxQuad">
                <div class="quad" v-for="(value) in liValueSignal" :key="value">{{ value }}</div>
            </div>

        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {
            liCorSignal:['rgb(253, 1, 0)','#FF7F00','#0001FD'],
            liValueSignal:['>= 5dB','5dB - 0dB','< 0dB'],
        }
    },
    methods: {
        //
    },

    created() {
        //window.scrollTo(0,0)
    }
}
</script>