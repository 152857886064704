<style scoped>
    @import url("../../styles/makers/makersLineTR.scss");
</style>

<template>
    <div class="box_makersLineTR">

        <span class="tit_painelTR">Temperatura e humidade do MakerSpace UPinC.</span>

        <PainelTR />

    </div>
</template>

<script>
import PainelTR from './PainelTR.vue'

export default {
    components: { PainelTR },
}
</script>