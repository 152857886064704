<style scoped>
    @import url("../../styles/loja/payEscolhaMet.scss");
</style>

<template>
    <div class="box_payEscolhaMet">

        <v-icon class="" large>mdi-account-box</v-icon>

        <div class="super_tit">Métodos de pagamento</div>

        <div class="box_selectMet">

            <div class="cardMet" @mouseover="shadowCardA ='0px 1px 2px #F7D72E'" @mouseleave="shadowCardA = '0px 1px 2px #4A93BF'" :style="{'box-shadow':shadowCardA}">
                <span class="tit mt-4">Bitcoin</span><!-- mt-4 tmp até remover msg de ..em breve btc.. ~ nivelando horizontal do tit ~ -->
                <v-icon class="mt-2" x-large>mdi-bitcoin</v-icon>
                <span :style="{'color':'rgb(121, 38, 38)','font-size':'0.7rem'}">Em breve</span>
            </div>

            <div class="cardMet" 
            @click="callSendMet('mb')"
            @mouseover="shadowCardB ='0px 1px 2px #F7D72E'" 
            @mouseleave="shadowCardB = '0px 1px 2px #4A93BF'" 
            :style="{'box-shadow':shadowCardB}"
            >
                <span class="tit">MultiBanco</span>
                <v-icon class="mt-2" x-large>mdi-currency-eur</v-icon>
            </div>
        
        </div>

    </div>
</template>

<script>
export default {
    //components: {},

    data(){
        return {
            shadowCardA: '0px 1px 2px #4A93BF',
            shadowCardB: '0px 1px 2px #4A93BF',
        }
    },

    methods:{
        callSendMet(stringEvent) {

            console.log('stringEvent: ',stringEvent)
            this.$emit('eventEscolhaMet', [stringEvent, 'payMet']) // método escolhido e a flag de seleção do componente.
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>