<style scoped>
    @import url("../../styles/loja/lojaMenuSup.scss");
</style>

<template>
    <div class="box_lojaMenuSup">

        <!-- BTN VOLTAR -->
        <div class="el_menuSup">

            <!-- linha sup btn-->
            <div class="el_line_menuSup hidden-sm-and-down">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @mouseover="hoverBackMain = 'rgb(31,122,177)'"
                        @mouseleave="hoverBackMain = 'rgb(247,215,46)'"
                        @click="chamarMain('/')" 
                        class="el_tit_btn_voltar"
                        :style="{'color': hoverBackMain}"
                        >
                            mdi-arrow-top-left-thick
                        </v-icon>
                        
                    </template>

                    <span>Voltar ao mosaico</span>

                </v-tooltip>

                <span 
                class="el_tit_btn_voltar hidden-sm-and-down" 
                @click="chamarMain('/')"
                @mouseover="hoverBackMain = 'rgb(31,122,177)'"
                @mouseleave="hoverBackMain = 'rgb(247,215,46)'"
                >
                <v-icon icon>mdi-home</v-icon><!-- Voltar ao início --></span>
            </div>

            <!-- VOLTAR MOSAICO (SMALL)-->
            <div class="el_line_menuSup hidden-md-and-up" @click="chamarMain('/')">
                <v-icon class="el_tit_btn_voltar" :style="{'color':'rgb(247,215,46)'}"
                >mdi-arrow-top-left-thick</v-icon>
                <span :style="{'font.size':'0.7rem'}" class="">Voltar</span>
            </div>

            <!-- linha inf btn-->
            <div class="el_line_menuSup" 
            @click="callPage('lojaMain')"
            @mouseover="hoverBackInitLoja = 'rgb(31,122,177)'"
            @mouseleave="hoverBackInitLoja = 'rgb(247,215,46)'"
            >
                <v-icon class="el_tit_btn_voltar" :style="{'color': hoverBackInitLoja}"
                >mdi-arrow-left-thick</v-icon>
                
                <span>Início Loja</span>
            </div>

        </div>

        <!--<v-icon class="hidden-md-and-up" small icon>mdi-home</v-icon>-->

        <!-- LOGOs -->
        <div class="el_menuSup_logo" @click="callPage('lojaMain')">
            
            <v-img class="img_upinc"
                :src="require('../../assets/0t.png')"
            />
            
            <!--<v-img class="img_ecommerce"
                :src="require('../../assets/logoLojaA.png')"
            />-->

        </div>

        <!-- SEARCH BAR -->
        <div class="el_menuSup_search hidden-sm-and-down">

            <span class="el_search_txt">Como podemos ajudar?</span>
            
            <div class="el_search_menuSup">

                <v-autocomplete
                v-model="values"
                :items="items"
                class="el_search"
                color="rgb(247,215,46)"
                :height="20"
                ></v-autocomplete>  

                <v-icon @click="callCatalogo()"
                large
                class="el_icon_search"
                @mouseover="hoverSearch = 'rgb(31,122,177)'"
                @mouseleave="hoverSearch = 'rgb(247,215,46)'"
                :style="{'color': hoverSearch}">mdi-magnify</v-icon>

            </div>

        </div>

        <!-- ITEM LIST -->
        <div class="el_menuSup_gerais hidden-sm-and-down">
            
            <span 
            @mouseover="hover1 = 'rgb(31,122,177)'"
            @mouseleave="hover1 = 'rgb(247,215,46)'"
            :style="{'color':hover1}"
            class="el_tit_geral" 
            @click="callPage('lojaProdutos')"
            >
                <span :style="{'align-self':'center'}">Produtos</span>
                <v-icon>mdi-printer-3d</v-icon>
            </span>

            <span 
            @mouseover="hover2 = 'rgb(31,122,177)'"
            @mouseleave="hover2 = 'rgb(247,215,46)'"
            :style="{'color':hover2}"
            class="el_tit_geral" @click="callPage('lojaQuemSomos')">
                <span :style="{'align-self':'center'}">Informações</span>
                <v-icon>mdi-account-group</v-icon>
            </span>

            <span 
            @mouseover="hover3 = 'rgb(31,122,177)'"
            @mouseleave="hover3 = 'rgb(247,215,46)'"
            :style="{'color':hover3}"
            class="el_tit_geral" @click="callPage('lojaSuporte')">
                <span :style="{'align-self':'center'}">Suporte</span>
                <v-icon>mdi-headphones</v-icon>
            </span>

            <span
            @mouseover="hover4 = 'rgb(31,122,177)'"
            @mouseleave="hover4 = 'rgb(247,215,46)'"
            :style="{'color':hover4}"
            class="el_tit_geral" @click="callPage('areaCliente')">
                <span :style="{'align-self':'center'}">Área cliente</span>
                <v-icon>mdi-account</v-icon>
            </span>

        </div>

        <!-- MENU SMALL 
        <v-icon large :style="{'color':'rgb(247,215,46)'}" class="iconMain hidden-md-and-up">mdi-menu</v-icon>
        -->
        <!-- offset-y origin="center center" transition="scale-transition" -->
        <div class="iconMenuDrop hidden-md-and-up">
            <v-menu
            transition="slide-y-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div 
                    v-bind="attrs"
                    v-on="on"
                    >
                        <v-icon large :style="{'color':'rgb(247,215,46)'}">mdi-menu</v-icon>
                    </div>
                </template>
                <v-list>
                    <v-list-item
                    v-for="(itemMenu, index) in menuLi"
                    :key="index"
                    >
                    <v-list-item-title 
                    :style="{'color':'rgba(7,46,79,0.9)'}"
                    @click="callPage(itemMenu.flag)"
                    >
                        {{ itemMenu.tit }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <!-- ICON CAUSADOR DE MOVIMENTO -->
        <v-icon large :style="{'transform':rotCog}" class="iconMain">mdi-cog</v-icon>

    </div>
</template>

<script>
export default {
    data() {
        return {
            hoverBackMain:'rgb(247,215,46)',
            hoverBackInitLoja:'rgb(247,215,46)',
            hoverSearch:'rgb(247,215,46)',

            hover1:'rgb(247,215,46)',
            hover2:'rgb(247,215,46)',
            hover3:'rgb(247,215,46)',
            hover4:'rgb(247,215,46)',

            items: ['LED neon','Brindes','Lotes','Chaveiro Spotify','Retrato impresso em madeira','Restauração','Artigos personalizados'],
            values: ['foo', 'bar'],
            value: null,

            // rotacionar engrenagem.
            interval: {},
            valueCog:0,
            rotCog:'rotate(0deg)',

            // menu small
            menuLi:[
                {'tit':'Inicio','flag':'lojaMain'},
                {'tit':'Produtos','flag':'lojaProdutos'},
                {'tit':'Quem somos','flag':'lojaQuemSomos'},
                {'tit':'Suporte','flag':'lojaSuporte'},
                {'tit':'Área cliente','flag':'areaCliente'}
            ],
        }
    },
    methods: {
        chamarMain(rota) {
            this.$router.push(rota)
        },
        rotCogFunc() {
            this.interval = setInterval(() => {

                if (this.valueCog === 180) {

                    this.valueCog = 0
                }

                this.valueCog += 45
                this.rotCog = 'rotate(' + this.valueCog + 'deg)'

            }, 1000)
        },
        callPage(flagPage) {
            this.$store.state.flagPageLoja = flagPage
        },
        callCatalogo() { this.$store.state.flagPageLoja = 'lojaProdutos'; },
    },

    beforeDestroy () {
      clearInterval(this.interval)
    },

    created() {
        this.rotCogFunc()
    }
}
</script>