<style scoped>
    @import url("../../styles/makers/makersLineB.scss");
</style>

<template>
    <div class="box_makersLineB" v-if="!$store.state.flagExpContent">

        <!-- LARGE -->
        <MakersLineBCarsMain :liContentMain="liContentMain" class="hidden-sm-and-down" />
    
    </div>
</template>

<script>
import MakersLineBCarsMain from './MakersLineBCarsMain.vue'
export default {

    props:['liContentMain'],

    components: { MakersLineBCarsMain },
}
</script>