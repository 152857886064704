import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSessionStorage from "vue-sessionstorage"
import Meta from 'vue-meta'
import VueApexCharts from 'vue-apexcharts'

//import './fonts/fonts.scss'
/*import NotoSans from './assets/fonts/NotoSans-Regular.ttf';
console.log(NotoSans)
Vue.use(NotoSans)*/

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueSessionStorage);

Vue.config.productionTip = false

Vue.filter('dinheiro', valor => {
	return `${parseFloat(valor).toFixed(2)} €`.replace('.', '.')
})

Vue.use(Meta);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')