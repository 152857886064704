<style scoped>
@import url("../../styles/makers/makersPageExpContentSmall.scss");
</style>

<template>
  <div class="box_makersPageExpContentSmall">

    <MakersLineAExp />

    <div class="col_pageExpSmall">

        <!-- IMG 1 -->
        <v-img v-if="this.$store.state.infoCardContent.srcExp"
        class="pageExpSmall_img" 
        :src="this.$store.state.infoCardContent.srcExp"
        />
        <!-- IMG 2 -->
        <v-img v-else
        class="pageExpSmall_img" 
        :src="this.$store.state.infoCardContent.src"
        />

        <!-- TITULO -->
        <div class="pageExpSmall_tit">
            <div v-for="(wordTit,i) in this.infoCardContent_tit" :key="i">
                <span :style="{'margin':'5px'}">{{ wordTit }}</span>
            </div>
        </div>

        <!-- TEXTO: modalidade "txt" -->
        <div class="pageExpSmall_txt" v-if="this.$store.state.infoCardContent.txtExp">

            <span class="pageExpSmall_txt_int" v-if="this.$store.state.infoCardContent.txtExp[0]">
                {{ this.$store.state.infoCardContent.txtExp[0] }}
            </span>

            <span class="pageExpSmall_txt_int" v-if="this.$store.state.infoCardContent.txtExp[1]">
                {{ this.$store.state.infoCardContent.txtExp[1] }}
            </span>
            
            <span class="pageExpSmall_txt_int" v-if="this.$store.state.infoCardContent.txtExp[2]">
                {{ this.$store.state.infoCardContent.txtExp[2] }}
            </span>
            
            <span class="pageExpSmall_txt_int" v-if="this.$store.state.infoCardContent.txtExp[3]">
                {{ this.$store.state.infoCardContent.txtExp[3] }}
            </span>

            <span class="pageExpSmall_txt_int" v-if="this.$store.state.infoCardContent.txtExp[4]">
                {{ this.$store.state.infoCardContent.txtExp[4] }}
            </span>

        </div>

        <!-- TEXTO: modalidade "desc" -->
        <div class="pageExpSmall_txt" v-if="this.$store.state.infoCardContent.desc">

            <span class="pageExpSmall_txt_int" v-if="this.$store.state.infoCardContent.desc">
                <span v-for="(el_desc) in this.$store.state.infoCardContent.desc" :key="el_desc">
                    <span :style="{'margin':'2px'}">{{ el_desc }}</span>
                </span>
            </span>

        </div>

        <!-- TITULO DO CONTEÚDO AUXILIAR -->
        <span class="pageExpSmall_tit_b" v-if="this.$store.state.infoCardContent.mainTitCont">
            {{ this.$store.state.infoCardContent.mainTitCont }}
        </span>

        <!-- CONTEÚDO 1 -->
        <div class="pageExpSmall_content_aux">

            <!-- TÍTULO CONTEÚDO 1 -->
            <span class="auxSmall_tit"
            v-if="this.$store.state.infoCardContent.titCont1"
            >
                {{ this.$store.state.infoCardContent.titCont1 }}
            </span>

            <!-- SE houver img no contéudo 1 -->
            <v-img v-if="this.$store.state.infoCardContent.srcCont1"
            class="auxSmall_img" 
            :src="this.$store.state.infoCardContent.srcCont1"
            @click="downloadImg($store.state.infoCardContent.srcCont1,$store.state.infoCardContent.titCont1)"
            />

            <!-- SE houver txt no conteúdo 1 -->
            <span v-if="this.$store.state.infoCardContent.txtCont1"
            class="auxSmall_txt"
            >
                {{ this.$store.state.infoCardContent.txtCont2 }}
            </span>

        </div>

        <!-- CONTEÚDO 2 -->
        <div class="pageExpSmall_content_aux">

            <!-- TÍTULO CONTEÚDO 2 -->
            <span class="auxSmall_tit"
            v-if="this.$store.state.infoCardContent.titCont2"
            >
                {{ this.$store.state.infoCardContent.titCont2 }}
            </span>

            <!-- SE houver img no contéudo 2 -->
            <v-img v-if="this.$store.state.infoCardContent.srcCont2"
            class="auxSmall_img" 
            :src="this.$store.state.infoCardContent.srcCont2"
            @click="downloadImg($store.state.infoCardContent.srcCont2,$store.state.infoCardContent.titCont2)"
            />

            <!-- SE houver txt no conteúdo 2 -->
            <span v-if="this.$store.state.infoCardContent.txtCont2"
            class="auxSmall_txt"
            >
                {{ this.$store.state.infoCardContent.txtCont2 }}
            </span>

        </div>

        <!-- TEXTO DE FINALIZAÇÃO DE EXIBIÇÃO DE CONTEÚDO -->
        <span class="pageExpSmall_last_content"
        v-if="this.$store.state.infoCardContent.txtFinal"
        >
            {{ this.$store.state.infoCardContent.txtFinal }}
        </span>

        <v-icon class="icon_last_content" large @click="chamarInsta()">mdi-instagram</v-icon>

    </div>
  
  </div>
</template>

<script>
import MakersLineAExp from "./MakersLineAExp"

export default {
  components: { MakersLineAExp },

  data() {
    return {
        infoCardContent_tit:'',
    }
  },

  methods: {
    downloadImg(img,tit) {

        const linkSource = img;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = tit; // label da imagem no download.
        downloadLink.click();
    },
    chamarInsta() {
        window.open("https://www.instagram.com/upinc.makers/")
    },
  },

  created() {

    window.scrollTo(0,0)

    if (Array.isArray(this.$store.state.infoCardContent.tit) == false) { // SE não for já um array.

        this.infoCardContent_tit = this.$store.state.infoCardContent.tit.split(' ')
    } else {
        this.infoCardContent_tit = this.$store.state.infoCardContent.tit
    }
  }
}
</script>