<style scoped>
    @import url("../../styles/loja/lojaImgMain.scss");
</style>

<template>
    <div class="box_lojaImgMain">

        <!-- LARGE logoLojaA.png -->
        <div class="logo_movel hidden-sm-and-down">
            <div class="logo_movel_el" :style="{'margin-left':percLeft}">
                <v-img class="img_ecommerce"
                :src="require('../../assets/l0.png')"
                />
                <div class="control_el">
                    <v-btn small icon @click="moveLogoMovel()"><v-icon :style="{'color':colorIcon1}">mdi-arrow-right-drop-circle-outline</v-icon></v-btn>
                    <v-btn small icon @click="stopLaser()"><v-icon :style="{'color':colorIcon2}">mdi-pause-box-outline</v-icon></v-btn>
                </div>
            </div>
        </div>

        <!-- LARGE laser (5).jpg-->
        <div class="img_box ">
            <v-img class="img_el"
            :src="require('../../assets/la0.jpg')"
            />
        </div>

        <!--SMALL
        <div class="img_box hidden-md-and-up" :style="{'height':'150px'}">
            <v-img class="img_el"
            :src="require('../../assets/laser (6).jpg')"
            />
        </div>-->

    </div>
</template>

<script>
export default {
    data() {
        return {
            percLeft:'21%',
            dinamPerc:-6, // INIT
            aux_counter:1,

            elMove:null,

            colorIcon1:'red',
            colorIcon2:null,
        }
    },
    methods:{
        moveLogoMovel () {
            //console.log('teste: inside func')
            this.colorIcon1 = 'red' // reativar laser
            this.colorIcon2 = null

            this.elMove = setInterval(() => {

                this.dinamPerc = this.dinamPerc + 16

                if (this.aux_counter >= 3) { 
                    this.dinamPerc = 7
                    this.aux_counter = 1
                }
                
                this.percLeft = String(this.dinamPerc) + '%'
                
                if (this.dinamPerc >= 45) {
                    this.dinamPerc = 21 // reset
                    this.aux_counter += 1
                }
                
                //this.aux_counter += 1
                //console.log('this.percLeft: ',this.percLeft)

            },2000)
        },
        stopLaser() {
            clearInterval(this.elMove);
            this.colorIcon1 = null
            this.colorIcon2 = 'red'
        },
    },

    created () {
        //console.log('teste: init')
        this.moveLogoMovel()
    }
}
</script>