<style scoped>
    @import url("../../styles/loja/quemSomosInfo.scss");
</style>

<template>
    <div class="elem_info">

        <div class="col_elem animate__animated animate__fadeIn"></div>

        <div class="exib_elem">
            <div class="exib_elem_tit animate__animated animate__fadeInLeft">{{ liExib.tit }}</div>
            <div class="exib_elem_txt animate__animated animate__fadeInLeft">{{ liExib.txt }}</div>
        </div>

    </div>
</template>

<script>
export default {
    
    props:['liExib'],

    data() {
        return {
            //
        }
    }
}
</script>