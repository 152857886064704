<style scoped>
    @import url("../../styles/loja/lojaCarouselGrade.scss");
</style>

<template>
    <div class="box_lojaCarouselGrade">

        <div class="lineCarousel" id="app">

            <div class="tit_carouselHome">
                <v-icon large :style="{'color':'red'}" class="">mdi-fire-circle</v-icon>
                <span>Em destaque</span>
            </div>

            <!-- LARGE -->
            <vue-horizontal responsive class="scrollLineCarousel hidden-sm-and-down" :button-between="false">
                <section 
                v-for="item in items" 
                :key="item.i" 
                class="el_lineCarousel"
                >
                    <v-img class="img_lineCarousel" 
                    :src="item.src"
                    @mouseover="expanImg(item.i)"
                    @mouseleave="backImg()"
                    v-bind:class="{ 'imgCardSelected': item.i == selected }"
                    >
                        <div class="box_action" v-if="item.i == selected">
                            <div class="btn_action">
                                <v-chip 
                                :style="{'cursor':'pointer','margin-bottom':'6px','color':'rgb(20, 40, 53)'}"
                                @click="getInfoPage(item.i)"
                                >
                                    Ver mais</v-chip>
                            </div>
                        </div>
                    </v-img>

                </section>
            </vue-horizontal>

            <!-- SMALL -->
            <vue-horizontal class="scrollLineCarousel hidden-md-and-up"
            responsive
            :button-between="false"
            >
                <section 
                v-for="item in items" 
                :key="item.i" 
                class="el_lineCarousel" 
                :style="{'width':'130px','height':'130px'}"
                >
                    <v-img class="img_lineCarousel" 
                    :src="item.src"
                    @click="expanImg(item.i)"
                    v-bind:class="{ 'imgCardSelected': item.i == selected }"
                    >
                        <div class="box_action" v-if="item.i == selected">
                            <div class="btn_action">
                                <v-chip 
                                :style="{'cursor':'pointer','align-self':'center','margin-top':'80px','color':'rgb(20, 40, 53)'}"
                                @click="getInfoPage(item.i)"
                                >
                                    Ver mais</v-chip>
                            </div>
                        </div>
                    </v-img>

                </section>
            </vue-horizontal>

        </div>
    </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal';

export default {
    name: 'App',
    components: { VueHorizontal },

    data() {
        return {

            selected: null,
            flag_btn_saberMais:false,

            //widthImgLarge:'130px',
            //heightImgLarge:'200px',

            // E.g: creates 20 array items...
            //items: [...Array(20).keys()].map((i) => {
            //    return {i, title: `Responsive`, content: `Content`};
            //}),

            //heightVAR:'200px',
            //boolheightVAR:false,

            items: [
                {'i':0, title: `Responsive`, src:require('../../assets/produtos/letr0.jpeg'), content: `Content1`},
                {'i':1, title: `two`, src:require('../../assets/produtos/p_tel.jpeg'), content: `Content2`},
                {'i':2, title: `three`, src:require('../../assets/produtos/led0.jpeg'), content: `Content3`},
                {'i':3, title: `Responsive`, src:require('../../assets/produtos/neons1.jpeg'), content: `Content1`},
                {'i':4, title: `two`, src:require('../../assets/produtos/mad_dobr.jpeg'), content: `Content2`},
                {'i':5, title: `three`, src:require('../../assets/produtos/ilumi.jpeg'), content: `Content3`},
                {'i':6, title: `three`, src:require('../../assets/produtos/p_chaves1.jpeg'), content: `Content10`},
                {'i':7, title: `three`, src:require('../../assets/produtos/acr_dobr1.jpeg'), content: `Content10`}
            ],
        }
    },

    methods: {
        expanImg(index) {
            //console.log('teste')
            //this.widthImgLarge = '200px'
            //this.heightVAR = '180px'
            //this.boolheightVAR = true // diminuir tamanho do card!
            //console.log(index)

            this.flag_btn_saberMais = true
            this.selected = index;
        },
        backImg() {
            //console.log('saiu')
            //this.widthImgLarge = '130px'
            //this.heightVAR = '200px'
            //this.boolheightVAR = false // restart tamanho do card!

            this.flag_btn_saberMais = false
            this.selected = null;
        },
        getInfoPage(index) {
            //console.log(index,'redirecionando... [teste]')
            console.log(index)
            this.$store.state.flagPageLoja = 'lojaProdutos'
        }
    },
}
</script>