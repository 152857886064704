<style scoped>
    @import url("../../styles/lora/painelExpande.scss");
</style>

<template>
    <div class="box_painelExpande mt-8">

        <div class="painelCardExpansor">
            <!--<div class="tit_faq">FAQ</div>-->
        
            <v-expansion-panels accordion class="cardExpansor">
                <v-expansion-panel v-for="(item,i) in liLoraInfo" :key="i" class="el_cardExpansor mt-1">

                    <v-expansion-panel-header class="titCardExpansor">
                        {{ item.pergunta }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="respCardExpansor">
                        {{ item.resposta }}
                        <v-divider class="mt-1 mb-1"></v-divider>
                        <span>{{ item.resposta2 }}</span>
                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </div>

    </div>
</template>

<script>
export default {

    data() {
        return {

            liLoraInfo: [
                {
                    pergunta:'Introdução', 
                    resposta:'LoRaWAN (Long Range Wide Area Network) é uma tecnologia de comunicação sem fio que permite a conexão de dispositivos à internet em longas distâncias com baixo consumo de energia. Essa tecnologia é ideal para conectar sensores, medidores e outros dispositivos de pequeno porte, que possuem baixa demanda energética, a uma rede sem a necessidade de utilizar Wi-Fi ou 4G.',
                    //resposta2:'Por exemplo, imagine que você tenha um sensor em uma plantação que mede a umidade do solo. Com LoRaWAN, esse sensor pode enviar dados para um sistema central a quilômetros de distância, mesmo estando em uma área rural onde o Wi-Fi ou o sinal de celular não chegam bem. Tudo isso usando pouca energia, o que significa que a bateria do sensor pode durar anos.'
                    resposta2:'',
                },
                {
                    pergunta:'Como verificar se estou conectado à rede?', 
                    resposta:'Inicialmente, solicitamos que verifique no mapa abaixo se a sua localidade está dentro da área coberta pela rede. Caso esteja na área coberta, recomendamos que verifique se a intensidade do sinal é adequada para o seu projeto.',
                    resposta2:'Caso encontre dificuldade em interpretar as informações, por favor, entre em contato com um de nossos agentes.',
                },
                {
                    pergunta:'Interpretando as informações do mapa [SNR]', 
                    resposta:'SNR, ou "Signal-to-Noise Ratio" (Relação Sinal-Ruído), é uma medida que indica a qualidade de um sinal de comunicação. Esse valor é utilizado para avaliar a eficácia com que o sinal é recebido em relação ao nível de ruído de fundo, proporcionando uma compreensão da clareza e da integridade do sinal ao atingir o receptor.',
                    //resposta2:'Imagine que você está tentando ouvir alguém falando em uma sala barulhenta. Se a pessoa fala mais alto do que o barulho ao redor, você consegue entender bem o que ela diz. No entanto, se o barulho for muito alto e a voz for baixa, fica difícil entender. O SNR é como a diferença entre a "voz" (o sinal) e o "barulho" (o ruído). Quanto maior o SNR, melhor a qualidade da comunicação.',
                    resposta2:'',
                },
                {
                    pergunta:'Interpretando as informações do mapa [RSSI]', 
                    //resposta:'RSSI, ou "Received Signal Strength Indicator" (Indicador de Intensidade do Sinal Recebido), é uma medida que indica o quão forte está o sinal de comunicação que um dispositivo LoRaWAN está recebendo.',
                    resposta:'RSSI, ou "Received Signal Strength Indicator" (Indicador de Intensidade do Sinal Recebido), é uma medida que representa a intensidade do sinal de comunicação recebido por um dispositivo LoRaWAN.',
                    //resposta2:'Pense no RSSI como um medidor de "barras de sinal" que você vê no seu celular. Se o RSSI estiver alto, significa que o dispositivo está recebendo um sinal forte, o que é bom para a comunicação. Se estiver baixo, o sinal está fraco, e a comunicação pode ser menos confiável.',
                    resposta2:'',
                },
            ],
        }
    },
    methods: {
        //
    },

    created() {
        window.scrollTo(0,0)
    }
}
</script>