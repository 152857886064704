<style scoped>
    @import url("../../../styles/loja/catalogo/catalogo.scss");
</style>

<template>
    <div class="box_catalogo">

        <div class="tit_c">
            <v-icon large class="icon_tit">mdi-printer-3d</v-icon>
            <span class="el_tit">Produtos e serviços</span>
        </div>

        <PainelCatalogo />

    </div>
</template>

<script>
import PainelCatalogo from './PainelCatalogo'

export default {
    components: { 
        PainelCatalogo
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
        window.scrollTo(0,0)
    }
}
</script>