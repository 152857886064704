<style scoped>
    @import url("../../styles/lora/dashSensor.scss");
</style>

<template>
    <div class="box_dashSensor">

        <div class="primeira_linha1">
            <v-icon x-large :style="{'color':'rgb(31,122,177)'}">mdi-map-marker-check</v-icon>
            <span class="tit">Laboratório UPinC Covilhã</span>
            <span class="sub_tit">Sonsoriamento indoor</span>
            <span class="sub_tit">Última leitura</span>
            <span class="sub_tit" :style="{'font-weight':'bold'}">{{ lastReading.Data }}</span>
        </div>

        <div class="segunda_linha1">

            <div class="cardLeitura">
                <div class="titCardLeitura">Humidade</div>
                <div class="ContentCardLeitura">{{ lastReading.Humidade }}%</div>
            </div>

            <div class="cardLeitura">
                <div class="titCardLeitura">Temperatura</div>
                <div class="ContentCardLeitura">{{ lastReading.Temperatura }}°C</div>
            </div>

        </div>

    </div>
</template>

<script>
export default {

    props:['listaTH'],

    data() {
        return {
            //
            lastReading:{},
        }
    },

    methods:{
        //
    },

    created() {
        //window.scrollTo(0,0)
        //console.log('page dashSensor')
        //console.log('listaTH: ',this.listaTH)

        // -- a lista rcb é invertida. Então o [0] é a última leitura.
        this.lastReading = {
            'Humidade':this.listaTH[0].sensor_readings.rcv_content.humidity,
            'Temperatura':this.listaTH[0].sensor_readings.rcv_content.temperature,
            'Data':this.listaTH[0].received_at
        }
        //console.log('this.lastReading: ',this.lastReading)
    }
}
</script>