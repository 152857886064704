<style scoped>
    @import url("../../styles/loja/suporteLoja.scss");
</style>

<template>
    <div class="box_suporteLoja">

        <div class="tit_suporte">
            <v-icon large class="icon_tit">mdi-headphones</v-icon>
            <span class="el_tit">Bem-Vinda(o) ao suporte UPinC!</span>
        </div>

        <PainelSuporte class="" />
    </div>
</template>

<script>
import PainelSuporte from './PainelSuporte.vue'

export default {
    components: { 
        PainelSuporte,
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
        window.scrollTo(0,0)
    }
}
</script>