<style scoped>
    @import url("../../styles/loja/painelAjusteProd.scss");
</style>

<template>
    <div class="box_painelAjusteProd">

        <!-- form vuetify com radious quadrado -->
        <div class="tipo">

            <div class="msg_ajuste">Nos conte através do ajuste como você quer o seu artigo.</div>

            <v-form ref="form" class="form">

                <div class="check_tipo">
                    <v-checkbox
                        v-model="checkbox1"
                        label="Escolher através das sugestões"
                        required
                        class="mr-4"
                        @click="checkbox(0)"
                    ></v-checkbox>

                    <v-checkbox
                        v-model="checkbox2"
                        label="Descrever por completo o meu pedido"
                        required
                        class="ml-4"
                        @click="checkbox(1)"
                    ></v-checkbox>
                </div>

                <!-- Opções / sugestões de personalização -->
                <div v-if="checkbox1">
                  <!-- SELECT (A) -->
                  <v-select
                      v-model="select_a"
                      :items="material"
                      :rules="[v => !!v || '* Opção obrigatória']"
                      label="Material"
                      required
                      variant="solo-inverted"
                  ></v-select>

                  <!-- SELECT (B) -->
                  <v-select
                      v-model="select_b"
                      :items="tamanho"
                      :rules="[v => !!v || '* Opção obrigatória']"
                      label="Tamanho"
                      required
                      variant="solo-inverted"
                  ></v-select>

                  <!-- REGIÃO DE PERSONALIZAÇÃO ESPECÍFICA DO ARTIGO ---------------------->
                  <v-textarea v-if="$store.state.liProdsLoja[$store.state.idProdLoja]['label_desc'] != ''"
                      color="orange"
                      v-model="desc_pdd"
                      :rules="desc_pdd_rules"
                      :label=label_desc
                      required
                      variant="solo-inverted"
                      clearable
                      counter
                  ></v-textarea>
                  <!----------------------------------------------------------------------->

                  <!-- QUANTIDADE -->
                  <v-text-field
                      v-model="qnt_un"
                      :rules="[qnt_un > 0 || '* Deve haver pelo menos uma unidade']"
                      label="Quantidade"
                      required
                      type="number"
                      suffix="UN"
                      variant="solo-inverted"
                  ></v-text-field>
                </div>

                <!-- DESCREVER TODOS OS DETALHES DO PRODUTO -->
                <div v-if="checkbox2">
                  <!-- DESCRIÇÃO DO PRODUTO A SER CONFECCIONADO -->
                  <v-textarea
                      color="orange"
                      v-model="desc_pdd"
                      :rules="desc_pdd_rules"
                      label="Descreva em detalhes o seu produto."
                      required
                      variant="solo-inverted"
                  ></v-textarea>
                </div>

                <!-- CHECKOUT PRODUTO -> input CARRINHO DE COMPRA -->
                <CheckOutProd 
                :nomeProduto="nomeProduto"
                :checkbox1="checkbox1"
                :checkbox2="checkbox2"
                :material="material"
                :select_a="select_a"
                :pr_ref_material="pr_ref_material"
                :tamanho="tamanho"
                :select_b="select_b"
                :tam_ref_material="tam_ref_material"
                :qnt_un="qnt_un"
                :desc_pdd="desc_pdd"
                />

            </v-form>
        </div>

    </div>
</template>

<script>
import CheckOutProd from './CheckOutProd'

export default {
    
    props:['nomeProduto'],

    components: { CheckOutProd },

    data: () => ({
      //valid: true,
      //name: '',
      desc_pdd_rules: [
        v => !!v || '* Descreva como quer o seu pedido',
        v => (v && v.length <= 300) || 'Máximo 300 caracteres',
      ],
      select_a: null,
      select_b: null,
      desc_pdd: '',
      qnt_un: 0,
      
      // artigo.
      label_desc:'Escreva a música que você quer gravar',
      material: [
        'madeira MDF cor branca (recomendado)',
        'madeira MDF',
        'Acrílico preto transparente'
      ],
      pr_ref_material:[
        {
          'ref_m2':240,
          'pr_ref_m2':3.3
        },
        {
          'ref_m2':200,
          'pr_ref_m2':3.7
        },
        {
          'ref_m2':60,
          'pr_ref_m2':5.5
        }
      ], // preço base para o cálculo do custo do material escolhido.
      tamanho: [
        'Pequeno (10x40x3 mm)',
        'Médio (20x60x3 mm)',
        'Grande (30x100x3 mm)'
      ],
      tam_ref_material:[ // milímetro [mm]
        {
          'altura':10,
          'largura':40,
          'profundidade':3,
        },
        {
          'altura':20,
          'largura':60,
          'profundidade':3,
        },
        {
          'altura':30,
          'largura':100,
          'profundidade':3,
        },
      ], // tamanho (float) para o cálculo.

      checkbox1: true,
      checkbox2: false,
    }),

    methods: {

      checkbox(numFlag) {
        if(numFlag == 0) {
          this.checkbox1 = true
          this.checkbox2 = false
        } else {
          this.checkbox1 = false
          this.checkbox2 = true
        }
        //console.log('this.checkbox1: ',this.checkbox1)
        //console.log('this.checkbox2: ',this.checkbox2)
      },

      async validate () {
        const { valid } = await this.$refs.form.validate()

        if (valid) alert('Form is valid')
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>