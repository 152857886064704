<style scoped>
    @import url("../../styles/makers/makersLineD.scss");
</style>

<template>
    <div class="box_makersLineD">

        <div class="painel_content_lineD">
            
            <!-- LARGE -->
            <MakersNoticeCardB60 
            class="hidden-sm-and-down"
            :infoCardB="liContent_D[0]" 
            />

            <!-- SMALL 
            <MakersNoticeCardB60 
            class="hidden-md-and-up"
            :infoCardB="liContent_D[0]"
            :style="{'flex-wrap':'wrap'}"
            />-->

            <MakersNoticeCardB40 :infoCardB="liContent_D[1]" />
        </div>
    
    </div>
</template>

<script>
import MakersNoticeCardB60 from './MakersNoticeCardB60'
import MakersNoticeCardB40 from './MakersNoticeCardB40'

export default {
    components: { MakersNoticeCardB60, MakersNoticeCardB40 },

    props: ['liContent_D'],
}
</script>