<style scoped>
    @import url("../../styles/loja/areaCliente.scss");
</style>

<template>
    <div class="box_areaCliente">

        <!-- VERSÃO LARGE <v-icon>mdi-account</v-icon> -->

        <div class="painel_login_dashagro" v-if="$store.state.flagPageLoginCl">

            <div
            class="btn_voltar_init_loja"
            @mouseover="hoverBackAgro = 'rgb(247,215,46)'"
            @mouseleave="hoverBackAgro = 'rgb(31,122,177)'"
            @click="chamarRota()" 
            >
                <v-icon :style="{'color': hoverBackAgro}">mdi-arrow-left-thick</v-icon>
                <span :style="{'font-size': '0.8rem'}">Início Loja</span>
            </div>

            <div class="img_logo_login">

                <v-img class="el_img_logo_login" 
                :src="imgLogoECommerce"
                />
            </div>

            <div class="txt_img_logo_login" v-if="!$store.state.flagCadastroUser">Log in</div>
            <div class="txt_img_logo_login" v-if="$store.state.flagCadastroUser">Cadastro</div>

            <div class="dados_login" v-if="!$store.state.flagCadastroUser && $store.state.flagPageLoginCl">
                <PainelLogin />
            </div>

            <div class="dados_login" v-if="$store.state.flagCadastroUser && $store.state.flagPageLoginCl">
                <Cadastro />
            </div>
        </div>

        <div class="painel_user_dash_cl" v-if="!$store.state.flagPageLoginCl">
            <ClPage />
        </div>

    </div>
</template>

<script>
import PainelLogin from './PainelLogin'
import Cadastro from './Cadastro'
import ClPage from './ClPage'

export default {
    components: { PainelLogin, Cadastro, ClPage },

    data() {
        return {
            //imgLogoECommerce:require('../../assets/logoLojaA.png'),
            imgLogoECommerce:require('../../assets/l1.png'),

            hoverBackAgro:'rgb(31,122,177)',
        }
    },

    methods: {
        chamarRota() {
            this.$store.state.flagPageLoja = 'lojaMain'
        },
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>