<style scoped>
    @import url("../../styles/makers/painelTR.scss");
</style>

<template>
    <div class="box_painelTR">

        <iframe :src="url_datacake"
        width="100%"
        height="1200px" />

    </div>
</template>

<script>
export default {
    //
    data() {
        return {
            url_datacake:'https://app.datacake.de/pd/6428e365-b610-49f4-84e0-160699e73717',
        }
    }
}
</script>