<style scoped>
    @import url("../../styles/lora/quadLegendGwFound.scss");
</style>

<template>
    <div class="box_quadLegendGwFound">

        <!-- LINHA 1 -->
        <div class="lineLegend">
            <span class="elLegend">Gateways localizadas:</span>
            <span class="elLegend" :style="{'color':'rgb(31,122,177)'}">{{ liContadores[0] }}</span>
        </div>

        <!-- LINHA 2 -->
        <div class="lineLegend">
            <span class="elLegend">Gateways não localizadas:</span>
            <span class="elLegend" :style="{'color':'rgb(31,122,177)'}">{{ liContadores[1] }}</span>
        </div>

        <!-- LINHA 3 -->
        <div class="lineLegend">
            <span class="elLegend">Total gateways identificadas:</span>
            <span class="elLegend" :style="{'color':'rgb(31,122,177)'}">{{ liContadores[2] }}</span>
        </div>
    </div>
</template>

<script>
export default {

    //props:['liContadores'],
    props:{
        liContadores: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            //
        }
    },
    methods: {
        //
    },

    watch: {
        liContadores(newValue, oldValue) {
        console.log('(watch) Variável foi atualizada de', oldValue, 'para', newValue);
        // Aqui você pode executar lógica quando a prop mudar
        }
    },

    created() {
        //window.scrollTo(0,0)
        //console.log('(via propos) [init] liContadores: ',this.liContadores)
    }
}
</script>