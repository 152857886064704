<style scoped>
    @import url("../../styles/lora/infoLoraInitPage.scss");
</style>

<template>
    <div class="box_infoLoraInitPage">

        <div class="titLoraInitPage">
            <v-icon large class="" :style="{'color':'rgb(31,122,177)'}">mdi-map-marker-path</v-icon>
            <span class="mt-2">Rede LoRaWAN</span>
        </div>
        
        <div class="linha1InfoLora">
            <span class="mr-2" v-for="(el,i) in liFraseTit" :key="i">{{ el }}</span>
        </div>

        <!--<div class="imgInfoLoraInitPage mt-4"
        :style="{
        backgroundImage:'url(' + require('../../assets/loraEx.png') + ')',
        backgroundSize: 'cover', //'contain',
        backgroundPosition: 'center'
        }"></div>-->

        <PainelExpande class="" />

        <v-btn class="mt-8" color="#092D4F" 
        :style="{'color':'rgba(247,215,46,1)'}"
        @click="callMapLora(strStatusBtnMapLora)"
        >
            <span>{{ strStatusBtnMapLora }} mapa da rede</span>
        </v-btn>

    </div>
</template>

<script>
import PainelExpande from './PainelExpande'

export default {

    props:['strFromPaiStatusBtn'],

    components:{ PainelExpande },

    data() {
        return {
            //
            strStatusBtnMapLora:'Mostrar',

            //
            liFraseTit:['O','que','é','preciso','compreender','para','utilizar','esta','tecnologia','de','comunicação?'],
        }
    },

    methods: {
        callMapLora(strStatusBtnMapLora) { // evento de habilitar e desabilitar mapa lora.
            if (strStatusBtnMapLora == 'Mostrar') {

                this.strStatusBtnMapLora = 'Esconder'
                this.$emit('callMapLora',true)

            } else {

                this.strStatusBtnMapLora = 'Mostrar'
                this.$emit('callMapLora',false)
            }
        }
    },

    created() {
        this.strStatusBtnMapLora = this.strFromPaiStatusBtn
    }
}
</script>