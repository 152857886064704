<style scoped>
    @import url("../../styles/makers/makersLineA.scss");
</style>

<template>
    <div class="box_makersLineA">

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                v-bind="attrs"
                v-on="on"
                large 
                @mouseover="hoverBackMain = 'rgb(31,122,177)'"
                @mouseleave="hoverBackMain = 'rgb(247,215,46)'"
                @click="chamarMain('/')" 
                class="icon_instagram"
                :style="{'color': hoverBackMain}"
                >
                    mdi-arrow-top-left-thick
                </v-icon>
            </template>

            <span>Voltar ao início</span>

        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                v-bind="attrs"
                v-on="on"
                large 
                @mouseover="hoverInsta = 'rgb(31,122,177)'"
                @mouseleave="hoverInsta = 'rgb(247,215,46)'"
                @click="chamarInsta()" 
                class="icon_instagram"
                :style="{'color': hoverInsta}"
                >
                    mdi-instagram
                </v-icon>
            </template>

            <span>Conheça nosso instagram</span>

        </v-tooltip>

    </div>
</template>

<script>
export default {
    data() {
        return {
            hoverInsta:'rgb(247,215,46)',
            hoverBackMain:'rgb(247,215,46)',
        }
    },
    methods: {
        chamarInsta() {
            //window.location = 'https://www.instagram.com/upinc.makers/'

            // Abertura de nova aba na mesma janela.
            window.open("https://www.instagram.com/upinc.makers/")

            // Abertura em nova janela.
            //var win = window.open("https://www.instagram.com/upinc.makers/", "Nova Janela", "height=200,width=200");
            //win.focus();
        },
        chamarMain(rota) {
            this.$router.push(rota)
        }
    }
}
</script>