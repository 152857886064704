<style scoped>
    @import url("../../styles/loja/painelSendLoja.scss");
</style>

<template>
    <div class="box_painelLogin">

        <v-form class="session_form"
        ref="form"
        v-model="valid"
        lazy-validation
        >
            <v-textarea
            color="orange"
            v-model="msgClient"
            :rules="nameRules"
            label="Breve descrição"
            required
            ></v-textarea>

        </v-form>

        <div class="btn_login">
            <v-btn
            small
            :disabled="!valid"
            color="orange"
            class="mr-4 mb-1"
            @click="sendMsgQ(true)"
            >
                enviar
            </v-btn>

            <!-- btn só é exibido no componente dentro da área do cliente -->
            <v-btn v-if="!$store.state.flagPageLoginCl"
            small
            color="white"
            class="mr-4 mb-1 ml-1"
            @click="sendMsgQ(false)"
            >
                cancelar
            </v-btn>
        </div>

        <span v-if="flagErrorSend"
        :style="{'margin-top':'10px','color':'rgb(175, 28, 9)','font-size':'0.8rem'}">
            {{ msgErrorSend }}
        </span>

    </div>
</template>

<script>
export default {
    data() {
        return {
            msgClient:'',
            hoverCadastro:'rgb(31,122,177)',
            flagErrorSend:false,
            msgErrorSend:'Informações inválidas',

            valid: true,
            nameRules: [
                v => !!v || 'Preenchimento obrigatório',
            ],
        }
    },

    methods: {
        sendMsgQ(flag) {

            if(!flag) { this.$emit('eventSendQ', false) }

            if (this.$refs.form.validate()) {
                //console.log('msgClient: ',this.msgClient)

                if (this.msgClient.length > 5) {
                    //console.log('msgClient: ',this.msgClient)
                    
                    // BUSCAR email e nome do cliente logado.
                    //var params = {'ref':'c','cliente':self.nameClient,'email':self.emailClient,'desc':self.msgClient}

                    // ENVIAR MSG AQUI.
                    
                    // outra confirmação entra aqui..
                    this.flagErrorSend = false
                    
                    // emitir callback de confirmação.
                    if(flag) { this.$emit('eventSendQ', true) } else { this.$emit('eventSendQ', false) }

                    // reset
                    this.msgClient = ''

                    //console.log('teste send info')
                } else {

                    this.flagErrorSend = true
                    return
                }

                this.flagErrorSend = false
            }
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>