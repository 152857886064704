<style scoped>
    @import url("../../styles/loja/payConfirmMet.scss");
</style>

<template>
    <div class="box_payConfirmMet">

        <v-icon class="mt-2 mb-2" color="green" large>mdi-check-decagram</v-icon>

        <div class="txt_tit">Obrigado por escolher a UPinC. Enquanto aguarda a confirmação de pagamento você pode verificar a sua área de cliente ou navegar pela nossa coleção de artigos.</div>

        <div class="box_selectRota mt-2 mb-2">

            <div @click="$store.state.flagPageLoja = 'lojaProdutos'"
            class="cardRota" 
            @mouseover="shadowCardA ='0px 1px 2px #F7D72E'" 
            @mouseleave="shadowCardA = '0px 1px 2px #4A93BF'" 
            :style="{'box-shadow':shadowCardA}"
            >
                <span class="tit">Ver mais artigos</span>
                <v-icon class="ml-2" small>mdi-printer-3d</v-icon>
            </div>

            <div @click="$store.state.flagPageLoja = 'areaCliente'"
            class="cardRota mt-2" 
            @mouseover="shadowCardB ='0px 1px 2px #F7D72E'" 
            @mouseleave="shadowCardB = '0px 1px 2px #4A93BF'" 
            :style="{'box-shadow':shadowCardB}"
            >
                <span class="tit">Área cliente</span>
                <v-icon class="ml-2" small>mdi-account</v-icon>
            </div>
        
        </div>

    </div>
</template>

<script>
export default {
    //components: {},

    data(){
        return {
            shadowCardA: '0px 1px 2px #4A93BF',
            shadowCardB: '0px 1px 2px #4A93BF',
        }
    },

    methods:{
        //
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>