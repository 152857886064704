<style scoped>
    @import url("../../styles/loja/payMet.scss");
</style>

<template>
    <div class="box_payMet">

        <!-- À ESQUERDA OS DADOS DO PAGAMENTO PARA SEREM CONFIRMADOS -->
        <div class="lado_a">

            <v-icon x-large class="mt-6">mdi-card-account-details</v-icon>

            <div class="tit">
                
                <span class="ml-2">Resumo das suas informações</span>

                <div 
                @click="callClPage()"
                class="editPay mr-2" 
                @mouseover="backColorBtn1 = 'rgba(255,255,255,0.4)'" 
                @mouseleave="backColorBtn1 = ''"
                :style="{'background-color':backColorBtn1}">
                    <span>Editar</span>
                    <v-icon small>mdi-account-edit</v-icon>
                </div>

            </div>

            <div class="box_infoCl">

                <div class="line_infoCl" v-for="(el,i) in teste_li" :key="i">
                    <span class="el_line_a">{{ el.a }}</span>
                    <v-divider width="100%" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                    <span class="el_line_b">{{ el.b }}</span>
                </div>

            </div>

            <!-- <v-divider width="90%" class="ml-2 mr-2 mt-4" :style="{'align-self':'center'}"></v-divider> -->
            <div class="tit">
                
                <span class="ml-2">Detalhes do pagamento</span>

                <div 
                @click="callCartPage()"
                class="editPay mr-2" 
                @mouseover="backColorBtn2 = 'rgba(255,255,255,0.4)'" 
                @mouseleave="backColorBtn2 = ''"
                :style="{'background-color':backColorBtn2}">
                    <span>Editar</span>
                    <v-icon small>mdi-cart-variant</v-icon>
                </div>

            </div>

            <div class="box_infoCl mb-2">

                <div class="line_infoCl" v-for="(el,i) in teste_li2" :key="i">
                    <span class="el_line_a">{{ el.a }}</span>
                    <v-divider width="100%" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                    <span class="el_line_b">{{ el.b | dinheiro }}</span>
                </div>

            </div>

            <!--<div class="btnConfirmInfoPay">
                <v-btn block small color="green">confirmar</v-btn>
            </div>-->

        </div>

        <!-- À DIREITA A EXECUÇÃO/CHECKOUT DO PAGAMENTO -->
        <div class="lado_b">

            <div class="btnConfirmInfoPay mt-2" v-if="!flagRefPay" @click="callRef()">
                <v-icon class="mb-2">mdi-alert-circle-check</v-icon>
                <span class="txt mb-2">Verifique todas as informações antes de gerar a sua referência de pagamento.</span>
                <v-btn color="green" class="mb-2">gerar referência</v-btn>
            </div>

            <div class="painelRef" v-if="flagRefPay">

                <v-icon class="mb-2 mt-2" color="green">mdi-alert-circle-check</v-icon>

                <div class="box_info_ref">

                    <div class="lineInfo">
                        <span :style="{'font-weight':'bold'}">Entidade</span>
                        <v-divider width="100%" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                        <span>d15s6a</span>
                    </div>

                    <div class="lineInfo">
                        <span :style="{'font-weight':'bold'}">Referência</span>
                        <v-divider width="100%" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                        <span>133-321-222</span>
                    </div>
                </div>

                <span class="txt2 mt-4">
                    <span class="elfor" v-for="(elMsg,i) in liFrase" :key="i">
                        <span v-if="i == 7 || i == 8" :style="{'color':'red'}">{{ elMsg }}</span>
                        <span v-else>{{ elMsg }}</span>
                    </span>
                </span>

                <v-btn class="mt-2 mb-2" color="green" small @click="callConfirmMet()">Já fiz o pagamento</v-btn>
            </div>

        </div>

    </div>
</template>

<script>
export default {

    pros:['met'],

    data() {
        return {
            liFrase:['Os',' dados',' gerados',' têm',' a',' validade',' de',' 3',' minutos.',' Após',' esse',' prazo',' novos',' dados',' devem',' ser',' gerados.'],
            flagRefPay:false,
            entPay:'',
            refPay:'',
            backColorBtn1:'', // btn editar 1. info user.
            backColorBtn2:'', // btn editar 2. info cart.
            teste_li:[
                {
                    'a':'Nome','b':'UPINC'
                },
                {
                    'a':'NIF','b':'122332123'
                },
                {
                    'a':'Morada','b':'Rua Teste com o outro teste n60'
                },
                {
                    'a':'Codigo postal','b':'6200-000'
                },
                {
                    'a':'Localidade','b':'Covilhã'
                },
                {
                    'a':'Pais','b':'Portugal'
                },
                {
                    'a':'Telefone','b':'999666999'
                },
            ],
            teste_li2:[
                {
                    'a':'Desconto','b':'1.25'
                },
                {
                    'a':'Taxa de entrega','b':'5.0'
                },
                {
                    'a':'Subtotal','b':'11.25'
                },
                {
                    'a':'IVA (23%)','b':'2.9'
                },
                {
                    'a':'Total','b':'19.15'
                },
            ],
        }
    },

    methods:{
        callConfirmMet() {
            // callback envia e altera a string flag de navegação entre as pág. pay.

            this.$emit('eventConfirmMet', ['payConfirmMet']) // método escolhido e a flag de seleção do componente.
        },
        callRef() {
            // solicitar referência, enviando as info cl.

            // SE ref rcb:
            this.entPay = 'colocar nessa var'
            this.refPay = ' colocar nessa var'

            this.flagRefPay = true // exibi-las.

            this.$store.state.flagPayPage = false // inidica op pay sendo encerrada.
        },
        opPayMB() {
            
            // call api. criptografar até chegar na api intermédia interna?

                // SE pay confirm ok. Então call db e depois call cl page.

                    // req post new info cl to db.

                        // chamar cl page.
                        //this.$store.state.flagPageLoja = 'areaCliente'
        },
        opPayCripto(){
            
            //

            // this.$store.state.flagPayPage = false // inidica op pay sendo encerrada.
        },
        callClPage() {
            this.$store.state.flagCl = 'options' // habilitar opção de ediçõ interna à pág. cl.
            this.$store.state.flagPageLoja = 'areaCliente' // habilitar pág. cl.
        },
        callCartPage() {
            console.log('call cart page!')

            // atribuir ao item de edição de carrinho o 1º artigo do carrinho. Fazer quando a lista de produtos estiver concluída.

            this.$store.state.flagPageLoja = 'produto'
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.

        // RECEBER INFO CL. CONVERTER PARA O FORMATO DE EXIBIÇÃO.
    }
}
</script>