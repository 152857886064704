<style scoped>
    @import url("../../styles/makers/makersLineAExp.scss");
</style>

<template>
    <div class="box_makersLineAExp">

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                v-bind="attrs"
                v-on="on"
                large 
                @mouseover="hoverBackMain = 'rgb(31,122,177)'"
                @mouseleave="hoverBackMain = 'rgb(247,215,46)'"
                @click="chamarMakerSpace()" 
                class="icon_back"
                :style="{'color': hoverBackMain}"
                >
                    mdi-arrow-top-left-thick
                </v-icon>
            </template>

            <span>Voltar ao MakerSpace</span>

        </v-tooltip>

    </div>
</template>

<script>
export default {
    data() {
        return {
            hoverBackMain:'rgb(247,215,46)',
        }
    },
    methods: {
        chamarMakerSpace() {

            // Habilitar main page makers.
            // Desabilitar página de extensão de exibição de conteúdo.

            this.$store.state.flagExpContent = false
        }
    }
}
</script>