<style scoped>
    @import url("../../styles/lora/pageLora.scss");
</style>

<template>
    <div class="box_pageLora" v-if="!$store.state.flagMenuSup"
    :style="{
    backgroundImage:'url(' + require('../../assets/fundo_t.png') + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
    }"
    >
        <!--<MenuSupMainV2 @callByMenu="returnFromMenu($event)" />

        <MenuBodyMainV2 v-if="flagMenuSup" />-->

        <InfoLoraInitPage @callMapLora="callMapLora($event)" :strFromPaiStatusBtn="strFlagBtn" />

        <MapLora v-if="flagShowMap" />

        <ContactoUp />
    </div>
</template>

<script>
//import MenuSupMainV2 from '../mainV2/MenuSupMainV2'
//import MenuBodyMainV2 from '../mainV2/MenuBodyMainV2'

import InfoLoraInitPage from './InfoLoraInitPage'
import MapLora from './MapLora'

import ContactoUp from './ContactoUp'

export default {

    components: { /*MenuSupMainV2, MenuBodyMainV2,*/ InfoLoraInitPage, MapLora, ContactoUp },

    data() {
        return {
            //
            //flagMenuSup:false,
            flagShowMap:true,

            //strFlagBtn:'Mostrar', // flag string de controle da frase do btn no componente InfoLoraInitPage.vue
            strFlagBtn:'Esconder',
        }
    },

    methods: {
        /*returnFromMenu(flagStringMenuSmall) {

            if (flagStringMenuSmall == 'open') { 
                this.flagMenuSup = true 

                this.flagShowMap = false // reset btn | adaptação do menu sup a esta página.
                this.strFlagBtn = 'Mostrar' // reset btn | adaptação do menu sup a esta página.
            } else { 
                this.flagMenuSup = false 
            }
        },*/
        callMapLora(boolStatusMapLora) { this.flagShowMap = boolStatusMapLora }
    },

    created() {
        this.$store.commit('init') // despertar api.
    }
}
</script>