<style scoped>
    @import url("../../styles/loja/interPagProdEmail.scss");
</style>

<template>
    <div class="box_interPagProdEmail">

        <!-- VERSÃO LARGE -->

        <!-- LINHA INIT: marcador de etapas entre páginas -->
        <div class="etapas mt-6 mb-3">
            <div class="iconsEtapas">
                <v-icon :style="{'color':'#4B94C0'}">mdi-wrench</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                
                <v-icon>mdi-account-credit-card</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                
                <v-icon>mdi-check-circle-outline</v-icon>
            </div>
        </div>
        <div class="etapas mb-10">
            <div class="iconsEtapas">
                <!-- <v-icon :style="{'color':'#4B94C0'}">mdi-wrench</v-icon> -->
                <v-icon :style="{'color':'#4B94C0'}">mdi-subdirectory-arrow-right</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                
                <!--<v-icon :style="{'color':'#4B94C0'}">mdi-account-question</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                -->

                <v-icon :style="{'color':'#4B94C0'}">mdi-email-fast-outline</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>

                <v-icon :style="{'color':corIconCheck}">mdi-check-circle-outline</v-icon>
            </div>
        </div>

        <!-- painel de envio EMAIL -->
        <div class="btns_painel">
            
            <PainelSendPdd @eventSend="eventRcvInfoSend($event)" v-if="!flagSend" />

            <!-- MSG confirmação de envio -->
            <div v-if="flagSend">
                <v-icon :style="{'color':corIconCheck}" large>mdi-check-circle-outline</v-icon>
                <span :style="{'font-size':'0.8rem'}">{{ msg1 }}</span>
            </div>
        </div>

        <v-btn 
        @click="$store.state.flagPageLoja = 'lojaProdutos'"
        v-if="flagSend" class="mt-2" width="250" color="green">ver mais produtos</v-btn>

    </div>
</template>

<script>
import PainelSendPdd from './PainelSendPdd'

export default {
    components: { PainelSendPdd },

    data() {
        return {
            corIconCheck:'',

            msg1:'Seu pedido foi enviado com sucesso. Durante as próximas 24h você receberá um e-mail referente a sua ordem.',
            flagSend:false, // ativa e desativa componente de envio.
            //flagSendLocal:false, // apenas aciona o componente de confirmação de envio.
        }
    },
    methods: {
        eventRcvInfoSend(el) { // el list: flag + email

            this.corIconCheck = 'green'
            this.flagSend = el[0] // flag bool. SE true indicada que tentou enviar o email.
            
            if (this.flagSend) { // sse o email estiver sido enviado.

                var email = el[1]

                // Essa rotina só deve ser executada se for um pedido de orçamento. Se for um pagamento esse componente não é válido. Infos insuficientes.

                // request get info cl: qnt de cls no db.

                var id_cl_novo = this.$store.state.li_simu_cl.length // serve para qualquer condição de fluxo abaixo.
                
                // rcv info atual local do cl logado.
                //var info_cl = this.$store.state.info_cl

                // init itens.
                var somaDesconto = 0
                var somaSubtotal = 0
                var somaImposto = 0
                var somaPrFinal = 0
                var msgStatus = 'Orçamento em análise'

                // novo pedido.
                var novo_pdd = {
                    'id_pdd': 101,
                    'date_pdd':'01/01/2001', // automatizar data de agora: Data do input.
                    'status_pdd':msgStatus,
                    'desconto':somaDesconto, // soma de todos os descontos, se houverem.
                    'subtotal':somaSubtotal, // soma.
                    'imposto':somaImposto, // soma.
                    'prFinal':somaPrFinal, // soma.
                    'pdd':this.$store.state.liCartGlobal
                }

                // LOCAL. (Qual o motivo de preencher o user local apenas com email?)

                /*this.$store.state.info_cl = {
                    'id_cl':id_cl_novo,
                    'email_cl':email,
                    'nome_cl':'',
                    'pass_cl':'',
                    'endereco_cl':'',
                    'fone_cl':'',
                    'pedidos':[novo_pdd],
                    'status':'Aberto' // pdd
                }*/

                // DB.

                var novo_cl = {
                    'id_cl': id_cl_novo,
                    'email_cl':email,
                    'nome_cl':'',
                    'pass_cl':'',
                    'endereco_cl':'',
                    'fone_cl':'',
                    'pedidos':[novo_pdd],
                    'status':'aberto' // pdd de orçamento sendo inputado.
                }

                // req post new info cl to db.
                console.log('novo cl: ',novo_cl)
                
                this.$store.state.flagOpSubmitPdd = false // fim de operação de movimento com o pdd.

                //this.flagSendLocal = true // após confirmação de envio do email.

                // reset carrinho de compras.
                this.$store.state.liCartGlobal = []

                // reset.
                // variáveis globais de operação financeira.
                this.$store.state.pr_inicialCartGlobal = 0
                this.$store.state.descontoCartGlobal = 0
                this.$store.state.subtotalCartGlobal = 0
                this.$store.state.impostoCartGlobal = 0
                this.$store.state.pr_finalCartGlobal = 0
            }
            //console.log('flag after try send: ',this.flagSend)
        }
    }
}
</script>