<style scoped>
    @import url("../../styles/loja/painelResumoProd.scss");
</style>

<template>
    <div class="box_painelResumoProd">

        <!-- VERSÃO LARGE -->
        
        <span class="txt_sup">Resumo do pedido e informações financeiras</span>

        <div v-if="$store.state.liCartGlobal < 1"
        :style="{'display':'flex','justify-content':'center'}" 
        class="mb-2"
        >
            <span :style="{'font-size':'0.7rem'}">O seu carrinho ainda está vazio.</span>
            <v-icon small>mdi-cart-minus</v-icon>
        </div>

        <div class="painel_chip" v-for="(el,i) in $store.state.liCartGlobal" :key="i">
            
            <div class="el_info">
                <span class="el_tit ml-4" label color="pink" text-color="white">
                    <span>{{ el.name }}</span>
                    <span class="el_tit_secund" :style="{'color':'white'}">{{ el.desc }}</span>
                    <span class="el_tit_secund" v-if="el.pr > 0">{{ el.material }}</span>
                    <span class="el_tit_secund" v-if="el.pr > 0">{{ el.tam }}</span>
                    <span class="el_tit_secund" v-if="el.pr > 0">x {{ el.qnt }}</span>
                </span>

                <div class="el_pr mr-4" v-if="el.pr > 0">{{ el.pr | dinheiro }}</div>
            </div>

            <!--<div class="el_edit"
            @mouseover="hoverEditFunc('on',i)"
            @mouseleave="hoverEditFunc('off',i)"
            :style="{'color': el.hoverEdit,'border': el.hoverEditBorder}"
            >Editar</div>-->

            <div class="el_closed"
            @mouseover="hoverEditFunc('on',i)"
            @mouseleave="hoverEditFunc('off',i)"
            :style="{'color': el.hoverEdit,'border': el.hoverEditBorder}"
            @click="cancel_prod_from_cart(i)"
            >X</div>
        </div>

        <v-divider class="mb-1"></v-divider>

        <v-btn
        small
        color="green"
        class="text--white"
        @click="$store.state.flagPageLoja = 'lojaProdutos'"
        >
        Ver mais produtos
        </v-btn>

        <v-divider class="mb-6 mt-1"></v-divider>

        <div class="painel_fin" v-if="!$store.state.flagCartParaAnalise && $store.state.liCartGlobal.length > 0">

            <div class="el_fin" v-if="$store.state.descontoCartGlobal > 0">
                <span>Desconto</span>
                <span>{{ $store.state.descontoCartGlobal | dinheiro }}</span>
            </div>

            <div class="el_fin">
                <span>Subtotal</span>
                <span>{{ $store.state.subtotalCartGlobal | dinheiro }}</span>
            </div>

            <div class="el_fin">
                <span>IVA (23%)</span>
                <span>{{ $store.state.impostoCartGlobal | dinheiro }}</span>
            </div>

            <div class="el_fin" v-if="$store.state.pr_finalCartGlobal >= 10">
                <span>Total</span>
                <span>{{ $store.state.pr_finalCartGlobal | dinheiro }}</span>
            </div>

            <!-- VALOR MENOR QUE 10 EUROS -->
            <div v-if="$store.state.pr_finalCartGlobal < 10"
            :style="{'display':'flex','justify-content':'flex-end','color':'red'}">{{ $store.state.pr_finalCartGlobal | dinheiro }}</div>
            <div class="el_fin" v-if="$store.state.pr_finalCartGlobal < 10">
                <span>Total</span>
                <span>10.00 €</span>
            </div>
            <div v-if="$store.state.pr_finalCartGlobal < 10"
            :style="{'display':'flex','justify-content':'flex-end','color':'red','font-size':'0.7rem'}"
            >Valor mínimo 10.00 €</div>
        </div>

        <v-divider class="mb-6" v-if="$store.state.flagCartParaAnalise"></v-divider>

        <div class="msg_alerta" v-if="$store.state.flagCartParaAnalise">{{ msg1 }}</div>
        <div class="msg_alerta" v-if="$store.state.flagCartParaAnalise">{{ msg2 }}</div>

        <v-divider class="mb-6"></v-divider>

        <div class="painel_btn">
            <v-btn
            small
            :disabled="$store.state.liCartGlobal.length < 1 || !$store.state.flagCartParaAnalise"
            color="rgb(247,215,46)"
            class="mr-1 mt-1"
            @click="callEndPdd(false)"
            >
            Submeter pedido
            </v-btn>

            <v-btn
            small
            :disabled="$store.state.liCartGlobal.length < 1 || $store.state.flagCartParaAnalise"
            color="green"
            class="mt-1"
            @click="callEndPdd(true)"
            >
            Finalizar compra
            </v-btn>
        </div>

    </div>
</template>

<script>
//import PainelAjusteProd from './PainelAjusteProd'
export default {
    //components: { PainelAjusteProd },

    data() {
        return {
            msg1:'Você está a criar uma solicitação de orçamento. Após finalizar o pedido será analisado e você receberá um retorno na sua área de cliente e/ou por e-mail em até 24h.',
            msg2:'Após o retorno você poderá decidir se quer avançar e a opção de pagamento ficará disponível.',

            //valid1: true,
            //valid2: false,
        }
    },

    methods: {
        hoverEditFunc(flag,index) {
            if (flag == 'on') {
                this.$store.state.liCartGlobal[index].hoverEdit = 'rgb(247,215,46)'
                this.$store.state.liCartGlobal[index].hoverEditBorder = '1px solid rgb(247,215,46)'
            } else {
                this.$store.state.liCartGlobal[index].hoverEdit = 'rgb(31,122,177)'
                this.$store.state.liCartGlobal[index].hoverEditBorder = '1px solid rgb(31,122,177)'
            }
        },
        cancel_prod_from_cart(index){

            this.$store.state.pr_inicialCartGlobal = this.$store.state.pr_inicialCartGlobal - this.$store.state.liCartGlobal[index]['pr']
            this.$store.state.descontoCartGlobal = this.$store.state.descontoCartGlobal - this.$store.state.liCartGlobal[index]['desconto']
            this.$store.state.subtotalCartGlobal = this.$store.state.subtotalCartGlobal - this.$store.state.liCartGlobal[index]['subtotal']
            this.$store.state.impostoCartGlobal = this.$store.state.impostoCartGlobal - this.$store.state.liCartGlobal[index]['imposto']
            this.$store.state.pr_finalCartGlobal = this.$store.state.pr_finalCartGlobal - this.$store.state.liCartGlobal[index]['prFinal']

            this.$store.state.liCartGlobal.splice(index, 1) // rmv item do carrinho.
            //console.log('novo cart: ',this.$store.state.liCartGlobal)

            // Se a lista carrinho estiver vazia.
            if (this.$store.state.liCartGlobal < 1) { // desabilitar msg de que o orçamento será analisado.
                this.$store.state.flagCartParaAnalise = false
                return
            }

            // desabilitar msg de que o orçamento será analisado. VARRER até encontrar 1 pdd de análise em aberto.
            let c=0
            while (c < this.$store.state.liCartGlobal.length) {
                if (this.$store.state.liCartGlobal[c]['pr'] == '') {
                    this.$store.state.flagCartParaAnalise = true
                    return
                }
                c++
            }
            this.$store.state.flagCartParaAnalise = false
        },
        verifClLogado() {
            //
            if (this.$store.state.flagPageLoginCl) { // NÃO LOGADO.
                return false
            }
            return true // USUÁRIO LOGADO.
        },
        callEndPdd(flagPayPage) {

            // habilitar flag indicando que há op pdd em andamento.

            // verif. se o cl está logado.
            
            if (this.verifClLogado()) { // LOGADO.

                // rcv info atual local do cl logado.
                var info_cl = this.$store.state.info_cl

                // Operação de soma condicionada através do tipo de pedido.
                // Se ~orçamento~ Então STATUS_PDD rcb "Orçamento em análise". Senao rcb "Pagamento em análise".
                var liCart = this.$store.state.liCartGlobal
                var somaDesconto = 0
                var somaSubtotal = 0
                var somaImposto = 0
                var somaPrFinal = 0
                var msgStatus = ''
                var i = 0
                while (i < liCart.length) {
                    if (liCart[i]['pr'] != '') {
                        somaDesconto = somaDesconto + liCart[i]['desconto']
                        somaSubtotal = somaSubtotal + liCart[i]['subtotal']
                        somaImposto = somaImposto + liCart[i]['imposto']
                        somaPrFinal = somaPrFinal + liCart[i]['prFinal']
                        msgStatus = 'Pagamento em análise'
                    } else {
                        somaDesconto = 0
                        somaSubtotal = 0
                        somaImposto = 0
                        somaPrFinal = 0
                        msgStatus = 'Orçamento em análise'
                        break // Se encontrar pelo menos 1 preço vazio Então indica que é um pdd orçamento.
                    }
                    i++
                }

                // NOVO pedido.
                var len = info_cl['pedidos'].length
                var novo_id_pdd = len + 101
                var novo_pdd = {
                    'id_pdd':novo_id_pdd,
                    'date_pdd':'02/01/2001',
                    'pdd':this.$store.state.liCartGlobal,
                    'status_pdd':msgStatus,
                    'desconto':somaDesconto, // soma de todos os descontos, se houverem.
                    'subtotal':somaSubtotal, // soma.
                    'imposto':somaImposto, // soma.
                    'prFinal':somaPrFinal, // soma.
                }
                info_cl['status'] = 'Aberto' // input db. ~pdd sendo aberto~
                info_cl['pedidos'].push(novo_pdd) // input db.

                // update lista de pedidos do cl logado. (sobrepor a info local)
                this.$store.state.info_cl = info_cl

                // verif. se é um pagamento ou se é uma solicitação de orçamento.

                if (flagPayPage) { // É um pagamento.
                    
                    // chamar pay page.
                    this.$store.state.flagPageLoja = 'pag'

                    this.$store.state.flagPayPage = true // inidica op pay em andamento.

                } else { // É uma solicitação de orçamento.

                    // req post to db. (input new info cl)

                    // chamar cl page.
                    this.$store.state.flagPageLoja = 'areaCliente'
                }

            } else { // SE cl não logado.

                if (!flagPayPage) { // SE não é um pagamento direto, pode apenas enviar email no pdd orçamento.

                    // chamar página de escolha. (login ou cadastro ou apenas email).
                    this.$store.state.flagPageLoja = 'interPagProduto'

                } else {

                    // chamar cl page. (login ou cadastro).
                    this.$store.state.flagPageLoja = 'areaCliente'

                    this.$store.state.flagPayPage = true // inidica op pay em andamento.
                }
            }
        },
    },
}
</script>