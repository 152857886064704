<style scoped>
    @import url("../../styles/makers/makersNoticeCardB40.scss");
</style>

<template>
    <div class="box_makersNoticeCardB40">

        <v-img 
        class="img_NoticeCardB40" 
        :src="infoCardB.src"
        @click="callExp(infoCardB)"
        />

        <div class="tit_NoticeCardB40">{{ infoCardB.tit }}</div>

        <div class="action_NoticeCardB40">
            <v-icon small :style="{color:'white'}">mdi-calendar</v-icon>
            <span :style="{'margin-left':'4px'}">{{ infoCardB.date }}</span>
        </div>
    
    </div>
</template>

<script>
export default {
    props: ['infoCardB'],

    methods: {
        callExp(infoCardB) {

            // Desabilitar main page makers.
            // Habilitar página de extensão de exibição de conteúdo.
            // Rcv info card indv.

            this.$store.state.infoCardContent = infoCardB

            this.$store.state.flagExpContent = true
        },
    },

    created() {

    }
}
</script>