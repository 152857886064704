<style scoped>
    @import url("../../styles/loja/interPagProd.scss");
</style>

<template>
    <div class="box_interPagProd">

        <!-- VERSÃO LARGE -->

        <!-- LINHA SUP: marcador de etapas entre páginas -->
        <div class="etapas mt-6 mb-3">
            <div class="iconsEtapas">
                <v-icon :style="{'color':'#4B94C0'}">mdi-wrench</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                
                <v-icon>mdi-account-credit-card</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                
                <v-icon>mdi-check-circle-outline</v-icon>
            </div>
        </div>
        <div class="etapas mb-10">
            <div class="iconsEtapas">
                <!-- <v-icon :style="{'color':'#4B94C0'}">mdi-wrench</v-icon> -->
                <v-icon :style="{'color':'#4B94C0'}">mdi-subdirectory-arrow-right</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                
                <v-icon :style="{'color':'#4B94C0'}">mdi-account-question</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>

                <!--<v-icon :style="{'color':'#4B94C0'}">mdi-email-fast-outline</v-icon>

                <v-divider width="30" class="ml-2 mr-2" :style="{'align-self':'center'}"></v-divider>
                -->
                
                <v-icon>mdi-check-circle-outline</v-icon>
            </div>
        </div>

        <!-- BTNs -->
        <div class="btns_painel">
            
            <div class="mb-2 btn_el"
            @mouseover="corBtn1 = '0px 2px 4px rgb(247,215,46)'"
            @mouseleave="corBtn1 = ''"
            :style="{'box-shadow':corBtn1}"
            @click="callRote('areaCliente')"
            >
                <span>Fazer login/cadastro na área do cliente (recomendado)</span>
                <v-icon color="rgb(247,215,46)" class="ml-4">mdi-account</v-icon>
            </div>

            <div class="btn_el"
            @mouseover="corBtn2 = '0px 2px 4px rgb(247,215,46)'"
            @mouseleave="corBtn2 = ''"
            :style="{'box-shadow':corBtn2}"
            @click="callRote('interPagProdutoEmail')"
            >
                <span>Receber informações por e-mail diretamente</span>
                <v-icon color="rgb(247,215,46)">mdi-email-fast-outline</v-icon>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            corBtn1:'',
            corBtn2:'',
            corInit:'rgb(31,122,177)',
            corOnHover:'rgb(247,215,46)',
        }
    },
    methods: {
        callRote(rota) { // rota flag String.
            this.$store.state.flagPageLoja = rota
        }
    }
}
</script>