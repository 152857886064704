<style scoped>
    @import url("../../styles/loja/painelCadastro.scss");
</style>

<template>
    <div class="box_painelCadastro">

        <!--<v-form class="session_form"
        ref="form"
        v-model="valid"
        lazy-validation
        >
            <v-text-field
            color="orange"
            v-model="nameClient"
            :rules="nameRules"
            label="Nome de usuário"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="emailClient"
            :rules="nameRules"
            label="E-mail"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="endClient"
            :rules="nameRules"
            label="Morada"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="CodEndClient"
            :rules="nameRules"
            label="Código postal"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="localidadeClient"
            :rules="nameRules"
            label="Localidade"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="paisClient"
            :rules="nameRules"
            label="País"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="nifClient"
            label="Número fiscal (opcional)"
            required
            ></v-text-field>

            <v-text-field
            color="orange"
            v-model="foneClient"
            :rules="nameRules"
            label="Telefone"
            required
            ></v-text-field>

            <v-text-field
            type="password"
            color="orange"
            v-model="passClient"
            label="Palavra chave"
            required
            ></v-text-field>
        </v-form>

        <div class="btn_login">
            <v-btn
            small
            block
            :disabled="!valid"
            color="success"
            class="mr-4 mt-1"
            @click="chamarClientPage"
            >
                cadastrar
            </v-btn>
        </div>-->

        <span 
        @mouseover="hoverLogin = 'rgb(247,215,46)'"
        @mouseleave="hoverLogin = 'rgb(31,122,177)'"
        class="cadastroNameClient" 
        @click="chamarLogin()"
        :style="{'color':hoverLogin}"
        >
            Voltar ao login</span>

        <span v-if="flagErrorCadastro"
        :style="{'margin-top':'10px','color':'red','font-size':'0.8rem'}">
            {{ msgErrorCadastro }}
        </span>

    </div>
</template>

<script>
export default {
    data() {
        return {
            nameClient:'',
            emailClient:'',
            endClient:'',
            CodEndClient:'',
            localidadeClient:'',
            paisClient:'',
            nifClient:'',
            foneClient:'',
            passClient:'',

            hoverLogin:'rgb(31,122,177)',
            flagErrorCadastro:false,
            msgErrorCadastro:'Preenchimento incorreto',

            valid: true,
            nameRules: [
                v => !!v || 'Preenchimento obrigatório',
            ],
        }
    },

    methods: {
        chamarClientPage() {
            if (this.$refs.form.validate()) {

                if ( // acrescentar outros itens do cadastro.
                    this.nameClient.length >= 5 && this.passClient.length >= 5 && this.emailClient.length >= 13 && 
                    this.foneClient.length >= 9
                    ) {

                    // request get info cl: qnt de cls no db.

                    var id_cl_novo = this.$store.state.li_simu_cl.length // serve para qualquer condição de fluxo abaixo.

                    // update info cl local. SE for um novo pdd.
                    if(this.$store.state.flagOpSubmitPdd){

                        // obter info do pdd current.

                        // Operação de soma condicionada através do tipo de pedido.
                        // Se ~orçamento~ Então STATUS_PDD rcb "Orçamento em análise". Senao rcb "Pagamento em análise".
                        var liCart = this.$store.state.liCartGlobal
                        var somaDesconto = 0
                        var somaSubtotal = 0
                        var somaImposto = 0
                        var somaPrFinal = 0
                        var msgStatus = ''
                        var i = 0
                        while (i < liCart.length) {
                            if (liCart[i]['pr'] != '') {
                                somaDesconto = somaDesconto + liCart[i]['desconto']
                                somaSubtotal = somaSubtotal + liCart[i]['subtotal']
                                somaImposto = somaImposto + liCart[i]['imposto']
                                somaPrFinal = somaPrFinal + liCart[i]['prFinal']
                                msgStatus = 'Pagamento em análise'
                            } else {
                                somaDesconto = 0
                                somaSubtotal = 0
                                somaImposto = 0
                                somaPrFinal = 0
                                msgStatus = 'Orçamento em análise'
                                break // Se encontrar pelo menos 1 preço vazio Então indica que é um pdd orçamento.
                            }
                            i++
                        }

                        // new pdd tmp save local.

                        var info_cl //= this.$store.state.info_cl

                        // NOVO pedido.
                        //var len = info_cl['pedidos'].length
                        var novo_id_pdd = 101
                        var novo_pdd = {
                            'id_pdd':novo_id_pdd,
                            'date_pdd':'02/01/2001',
                            'pdd':this.$store.state.liCartGlobal,
                            'status_pdd':msgStatus,
                            'desconto':somaDesconto, // soma de todos os descontos, se houverem.
                            'subtotal':somaSubtotal, // soma.
                            'imposto':somaImposto, // soma.
                            'prFinal':somaPrFinal, // soma.
                        }

                        // update lista de pedidos do cl logado. (sobrepor a info local)
                        this.$store.state.info_cl = info_cl

                        this.$store.state.info_cl = {
                            'id_cl':id_cl_novo,
                            'email_cl':this.emailClient,
                            'nome_cl':this.nameClient,
                            'endereco_cl':this.endClient,
                            'fone_cl':this.foneClient,
                            'pass_cl':this.passClient,
                            'pedidos':[novo_pdd],
                            'status':'Aberto' // pdd
                        }


                        if(this.$store.state.flagCartParaAnalise){ // SE pdd orçamento.
                            
                            // req post new info cl to db.

                            // abrir cl page.
                            this.$store.state.flagPageLoginCl = false // desabilitar login.

                            // limpar cart após input do pdd.
                            this.$store.state.liCartGlobal = []

                            this.$store.state.flagOpSubmitPdd = false // fim de operação de movimento com o pdd.
                            return

                        } else { // SE pdd pagamamento direto.

                            // abrir pay page.
                            this.$store.state.flagPageLoginCl = false // desabilitar login.
                            this.$store.state.flagPageLoja = 'pag'

                            this.$store.state.flagOpSubmitPdd = false // fim de operação de movimento com o pdd.
                            return
                        }
                    }

                    // preencher novas info cl. SE NÃO HOUVER OP PDD ACONTECENDO.

                    this.$store.state.info_cl = {
                        'id_cl':id_cl_novo,
                        'email_cl':this.emailClient,
                        'nome_cl':this.nameClient,
                        'endereco_cl':this.endClient,
                        'fone_cl':this.foneClient,
                        'pass_cl':this.passClient,
                        'pedidos':[],
                        'status':'Fechado' // pdd
                    }

                    this.$store.state.flagPageLoginCl = false

                } else {

                    this.flagErrorCadastro = true
                    return
                }

                this.flagErrorCadastro = false
            }
        },
        chamarLogin() {
            this.$store.state.flagCadastroUser = false
        },
    },

    created() {
        window.scrollTo(0,0)
    }
}
</script>