<style scoped>
    @import url("../../styles/loja/lojaPayPage.scss");
</style>

<template>
    <div class="box_lojaPayPage">

        <!-- PÁGINA DE SELEÇÃO DE MÉTODO DE PAGAMENTO -->
        <PayEscolhaMet v-if="flagMet == 'payEscolhaMet'" @eventEscolhaMet="funcEscolhaMet($event)" />

        <!-- PÁGINA DE EFETUAÇÃO DO PAGAMENTO -->
        <PayMet v-if="flagMet == 'payMet'" :met="metodoEscolhido" @eventConfirmMet="changeFlagPay($event)" />
        
        <!-- CONFIRMAÇÃO DO PAGAMENTO-->
        <PayConfirmMet v-if="flagMet == 'payConfirmMet'" />

    </div>
</template>

<script>
import PayEscolhaMet from './PayEscolhaMet'
import PayMet from './PayMet'
import PayConfirmMet from './PayConfirmMet'

export default {
    components: { PayEscolhaMet, PayMet, PayConfirmMet },

    data() {
        return {
            flagMet:'payEscolhaMet', // selecionador de página de pagamento.
            metodoEscolhido:'',
        }
    },

    methods:{
        funcEscolhaMet(e) {
            
            this.flagMet = e[1] // evento emitido pelo child. e == STRING. (selcionar o componente)
            this.metodoEscolhido = e[0]
        },
        changeFlagPay(e) {
            this.flagMet = e
        }
    },

    created() {
        //this.$store.commit('srcIPloja') // without param. Initial main GET Req.
    }
}
</script>