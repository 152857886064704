<style scoped>
    @import url("../../styles/lora/mapa.scss");
</style>

<template>
    <div class="box_mapa" id="mapaid"></div>
</template>

<script>
import L from "leaflet"; // npm install --save leaflet
//import axios from 'axios'

export default {

    props:['listaCoords'],

    data() {
        return {
            //
        }
    },

    methods:{
        //
        mapa () {
            // remontar o mapa? /xgetxinfolorax 
        }
    },

    mounted() {
        //console.log('signal map start | PontosMap')

        // -- EXIBIÇÃO PONTOS CAPTADOS INDIVIDUALMENTE NO TERRENO.
        var liRed = []
        var liRSSI_init = []
        var liSNR_init = []
        var c=0
        while (c < this.listaCoords.length) { // lista rcv via props.

            if (this.listaCoords[c].length == 3) {

                /*L.circle([this.listaCoords[c][0],this.listaCoords[c][1]], {
                    color: 'red',
                    fillColor: '#f03',
                    fillOpacity: 0.2,
                    radius: 5 // metros
                }).addTo(map);*/

                liRed.push(
                    L.circle([this.listaCoords[c][0],this.listaCoords[c][1]], {
                    color: '#D91966',
                    fillColor: '#f03',
                    fillOpacity: 0.2,
                    radius: 5 // metros
                    })
                )
            }

            var corDinamRSSI = 'rgba(1,1,1,0.5)'
            var corDinamSNR = 'rgba(1,1,1,0.5)'
            if (this.listaCoords[c].length >= 4) {

                /*L.circle([this.listaCoords[c][0],this.listaCoords[c][1]], {
                    color: 'blue',
                    fillColor: '#f03',
                    fillOpacity: 0.2,
                    radius: 5 // metros
                }).addTo(map)
                .bindPopup(this.listaCoords[c][3]);*/

                // --- processar rssi e snr
                var rssi = this.listaCoords[c][6][0]['rssi']
                var snr = this.listaCoords[c][6][0]['snr']
                
                if (rssi > -100) { corDinamRSSI = 'rgb(253, 1, 0)' } // (color) vermelho > -100dBm (Signal)
                if (rssi <= -100 && rssi > -105 ) { corDinamRSSI = '#FF7F00' } // -100 - -105
                if (rssi <= -105 && rssi > -110 ) { corDinamRSSI = '#FEFF01' } // -105 - -110
                if (rssi <= -110 && rssi > -115 ) { corDinamRSSI = '#00FF01' } // -110 - -115
                if (rssi <= -115 && rssi > -120 ) { corDinamRSSI = '#00FFFF' } // -115 - -120
                if (rssi <= -120) { corDinamRSSI = '#0001FD' } // < -120dBm

                if (snr >= 5) { corDinamSNR = 'rgb(253, 1, 0)' } // >= 5dB
                if (snr < 5 && snr >= 0) { corDinamSNR = '#FF7F00' } // 5dB - 0dB
                if (snr < 0) { corDinamSNR = '#0001FD' } // < 0dB
                
                //if (c == 2000) { console.log('this.listaCoords[c][6]: ',this.listaCoords[c][6][0]['rssi']) }

                liSNR_init.push(
                    L.circle([this.listaCoords[c][0],this.listaCoords[c][1]], {
                    //color: 'blue',
                    fillColor: corDinamSNR, // Cor interna do círculo
                    fillOpacity: 0.4, // Opacidade da cor interna
                    stroke: false, // Remove a borda (sem contorno)
                    radius: 150 // metros
                    }).bindPopup(this.listaCoords[c][3])
                )

                liRSSI_init.push(
                    L.circle([this.listaCoords[c][0],this.listaCoords[c][1]], {
                    //color: 'blue',
                    fillColor: corDinamRSSI, // Cor interna do círculo
                    fillOpacity: 0.4, // Opacidade da cor interna
                    stroke: false, // Remove a borda (sem contorno)
                    radius: 150 // metros
                    }).bindPopup(this.listaCoords[c][3])
                )
            }

            c++
        }

        var layerRed = L.layerGroup(liRed);
        var liRSSI = L.layerGroup(liRSSI_init);
        var liSNR = L.layerGroup(liSNR_init);

        //console.log('qnt pontos validos: ',count)
        var map = L.map(
            'mapaid',
            { layers: [layerRed, liRSSI, liSNR] }
        )
        .setView([40.1622213, -7.4553607], 11);

        /*var map = L.map('map', {
            center: [39.73, -104.99],
            zoom: 10,
            layers: [osm, cities]
        });*/

        var overlayMaps = {
            "Pontos iniciais": layerRed,
            "RSSI": liRSSI,
            "SNR": liSNR
        };

        L.control.layers(overlayMaps).addTo(map);

        //var layerControl = L.control.layers(overlayMaps).addTo(map);

        //layerControl.addOverlay(parks, "Parks");

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
    },

    created() {
        //window.scrollTo(0,0)
    }
}
</script>