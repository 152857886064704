<style scoped>
    @import url("../../styles/makers/makersNoticeCardA.scss");
</style>

<template>
    <div class="box_makersNoticeCardA" @click="callExp(infoCardA)">

        <v-img 
        class="img_NoticeCardA" 
        :src="infoCardA.src"
        />

        <div class="tit_NoticeCardA">{{ infoCardA.tit }}</div>

        <div class="txt_NoticeCardA">{{ infoCardA.txt }}</div>

        <div class="action_NoticeCardA">
            <v-icon small :style="{color:'white'}">mdi-calendar</v-icon>
            <span :style="{'margin-left':'4px'}">{{ infoCardA.date }}</span>
        </div>
    
    </div>
</template>

<script>
export default {
    props: ['infoCardA'],

    methods: {
        callExp(infoCardA) {

            // Desabilitar main page makers.
            // Habilitar página de extensão de exibição de conteúdo.
            // Rcv info card indv.

            console.log('infoCardA: ',infoCardA)
            this.$store.state.infoCardContent = infoCardA
            console.log('content old cards: ',this.$store.state.infoCardContent)
            this.$session.set("infoCardContent", this.$store.state.infoCardContent)
            //let infoCardContent = this.$session.get("infoCardContent")
            //console.log(infoCardContent)

            // enviar o infoCardA selecionado para o store? Uma sessão deve 
            // está habilitada para não perder a info no 'barramento'.
            //sessionStorage.infoCardContent = infoCardA
            //this.$session.set("infoCardContent", "")

            this.$session.set("flagExpContent", true)
            this.$store.state.flagExpContent = true
            //sessionStorage.flagExpContent = true

            //console.log(sessionStorage.infoCardContent)
        },
    },

    created() {

    }
}
</script>