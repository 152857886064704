<style scoped>
    @import url("../../styles/lora/mapa.scss");
</style>

<template>
    <div class="box_mapa" id="mapaid"></div>
</template>

<script>
import L from "leaflet"; // npm install --save leaflet
//import axios from 'axios'

export default {

    props:['listaCoords','listaGw','flagStrOptionMap'],

    data() {
        return {
            //
        }
    },

    methods:{
        //
        mapa () {
            // remontar o mapa? /xgetxinfolorax 
        }
    },

    mounted() {
        // coordenada upinc covilha 40.2789636, -7.5040484,275
        // setView([latitude, longitude], zoom)
        //var map = L.map('mapaid').setView([40.2789636, -7.5040484,275], 9.5);
        var map = L.map('mapaid').setView([40.1222213, -7.4153607], 9.5);

        //console.log('signal map start | gw map')

        // -- EXIBIÇÃO SIMPLES GATEWAYS ATRAVÉS DE SUAS COORDENADAS.
        var countYesCoordGw=0
        var countNoCoordGw=0
        var strShow = ''
        var c=0
        while (c < this.listaGw.length) { // lista rcv via props.

            /*if (this.listaGw[c]['gateway'] == 'Gateway Fundão Seminário') {

                //L.polygon(this.listaGw[c]['li_coords_pontos']).addTo(map);
                console.log('ponto da gateway (Gateway Fundão Seminário)', this.listaGw[c]['li_coords_pontos'])
            }*/

            strShow = `Gateway: ${this.listaGw[c]['gateway']}<br>Pontos mapeados: ${this.listaGw[c]['li_coords_pontos'].length}`

            // -- api v5 inclui gateways sem coordenadas.
            if ( this.listaGw[c]['coord'] != 'errorCoordGw' ) {

                L.marker([this.listaGw[c]['coord'][0],this.listaGw[c]['coord'][1]]).addTo(map)
                .bindPopup(strShow)
                .openPopup();

                countYesCoordGw++

            } else {

                countNoCoordGw++
            }

            c++
        }

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        // -- emitir callback com resultadado da contagem de gateways.
        var countTotalGw = countYesCoordGw + countNoCoordGw
        this.$emit('contadores',[countYesCoordGw, countNoCoordGw, countTotalGw])
        //console.log('(MapaGw) liContadores: ',[countYesCoordGw, countNoCoordGw, countTotalGw])
    },

    created() {
        //window.scrollTo(0,0)
    }
}
</script>