<style scoped>
    @import url("../../styles/lora/mapa.scss");
</style>

<template>
    <div class="box_mapa" id="mapaid"></div>
</template>

<script>
import L from "leaflet"; // npm install --save leaflet
//import axios from 'axios'

export default {

    props:['listaCirc'],

    data() {
        return {
            map:null,
        }
    },

    methods:{
        // remontar o mapa? /xgetxinfolorax 
        changeMap(c) {
            //console.log('disparo from circle')
            //console.log('c: ',c)
            //console.log('c.targe.options.radius',c.target.options.radius)
            var raioAlvo = c.target.options.radius
            
            var corDinamRSSI = 'rgb(253, 1, 0)'
            var w=0
            while (w < this.listaCirc.length) {
                if (this.listaCirc[w][1] == raioAlvo) {
                    
                    //console.log('alvo encontrado')

                    // remover conteúdo atual do mapa.
                    this.map.remove();
                    this.map = L.map('mapaid').setView([40.1222213, -7.4153607], 9.5);

                    //alert(this.listaCirc[w][3]);
                    L.popup()
                    .setLatLng(c.latlng) // Define a localização do pop-up onde o clique ocorreu
                    .setContent(this.listaCirc[w][3])
                    .openOn(this.map);

                    var circAlvo = L.circle(this.listaCirc[w][0], {
                        color: '#092D4F',
                        fillColor: 'rgb(243, 151, 151)',
                        fillOpacity: 0.3,
                        radius: this.listaCirc[w][1] // metros
                    }).addTo(this.map)

                    // lista de pontos que compõem esta gateway clicada.
                    var p=0
                    while (p < this.listaCirc[w][2].length) {
                        //console.log('rssi: ',this.listaCirc[w][4][p][1]) // rssi

                        // --- processar rssi (21/08/2024)
                        //var rssi = this.listaCoords[c][6][0]['rssi']

                        var rssi = this.listaCirc[w][4][p][1]
                        
                        if (rssi > -100) { corDinamRSSI = 'rgb(253, 1, 0)' } // (color) vermelho > -100dBm (Signal)
                        if (rssi <= -100 && rssi > -105 ) { corDinamRSSI = '#FF7F00' } // -100 - -105
                        if (rssi <= -105 && rssi > -110 ) { corDinamRSSI = '#FEFF01' } // -105 - -110
                        if (rssi <= -110 && rssi > -115 ) { corDinamRSSI = '#00FF01' } // -110 - -115
                        if (rssi <= -115 && rssi > -120 ) { corDinamRSSI = '#00FFFF' } // -115 - -120
                        if (rssi <= -120) { corDinamRSSI = '#0001FD' } // < -120dBm

                        L.circle([this.listaCirc[w][2][p][0],this.listaCirc[w][2][p][1]], {
                            fillColor: corDinamRSSI, // Cor interna do círculo
                            fillOpacity: 0.4, // Opacidade da cor interna
                            stroke: false, // Remove a borda (sem contorno)
                            radius: 250 // metros
                        }).addTo(this.map)
                        
                        p++
                    }

                    circAlvo.on('click', this.resetMap);

                    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    }).addTo(this.map);
                }
                w++
            }
        },
        resetMap() {
            //console.log('c.targe.options.radius',c.target.options.radius)
            //var raioAlvo = c.target.options.radius
            //console.log('reset map raioAlvo anterior: ',raioAlvo)

            if (this.map != null) { this.map.remove(); }
            
            this.map = L.map('mapaid').setView([40.1222213, -7.4153607], 9.5);

            // -- reordenar do maior para o menor círculo.
            //this.listaCirc.sort((a, b) => b.preco - a.preco); // para objetos (json)
            this.listaCirc.sort((a, b) => b[1] - a[1]); // para array de arrays.

            // -- EXIBIÇÃO SIMPLES DOS CÍRCULOS QUE ENVOLVEM UMA ÚNICA GATEWAY INDIVIDUAL.
            var circle0 = null
            var c=0
            while (c < this.listaCirc.length) { // lista rcv via props.

                circle0 = L.circle(this.listaCirc[c][0], {
                    fillColor: 'rgb(161, 31, 236)', // Cor interna do círculo
                    fillOpacity: 0.3, // Opacidade da cor interna
                    stroke: false, // Remove a borda (sem contorno)
                    radius: this.listaCirc[c][1] // metros
                }).addTo(this.map)

                circle0.on('click', this.changeMap); // tornar todos os circulos disparáveis? sim.

                c++
            }

            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map);
        },
    },

    mounted() {
        this.resetMap()
        /*this.map = L.map('mapaid').setView([40.1222213, -7.4153607], 9.5);

        console.log('signal map start | gw circ map')
        //console.log('this.listaCirc: ',this.listaCirc)

        // -- EXIBIÇÃO SIMPLES DOS CÍRCULOS QUE ENVOLVEM UMA ÚNICA GATEWAY INDIVIDUAL.
        var circle0 = null
        //var liCicle = []
        var c=0
        while (c < this.listaCirc.length) { // lista rcv via props.

            circle0 = L.circle(this.listaCirc[c][0], {
                color: '#092D4F',
                fillColor: '#1D71A5',
                fillOpacity: 0.3,
                radius: this.listaCirc[c][1] // metros
            }).addTo(this.map)
            //.bindPopup('Gateway Fundão Teste');
            //.bindPopup(this.listaCirc[c][3]);
            //.bindPopup(this.changeMap());
            //.bindTooltip("my tooltip text").openTooltip();
            //.bindTooltip("my tooltip text");
            //.bind('teste-0');
            //L.throttle(this.changeMap);

            //liCicle.push(circle0)

            circle0.on('click', this.changeMap); // tornar todos os circulos disparáveis?

            c++
        }

        //function onMapClick(e) {
        //    alert("You clicked the map at " + e.latlng);
        //    console.log('teste de disparo')
        //}

        //map.on('click', onMapClick);
        //map.on('click', this.changeMap);
        //circle0.on('click', this.changeMap); // funciona para o ultimo circulo printado no mapa.
        //liCicle.on('click', this.changeMap); // lista de circulos?

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);*/
    },

    created() {
        //window.scrollTo(0,0)
    }
}
</script>