<style scoped>
    @import url("../../styles/makers/makersSmallCard.scss");
</style>

<template>
    <div class="box_makersSmallCard">

        <!-- COLUNA À ESQUERDA -->
        <div class="painelTXT_SmallCard">

            <div class="tit_SmallCard">
                <span :style="{'margin':'2px'}">{{ infoCard.tit }}</span>
            </div>

            <div class="txt_SmallCard">
                <span :style="{'margin':'4px'}">{{ infoCard.txt }}</span>
            </div>

            <div class="action_SmallCard">
                <v-icon small :style="{color:'white'}">mdi-calendar</v-icon>
                <span :style="{'margin-left':'4px'}">{{ infoCard.date }}</span>
            </div>

        </div>

        <!-- IMG À DIREITA -->
        <v-img 
        class="img_SmallCard" 
        :src="infoCard.src"
        @click="callExp(infoCard)"
        />
    
    </div>
</template>

<script>
export default {
    props: ['infoCard'],

    methods: {
        callExp(infoCard) {

            // Desabilitar main page makers.
            // Habilitar página de extensão de exibição de conteúdo.
            // Rcv info card indv.

            this.$store.state.infoCardContent = infoCard

            this.$store.state.flagExpContent = true
        },
    },

    created() {
        //console.log('infoCard: ',this.infoCard)
    }
}
</script>